import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  Grid,
  Button,
  Divider,
  TextField,
  Box,
  LinearProgress,
  Typography,
  Autocomplete,
  Chip,
  FormControlLabel,
  Switch,
  InputAdornment,
} from "@mui/material";

import { getItemDetails } from "src/actions/orders";
import CustomSpinner from "src/components/CustomSpinner";
import {
  SHIPPING_COMPANY,
  item_internal_statuses,
  item_statuses,
} from "src/utils/common";
import moment from "moment";
import {
  deliveryDateUpdate,
  hasPurchaseError,
  purchasePrice,
  toggleIsDamaged,
  toggleIsDangerousGoods,
  trackingInfo,
  updateInternationalTrackingNumber,
  updateInvoiceNumber,
  updateItemInternalStatus,
  updateNote,
  updateStatus,
  updateTrackingNumber,
  wrongTackingNumber,
} from "src/actions/orderItems";
import { CustomDialog } from "src/components/CustomDialog";
import CustomText from "src/components/CustomText";
import RolePermission from "src/components/RolePermission";

export default function UpdateItemInfo(props) {
  const dispatch = useDispatch();

  const _UserInfo = useSelector((state) => state.users.userInfo);

  const [reload, setReload] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});

  const [selectedInternalStatuses, setSelectedInternalStatuses] = useState([]);

  useEffect(() => {
    if (props.isOpen || props.isOpenPurchasePrice) {
      getDetails(props.itemId ? props.itemId : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen, props.isOpenPurchasePrice]);

  function getDetails(id) {
    setReload(true);
    dispatch(getItemDetails(id)).then((res) => {
      setSelectedItem(res.data);
      setSelectedInternalStatuses(
        res.data.internalStatuses.map((x) => x.status)
      );
      setReload(false);
    });
  }

  function updateDeliveryDate() {
    setReload(true);
    dispatch(
      deliveryDateUpdate({
        itemIds:
          props.selectedDateRows.length > 0
            ? props.selectedDateRows
            : [selectedItem.itemId],
        deliveryDate: moment(selectedItem.itemDeliveryDate).format(
          "YYYY-MM-DD"
        ),
      })
    )
      .then((res) => {
        if (res.message === "Success") {
          props.onSuccess("Delivery date updated successfully");
        }
        if (props.selectedDateRows.length > 0) {
          props.handleClose();
        }
        setReload(false);
      })
      .catch((err) => {
        props.onFail("Network Error");
        setReload(false);
      });
  }

  function updateTrackNumber() {
    setReload(true);
    dispatch(
      updateTrackingNumber({
        ids:
          props.selectedRows.length > 0
            ? props.selectedRows
            : [selectedItem.id],
        trackingNumber: selectedItem.vendorTrackingNumber,
      })
    )
      .then((res) => {
        if (res.message === "Success") {
          props.onSuccess("Regional Tracking Number updated successfully");
        }
        if (props.selectedDateRows.length > 0) {
          props.handleClose();
        }
        setReload(false);
      })
      .catch((err) => {
        props.onFail("Network Error");
        setReload(false);
      });
  }

  function updateInternationalTrackingnumber() {
    setReload(true);
    dispatch(
      updateInternationalTrackingNumber({
        ids:
          props.selectedRows.length > 0
            ? props.selectedRows
            : [selectedItem.id],
        trackingNumber: selectedItem.internationalTrackingNumber,
      })
    )
      .then((res) => {
        if (res.message === "Success") {
          props.onSuccess("International Tracking Number updated successfully");
        }
        if (props.selectedDateRows.length > 0) {
          props.handleClose();
        }
        setReload(false);
      })
      .catch((err) => {
        props.onFail("Network Error");
        setReload(false);
      });
  }

  function updateWrongTrackingNumber(newStatus) {
    setReload(true);
    dispatch(
      wrongTackingNumber({
        ids:
          props.selectedRows.length > 0
            ? props.selectedRows
            : [selectedItem.id],
        isWrongTrackingNumber: newStatus,
      })
    )
      .then((res) => {
        if (res.message === "Success") {
          props.onSuccess("Updated successfully");
        }
        if (props.selectedDateRows.length > 0) {
          props.handleClose();
        }
        setReload(false);
      })
      .catch((err) => {
        props.onFail("Network Error");
        setReload(false);
      });
  }

  function updatePurchaseError(newStatus) {
    setReload(true);
    dispatch(hasPurchaseError(selectedItem.id))
      .then((res) => {
        if (res.message === "Success") {
          props.onSuccess("Updated successfully");
        }
        if (props.selectedDateRows.length > 0) {
          props.handleClose();
        }
        setReload(false);
      })
      .catch((err) => {
        props.onFail("Network Error");
        setReload(false);
      });
  }

  function updateItemNote() {
    setReload(true);
    dispatch(
      updateNote({
        ids:
          props.selectedRows.length > 0
            ? props.selectedRows
            : [selectedItem.id],
        note: selectedItem.note,
      })
    )
      .then((res) => {
        if (res.message === "Success") {
          props.onSuccess("Note updated successfully");
        }
        if (props.selectedDateRows.length > 0) {
          props.handleClose();
        }
        setReload(false);
      })
      .catch((err) => {
        props.onFail("Network Error");
        setReload(false);
      });
  }

  function updateInvoiceNO() {
    setReload(true);
    dispatch(
      updateInvoiceNumber({
        ids:
          props.selectedRows.length > 0
            ? props.selectedRows
            : [selectedItem.id],
        invoiceNumber: selectedItem.vendorInvoiceNumber,
      })
    )
      .then((res) => {
        if (res.message === "Success") {
          props.onSuccess("Invoice number updated successfully");
        }
        if (props.selectedDateRows.length > 0) {
          props.handleClose();
        }
        setReload(false);
      })
      .catch((err) => {
        props.onFail("Network Error");
        setReload(false);
      });
  }

  function purchaseprice() {
    setReload(true);
    dispatch(
      purchasePrice(selectedItem.id, {
        purchasePrice: selectedItem.purchasePrice,
      })
    )
      .then((res) => {
        if (res.message === "Success") {
          props.onSuccess("Purchase Price updated successfully");
        }
        if (props.selectedDateRows.length > 0) {
          props.handleClose();
        }
        setReload(false);
      })
      .catch((err) => {
        props.onFail("Network Error");
      });
  }

  function updateOrderItemStatus() {
    setReload(true);
    dispatch(
      updateStatus({
        ids: props.selectedRows,
        itemStatus: selectedStatus,
      })
    )
      .then((res) => {
        if (res.message === "Success") {
          props.onSuccess("Status updated successfully");
        }
        if (props.selectedDateRows.length > 0) {
          props.handleClose();
        }
        setReload(false);
      })
      .catch((err) => {
        setReload(false);
        props.onFail("Network Error");
      });
  }

  function updateOrderItemInternalStatus() {
    setReload(true);
    dispatch(
      updateItemInternalStatus({
        orderItemIds: [selectedItem.id],
        statuses: selectedInternalStatuses,
      })
    )
      .then((res) => {
        if (res.message === "Success") {
          props.onSuccess("Status updated successfully");
        }
        if (props.selectedDateRows.length > 0) {
          props.handleClose();
        }
        setReload(false);
      })
      .catch((err) => {
        setReload(false);
        props.onFail("Network Error");
      });
  }

  function updateShippingTrackingInfo() {
    setReload(true);
    dispatch(
      trackingInfo({
        ids:
          props.selectedRows.length > 0
            ? props.selectedRows
            : [selectedItem.id],
        realWeight: selectedItem.realWeight ?? 0,
        shippingInvoiceNumber: selectedItem.shippingInvoiceNumber,
        shippingCompany: selectedItem.shippingCompany,
        invoiceShippingDate: selectedItem.invoiceShippingDate,
      })
    )
      .then((res) => {
        if (res.message === "Success") {
          props.onSuccess("Shipping tracking info updated successfully");
        }
        if (props.selectedDateRows.length > 0) {
          props.handleClose();
        }
        setReload(false);
      })
      .catch((err) => {
        props.onFail("Network Error");
        setReload(false);
      });
  }

  function updateToggleIsDangerousGoods() {
    setReload(true);
    dispatch(toggleIsDangerousGoods(selectedItem.id))
      .then((res) => {
        if (res.message === "Success") {
          props.onSuccess("Updated successfully");
        }
        if (props.selectedDateRows.length > 0) {
          props.handleClose();
        }
        setReload(false);
      })
      .catch((err) => {
        props.onFail("Network Error");
        setReload(false);
      });
  }

  function updateToggleIsDamaged() {
    setReload(true);
    dispatch(toggleIsDamaged(selectedItem.id))
      .then((res) => {
        if (res.message === "Success") {
          props.onSuccess("Updated successfully");
        }
        if (props.selectedDateRows.length > 0) {
          props.handleClose();
        }
        setReload(false);
      })
      .catch((err) => {
        props.onFail("Network Error");
        setReload(false);
      });
  }

  return (
    <Box>
      {!selectedItem ? (
        <CustomSpinner />
      ) : (
        <Box>
          <CustomDialog
            isOpen={props.isOpen}
            handleClose={() => {
              props.handleClose();
              setSelectedItem({});
            }}
            title={"Update Order Item"}
            Draggable={true}
          >
            {reload ? (
              <LinearProgress sx={{ marginTop: -2, marginBottom: 2 }} />
            ) : null}
            <Grid container spacing={2} paddingBottom={1} paddingTop={1}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={
                        reload ||
                        props.itemStatus === "Canceled" ||
                        props.itemStatus === "Canceled By Customer" ||
                        !_UserInfo.roleOEndpoint
                          .map((data) => data.Code)
                          .includes("23-15")
                          ? true
                          : false
                      }
                      checked={selectedItem.hasPurchaseError ?? false}
                      onChange={(e) => {
                        setSelectedItem({
                          ...selectedItem,
                          hasPurchaseError: e.target.checked,
                        });
                        updatePurchaseError(e.target.checked);
                      }}
                    />
                  }
                  label="Purchase Error !"
                />
                <FormControlLabel
                  control={
                    <Switch
                      disabled={
                        reload ||
                        props.itemStatus === "Canceled" ||
                        props.itemStatus === "Canceled By Customer" ||
                        !_UserInfo.roleOEndpoint
                          .map((data) => data.Code)
                          .includes("23-06")
                          ? true
                          : false
                      }
                      checked={selectedItem.isWrongTrackingNumber ?? false}
                      onChange={(e) => {
                        setSelectedItem({
                          ...selectedItem,
                          isWrongTrackingNumber: e.target.checked,
                        });
                        updateWrongTrackingNumber(e.target.checked);
                      }}
                    />
                  }
                  label="Invalid Track !"
                />
                <RolePermission permission="23-18">
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={
                          reload ||
                          props.itemStatus === "Canceled" ||
                          props.itemStatus === "Canceled By Customer" ||
                          !_UserInfo.roleOEndpoint
                            .map((data) => data.Code)
                            .includes("23-15")
                            ? true
                            : false
                        }
                        checked={selectedItem.isDangerousGoods ?? false}
                        onChange={(e) => {
                          setSelectedItem({
                            ...selectedItem,
                            isDangerousGoods: e.target.checked,
                          });
                          updateToggleIsDangerousGoods(e.target.checked);
                        }}
                      />
                    }
                    label="Toggle Is Dangerous Goods"
                  />
                </RolePermission>
                <RolePermission permission="23-17">
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={
                          reload ||
                          props.itemStatus === "Canceled" ||
                          props.itemStatus === "Canceled By Customer" ||
                          !_UserInfo.roleOEndpoint
                            .map((data) => data.Code)
                            .includes("23-15")
                            ? true
                            : false
                        }
                        checked={selectedItem.isDamaged ?? false}
                        onChange={(e) => {
                          setSelectedItem({
                            ...selectedItem,
                            isDamaged: e.target.checked,
                          });
                          updateToggleIsDamaged(e.target.checked);
                        }}
                      />
                    }
                    label="Toggle Is Damaged"
                  />
                </RolePermission>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ mt: 3 }}>
                  <Chip label="PURCHASING" />
                </Divider>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                >
                  <Autocomplete
                    multiple
                    disabled={
                      reload ||
                      !_UserInfo.roleOEndpoint
                        .map((data) => data.Code)
                        .includes("23-19")
                        ? true
                        : false
                    }
                    options={item_internal_statuses}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField label="Internal Status" {...params} />
                    )}
                    onChange={(event, value) => {
                      var ids = value.map((x) => x.id);
                      setSelectedInternalStatuses(value ? ids : null);
                    }}
                    value={item_internal_statuses?.filter((x) =>
                      selectedInternalStatuses
                        ? selectedInternalStatuses?.includes(x.id)
                        : null
                    )}
                  />
                  <Button
                    size="small"
                    disabled={
                      reload ||
                      !_UserInfo.roleOEndpoint
                        .map((data) => data.Code)
                        .includes("23-19")
                        ? true
                        : false
                    }
                    variant="contained"
                    sx={{ marginX: "10px" }}
                    onClick={() => updateOrderItemInternalStatus()}
                  >
                    Update
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  label="Purchase Price"
                  value={
                    selectedItem.purchasePrice ? selectedItem.purchasePrice : ""
                  }
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      purchasePrice: e.target.value,
                    })
                  }
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          size="small"
                          disabled={
                            reload ||
                            props.itemStatus === "Canceled" ||
                            props.itemStatus === "Canceled By Customer" ||
                            !_UserInfo.roleOEndpoint
                              .map((data) => data.Code)
                              .includes("23-12")
                              ? true
                              : false
                          }
                          variant="contained"
                          onClick={() => purchaseprice()}
                          fullWidth
                        >
                          Update
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="Invoice number"
                  value={
                    selectedItem.vendorInvoiceNumber
                      ? selectedItem.vendorInvoiceNumber
                      : ""
                  }
                  onChange={(e) => {
                    setSelectedItem({
                      ...selectedItem,
                      vendorInvoiceNumber: e.target.value,
                    });
                  }}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          size="small"
                          disabled={
                            reload ||
                            props.itemStatus === "Canceled" ||
                            props.itemStatus === "Canceled By Customer" ||
                            !_UserInfo.roleOEndpoint
                              .map((data) => data.Code)
                              .includes("23-07")
                              ? true
                              : false
                          }
                          variant="contained"
                          onClick={() => updateInvoiceNO()}
                          fullWidth
                        >
                          Update
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ mt: 3 }}>
                  <Chip label="TRACKING" />
                </Divider>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  label="Regional Tracking Number"
                  value={
                    selectedItem.vendorTrackingNumber
                      ? selectedItem.vendorTrackingNumber
                      : ""
                  }
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      vendorTrackingNumber: e.target.value,
                    })
                  }
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          size="small"
                          disabled={
                            reload ||
                            props.itemStatus === "Canceled" ||
                            props.itemStatus === "Canceled By Customer" ||
                            !_UserInfo.roleOEndpoint
                              .map((data) => data.Code)
                              .includes("23-04")
                              ? true
                              : false
                          }
                          variant="contained"
                          onClick={() => updateTrackNumber()}
                          fullWidth
                        >
                          Update
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  label="International Tracking Number"
                  value={
                    selectedItem.internationalTrackingNumber
                      ? selectedItem.internationalTrackingNumber?.trim()
                      : ""
                  }
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      internationalTrackingNumber: e.target.value,
                    })
                  }
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          size="small"
                          disabled={
                            reload ||
                            props.itemStatus === "Canceled" ||
                            props.itemStatus === "Canceled By Customer" ||
                            !_UserInfo.roleOEndpoint
                              .map((data) => data.Code)
                              .includes("23-05")
                              ? true
                              : false
                          }
                          variant="contained"
                          onClick={() => updateInternationalTrackingnumber()}
                          fullWidth
                        >
                          Update
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  label="Note"
                  value={selectedItem.note ? selectedItem.note : ""}
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      note: e.target.value,
                    })
                  }
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          size="small"
                          disabled={
                            reload ||
                            props.itemStatus === "Canceled" ||
                            props.itemStatus === "Canceled By Customer"
                              ? true
                              : false
                          }
                          variant="contained"
                          onClick={() => updateItemNote()}
                          fullWidth
                        >
                          Update
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    inputFormat="YYYY-MM-DD"
                    views={["year", "month", "day"]}
                    label="Delivery date"
                    name="deliveryDate"
                    value={
                      selectedItem.itemDeliveryDate
                        ? selectedItem.itemDeliveryDate
                        : null
                    }
                    onChange={(value) =>
                      setSelectedItem({
                        ...selectedItem,
                        itemDeliveryDate: value,
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        {...params}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button
                                size="small"
                                disabled={
                                  reload ||
                                  props.itemStatus === "Canceled" ||
                                  props.itemStatus === "Canceled By Customer" ||
                                  !_UserInfo.roleOEndpoint
                                    .map((data) => data.Code)
                                    .includes("23-09")
                                    ? true
                                    : false
                                }
                                variant="contained"
                                onClick={() => updateDeliveryDate()}
                                fullWidth
                              >
                                Update
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              {/* <Grid item xs={12}>
                <Divider sx={{ mt: 3 }}>
                  <Chip label="Category" />
                </Divider>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple
                  disabled={
                    reload ||
                    props.itemStatus === "Canceled" ||
                    props.itemStatus === "Canceled By Customer" ||
                    !_UserInfo.roleOEndpoint
                      .map((data) => data.Code)
                      .includes("23-19")
                      ? true
                      : false
                  }
                  options={item_internal_statuses}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField label="Main Category" {...params} />
                  )}
                  onChange={(event, value) => {
                    var ids = value.map((x) => x.id);
                    setSelectedInternalStatuses(value ? ids : null);
                  }}
                  value={item_internal_statuses?.filter((x) =>
                    selectedInternalStatuses
                      ? selectedInternalStatuses?.includes(x.id)
                      : null
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple
                  disabled={
                    reload ||
                    props.itemStatus === "Canceled" ||
                    props.itemStatus === "Canceled By Customer" ||
                    !_UserInfo.roleOEndpoint
                      .map((data) => data.Code)
                      .includes("23-19")
                      ? true
                      : false
                  }
                  options={item_internal_statuses}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField label="Sub Main Category" {...params} />
                  )}
                  onChange={(event, value) => {
                    var ids = value.map((x) => x.id);
                    setSelectedInternalStatuses(value ? ids : null);
                  }}
                  value={item_internal_statuses?.filter((x) =>
                    selectedInternalStatuses
                      ? selectedInternalStatuses?.includes(x.id)
                      : null
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple
                  disabled={
                    reload ||
                    props.itemStatus === "Canceled" ||
                    props.itemStatus === "Canceled By Customer" ||
                    !_UserInfo.roleOEndpoint
                      .map((data) => data.Code)
                      .includes("23-19")
                      ? true
                      : false
                  }
                  options={item_internal_statuses}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField label="Product Category" {...params} />
                  )}
                  onChange={(event, value) => {
                    var ids = value.map((x) => x.id);
                    setSelectedInternalStatuses(value ? ids : null);
                  }}
                  value={item_internal_statuses?.filter((x) =>
                    selectedInternalStatuses
                      ? selectedInternalStatuses?.includes(x.id)
                      : null
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  disabled={
                    reload ||
                    props.itemStatus === "Canceled" ||
                    props.itemStatus === "Canceled By Customer" ||
                    !_UserInfo.roleOEndpoint
                      .map((data) => data.Code)
                      .includes("23-19")
                      ? true
                      : false
                  }
                  variant="contained"
                  onClick={() => updateOrderItemInternalStatus()}
                >
                  Update
                </Button>
              </Grid> */}
            </Grid>

            <Divider sx={{ mt: 4 }}>
              <Chip label="SHIPMENT TRACKING INFO" />
            </Divider>
            <Grid container spacing={1} paddingBottom={1} paddingTop={1}>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  type="number"
                  label="Real weight"
                  value={selectedItem.realWeight ? selectedItem.realWeight : ""}
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      realWeight: e.target.value,
                    })
                  }
                  style={{ margin: "5px" }}
                  fullWidth
                  helperText="weight should be in Pound unit"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="Shipping invoice number"
                  value={
                    selectedItem.shippingInvoiceNumber
                      ? selectedItem.shippingInvoiceNumber
                      : ""
                  }
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      shippingInvoiceNumber: e.target.value,
                    })
                  }
                  style={{ margin: "5px" }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={SHIPPING_COMPANY}
                  getOptionLabel={(option) => option.key}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Shipping company"
                      style={{ margin: "5px" }}
                    />
                  )}
                  onChange={(event, value) => {
                    setSelectedItem({
                      ...selectedItem,
                      shippingCompany: value ? value.value : "",
                    });
                  }}
                  value={
                    SHIPPING_COMPANY.filter(
                      (x) => x.value == selectedItem.shippingCompany
                    )[0]
                      ? SHIPPING_COMPANY.filter(
                          (x) => x.value == selectedItem.shippingCompany
                        )[0]
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    inputFormat="YYYY-MM-DD"
                    views={["year", "month", "day"]}
                    label="Invoice Shipping Date"
                    name="invoiceShippingDate"
                    value={
                      selectedItem.invoiceShippingDate
                        ? selectedItem.invoiceShippingDate
                        : null
                    }
                    onChange={(value) =>
                      setSelectedItem({
                        ...selectedItem,
                        invoiceShippingDate: moment(value).format("YYYY-MM-DD"),
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        style={{ margin: "5px" }}
                        size="small"
                        variant="outlined"
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                }}
              >
                <Button
                  variant="contained"
                  sx={{ margin: "5px" }}
                  onClick={() => updateShippingTrackingInfo()}
                  disabled={
                    reload ||
                    props.itemStatus == "Canceled" ||
                    props.itemStatus == "Canceled By Customer" ||
                    !_UserInfo.roleOEndpoint
                      .map((data) => data.Code)
                      .includes("23-13")
                      ? true
                      : false
                  }
                >
                  update
                </Button>
              </Grid>
            </Grid>
          </CustomDialog>

          <CustomDialog
            isOpen={props.isOpenPurchasePrice}
            handleClose={() => {
              props.handleClose();
              setSelectedItem({});
            }}
            title="Update Purchase Price"
          >
            {reload ? (
              <LinearProgress sx={{ marginTop: -2, marginBottom: 2 }} />
            ) : null}
            <Grid container spacing={1} paddingBottom={1} paddingTop={1}>
              <Grid item xs={9}>
                <CustomText
                  label="Purchase Price"
                  value={
                    selectedItem.purchasePrice ? selectedItem.purchasePrice : ""
                  }
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      purchasePrice: e.target.value,
                    })
                  }
                  style={{ margin: "5px" }}
                  fullWidth
                  inputProps={{ type: "number" }}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  disabled={
                    reload ||
                    props.itemStatus == "Canceled" ||
                    props.itemStatus == "Canceled By Customer" ||
                    !_UserInfo.roleOEndpoint
                      .map((data) => data.Code)
                      .includes("23-12")
                      ? true
                      : false
                  }
                  variant="contained"
                  sx={{ margin: "5px" }}
                  onClick={() => purchaseprice()}
                  fullWidth
                >
                  update
                </Button>
              </Grid>
            </Grid>
          </CustomDialog>

          <CustomDialog
            isOpen={props.isOpenMultiItem}
            Draggable={true}
            handleClose={() => {
              props.handleClose();
              setSelectedItem({});
            }}
            title="Update Multi Items"
          >
            {reload ? (
              <LinearProgress sx={{ marginTop: -2, marginBottom: 2 }} />
            ) : null}
            <Typography sx={{ p: 1 }}>
              Items selected: {props.selectedRows.length}
            </Typography>
            <Grid container spacing={2} paddingBottom={1} paddingTop={1}>
              <Grid item xs={12}>
                <Divider>
                  <Chip label="Purchasing" />
                </Divider>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                >
                  <Autocomplete
                    disabled={
                      reload ||
                      props.itemStatus === "Canceled" ||
                      props.itemStatus === "Canceled By Customer" ||
                      !_UserInfo.roleOEndpoint
                        .map((data) => data.Code)
                        .includes("23-03")
                        ? true
                        : false
                    }
                    options={item_statuses}
                    getOptionLabel={(option) => option.name}
                    getOptionDisabled={(option) =>
                      option.id === 9 || option.id === 8
                    }
                    fullWidth
                    size="small"
                    onChange={(event, value) => {
                      setSelectedStatus(value ? value.id : null);
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} label="Change Status" />
                    )}
                  />
                  <Button
                    size="small"
                    disabled={
                      reload ||
                      props.itemStatus === "Canceled" ||
                      props.itemStatus === "Canceled By Customer" ||
                      !_UserInfo.roleOEndpoint
                        .map((data) => data.Code)
                        .includes("23-03")
                        ? true
                        : false
                    }
                    variant="contained"
                    onClick={() => updateOrderItemStatus()}
                    sx={{ marginX: "10px" }}
                  >
                    Update
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  disabled={
                    reload ||
                    props.itemStatus === "Canceled" ||
                    props.itemStatus === "Canceled By Customer" ||
                    !_UserInfo.roleOEndpoint
                      .map((data) => data.Code)
                      .includes("23-07")
                      ? true
                      : false
                  }
                  label="Invoice number"
                  value={
                    selectedItem.vendorInvoiceNumber
                      ? selectedItem.vendorInvoiceNumber
                      : ""
                  }
                  onChange={(e) => {
                    setSelectedItem({
                      ...selectedItem,
                      vendorInvoiceNumber: e.target.value,
                    });
                  }}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          size="small"
                          disabled={
                            reload ||
                            props.itemStatus === "Canceled" ||
                            props.itemStatus === "Canceled By Customer" ||
                            !_UserInfo.roleOEndpoint
                              .map((data) => data.Code)
                              .includes("23-07")
                              ? true
                              : false
                          }
                          variant="contained"
                          onClick={() => updateInvoiceNO()}
                          fullWidth
                        >
                          Update
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider>
                  <Chip label="Tracking" />
                </Divider>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  disabled={
                    reload ||
                    props.itemStatus === "Canceled" ||
                    props.itemStatus === "Canceled By Customer" ||
                    !_UserInfo.roleOEndpoint
                      .map((data) => data.Code)
                      .includes("23-04")
                      ? true
                      : false
                  }
                  label="Regional Tracking Number"
                  value={
                    selectedItem.vendorTrackingNumber
                      ? selectedItem.vendorTrackingNumber
                      : ""
                  }
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      vendorTrackingNumber: e.target.value,
                    })
                  }
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          size="small"
                          disabled={
                            reload ||
                            props.itemStatus === "Canceled" ||
                            props.itemStatus === "Canceled By Customer" ||
                            !_UserInfo.roleOEndpoint
                              .map((data) => data.Code)
                              .includes("23-04")
                              ? true
                              : false
                          }
                          variant="contained"
                          onClick={() => updateTrackNumber()}
                          fullWidth
                        >
                          Update
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  disabled={
                    reload ||
                    props.itemStatus === "Canceled" ||
                    props.itemStatus === "Canceled By Customer" ||
                    !_UserInfo.roleOEndpoint
                      .map((data) => data.Code)
                      .includes("23-05")
                      ? true
                      : false
                  }
                  label="International Tracking Number"
                  value={
                    selectedItem.internationalTrackingNumber
                      ? selectedItem.internationalTrackingNumber?.trim()
                      : ""
                  }
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      internationalTrackingNumber: e.target.value,
                    })
                  }
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          size="small"
                          disabled={
                            reload ||
                            props.itemStatus === "Canceled" ||
                            props.itemStatus === "Canceled By Customer" ||
                            !_UserInfo.roleOEndpoint
                              .map((data) => data.Code)
                              .includes("23-05")
                              ? true
                              : false
                          }
                          variant="contained"
                          onClick={() => updateInternationalTrackingnumber()}
                          fullWidth
                        >
                          Update
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  disabled={
                    reload ||
                    props.itemStatus === "Canceled" ||
                    props.itemStatus === "Canceled By Customer" ||
                    !_UserInfo.roleOEndpoint
                      .map((data) => data.Code)
                      .includes("23-08")
                      ? true
                      : false
                  }
                  label="Note"
                  value={selectedItem.note ? selectedItem.note : ""}
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      note: e.target.value,
                    })
                  }
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          size="small"
                          disabled={
                            reload ||
                            props.itemStatus === "Canceled" ||
                            props.itemStatus === "Canceled By Customer" ||
                            !_UserInfo.roleOEndpoint
                              .map((data) => data.Code)
                              .includes("23-08")
                              ? true
                              : false
                          }
                          variant="contained"
                          onClick={() => updateItemNote()}
                          fullWidth
                        >
                          Update
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      disabled={
                        reload ||
                        props.itemStatus === "Canceled" ||
                        props.itemStatus === "Canceled By Customer" ||
                        !_UserInfo.roleOEndpoint
                          .map((data) => data.Code)
                          .includes("23-09")
                          ? true
                          : false
                      }
                      inputFormat="YYYY-MM-DD"
                      views={["year", "month", "day"]}
                      label="Delivery date"
                      name="deliveryDate"
                      value={
                        selectedItem.itemDeliveryDate
                          ? selectedItem.itemDeliveryDate
                          : null
                      }
                      onChange={(value) =>
                        setSelectedItem({
                          ...selectedItem,
                          itemDeliveryDate: value,
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <Button
                    size="small"
                    disabled={
                      reload ||
                      props.itemStatus === "Canceled" ||
                      props.itemStatus === "Canceled By Customer" ||
                      !_UserInfo.roleOEndpoint
                        .map((data) => data.Code)
                        .includes("23-09")
                        ? true
                        : false
                    }
                    variant="contained"
                    onClick={() => updateDeliveryDate()}
                    sx={{ marginX: "10px" }}
                  >
                    Update
                  </Button>
                </Box>
              </Grid>
              {/* <Grid item xs={12}>
                <Divider sx={{ mt: 3 }}>
                  <Chip label="Category" />
                </Divider>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple
                  disabled={
                    reload ||
                    props.itemStatus === "Canceled" ||
                    props.itemStatus === "Canceled By Customer" ||
                    !_UserInfo.roleOEndpoint
                      .map((data) => data.Code)
                      .includes("23-19")
                      ? true
                      : false
                  }
                  options={item_internal_statuses}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField label="Main Category" {...params} />
                  )}
                  onChange={(event, value) => {
                    var ids = value.map((x) => x.id);
                    setSelectedInternalStatuses(value ? ids : null);
                  }}
                  value={item_internal_statuses?.filter((x) =>
                    selectedInternalStatuses
                      ? selectedInternalStatuses?.includes(x.id)
                      : null
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple
                  disabled={
                    reload ||
                    props.itemStatus === "Canceled" ||
                    props.itemStatus === "Canceled By Customer" ||
                    !_UserInfo.roleOEndpoint
                      .map((data) => data.Code)
                      .includes("23-19")
                      ? true
                      : false
                  }
                  options={item_internal_statuses}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField label="Sub Main Category" {...params} />
                  )}
                  onChange={(event, value) => {
                    var ids = value.map((x) => x.id);
                    setSelectedInternalStatuses(value ? ids : null);
                  }}
                  value={item_internal_statuses?.filter((x) =>
                    selectedInternalStatuses
                      ? selectedInternalStatuses?.includes(x.id)
                      : null
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple
                  disabled={
                    reload ||
                    props.itemStatus === "Canceled" ||
                    props.itemStatus === "Canceled By Customer" ||
                    !_UserInfo.roleOEndpoint
                      .map((data) => data.Code)
                      .includes("23-19")
                      ? true
                      : false
                  }
                  options={item_internal_statuses}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField label="Product Category" {...params} />
                  )}
                  onChange={(event, value) => {
                    var ids = value.map((x) => x.id);
                    setSelectedInternalStatuses(value ? ids : null);
                  }}
                  value={item_internal_statuses?.filter((x) =>
                    selectedInternalStatuses
                      ? selectedInternalStatuses?.includes(x.id)
                      : null
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  disabled={
                    reload ||
                    props.itemStatus === "Canceled" ||
                    props.itemStatus === "Canceled By Customer" ||
                    !_UserInfo.roleOEndpoint
                      .map((data) => data.Code)
                      .includes("23-19")
                      ? true
                      : false
                  }
                  variant="contained"
                  onClick={() => updateOrderItemInternalStatus()}
                >
                  Update
                </Button>
              </Grid> */}
            </Grid>

            <Divider sx={{ mt: 4 }}>
              <Chip label="Shipment Tracking Info" />
            </Divider>
            <Grid container spacing={1} paddingBottom={1} paddingTop={1}>
              <Grid item xs={12}>
                <TextField
                  disabled={
                    reload ||
                    props.itemStatus == "Canceled" ||
                    props.itemStatus == "Canceled By Customer" ||
                    !_UserInfo.roleOEndpoint
                      .map((data) => data.Code)
                      .includes("23-13")
                      ? true
                      : false
                  }
                  size="small"
                  type="number"
                  label="Real weight"
                  value={selectedItem.realWeight ? selectedItem.realWeight : ""}
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      realWeight: e.target.value,
                    })
                  }
                  onWheel={(e) => null}
                  style={{ margin: "5px" }}
                  fullWidth
                  helperText="weight should be in Pound unit"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={
                    reload ||
                    props.itemStatus == "Canceled" ||
                    props.itemStatus == "Canceled By Customer" ||
                    !_UserInfo.roleOEndpoint
                      .map((data) => data.Code)
                      .includes("23-13")
                      ? true
                      : false
                  }
                  size="small"
                  label="Shipping invoice number"
                  value={
                    selectedItem.shippingInvoiceNumber
                      ? selectedItem.shippingInvoiceNumber
                      : ""
                  }
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      shippingInvoiceNumber: e.target.value,
                    })
                  }
                  style={{ margin: "5px" }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disabled={
                    reload ||
                    props.itemStatus == "Canceled" ||
                    props.itemStatus == "Canceled By Customer" ||
                    !_UserInfo.roleOEndpoint
                      .map((data) => data.Code)
                      .includes("23-13")
                      ? true
                      : false
                  }
                  options={SHIPPING_COMPANY}
                  getOptionLabel={(option) => option.key}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Shipping company"
                      style={{ margin: "5px" }}
                    />
                  )}
                  onChange={(event, value) => {
                    setSelectedItem({
                      ...selectedItem,
                      shippingCompany: value ? value.value : "",
                    });
                  }}
                  value={
                    SHIPPING_COMPANY.filter(
                      (x) => x.value == selectedItem.shippingCompany
                    )[0]
                      ? SHIPPING_COMPANY.filter(
                          (x) => x.value == selectedItem.shippingCompany
                        )[0]
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    disabled={
                      reload ||
                      props.itemStatus == "Canceled" ||
                      props.itemStatus == "Canceled By Customer" ||
                      !_UserInfo.roleOEndpoint
                        .map((data) => data.Code)
                        .includes("23-13")
                        ? true
                        : false
                    }
                    inputFormat="YYYY-MM-DD"
                    views={["year", "month", "day"]}
                    label="Invoice Shipping Date"
                    name="invoiceShippingDate"
                    value={
                      selectedItem.invoiceShippingDate
                        ? selectedItem.invoiceShippingDate
                        : null
                    }
                    onChange={(value) =>
                      setSelectedItem({
                        ...selectedItem,
                        invoiceShippingDate: moment(value).format("YYYY-MM-DD"),
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        style={{ margin: "5px" }}
                        size="small"
                        variant="outlined"
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                }}
              >
                <Button
                  disabled={
                    reload ||
                    props.itemStatus == "Canceled" ||
                    props.itemStatus == "Canceled By Customer" ||
                    !_UserInfo.roleOEndpoint
                      .map((data) => data.Code)
                      .includes("23-13")
                      ? true
                      : false
                  }
                  variant="contained"
                  sx={{ margin: "5px" }}
                  onClick={() => updateShippingTrackingInfo()}
                >
                  update
                </Button>
              </Grid>
            </Grid>
          </CustomDialog>
        </Box>
      )}
    </Box>
  );
}
