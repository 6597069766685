import {
  Box,
  Card,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { transactions } from "src/actions/reports";
import CustomSpinner from "src/components/CustomSpinner";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import moment from "moment";

export default function Transactions(props) {
  const dispatch = new useDispatch();
  const [loading, setLoading] = useState(false);
  const [transactionsData, setTransactionsData] = useState([]);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    handleTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.date, props.setSearch]);

  function handleTransactions() {
    setLoading(true);
    dispatch(
      transactions({
        from: props.date.startDate,
        to: props.date.endDate,
      })
    ).then((res) => {
      setTransactionsData(res.data);
      setLoading(false);
    });
  }

  const columns = [
    {
      field: "sequence",
      headerName: "#",
      width: 5,
    },
    {
      field: "transactionNumber",
      width: 150,
      headerName: "Transaction Number",
    },
    {
      field: "fromName",
      width: 150,
      headerName: "From Name",
      renderCell: (params) => (
        <Typography>
          {params.row.fromName ? params.row.fromName : "----"}
        </Typography>
      ),
    },
    {
      field: "toName",
      width: 150,
      headerName: "To Name",
      renderCell: (params) => (
        <Typography>
          {params.row.toName ? params.row.toName : "----"}
        </Typography>
      ),
    },
    {
      field: "fromPhoneNumber",
      width: 150,
      headerName: "From Phone Number",
      renderCell: (params) => (
        <Typography>
          {params.row.fromPhoneNumber ? params.row.fromPhoneNumber : "----"}
        </Typography>
      ),
    },
    {
      field: "toPhoneNumber",
      width: 150,
      headerName: "To Phone Number",
      renderCell: (params) => (
        <Typography>
          {params.row.toPhoneNumber ? params.row.toPhoneNumber : "----"}
        </Typography>
      ),
    },
    {
      field: "fromId",
      width: 400,
      headerName: "From User Id",
      renderCell: (params) => (
        <Typography>
          {params.row.fromId ? params.row.fromId : "----"}
        </Typography>
      ),
    },
    {
      field: "toId",
      width: 400,
      headerName: "To User Id",
      renderCell: (params) => (
        <Typography>{params.row.toId ? params.row.toId : "----"}</Typography>
      ),
    },
    // {
    //   field: "amount",
    //   width: 150,
    //   headerName: "Amount",
    //   renderCell: (params) => <Typography>{`${params.row.amount} $`}</Typography>
    // },
    {
      field: "credit",
      width: 150,
      headerName: "Credit $",
      renderCell: (params) => (
        <Typography>
          {params.row.credit ? params.row.credit?.toLocaleString() : null}
        </Typography>
      ),
    },
    {
      field: "debit",
      width: 150,
      headerName: "Debit $",
      renderCell: (params) => (
        <Typography>
          {params.row.debit ? params.row.debit?.toLocaleString() : null}
        </Typography>
      ),
    },
    {
      field: "transactionBy",
      width: 150,
      headerName: "Transaction By",
    },
    {
      field: "orderId",
      width: 350,
      headerName: "Order Id",
      renderCell: (params) => (
        <Typography>
          {params.row.orderId ? params.row.orderId : "----"}
        </Typography>
      ),
    },
    {
      field: "exchangeRatePrice",
      width: 160,
      headerName: "Exchange Rate Price $",
      renderCell: (params) => (
        <Typography>
          {params.row.exchangeRatePrice
            ? params.row.exchangeRatePrice?.toLocaleString()
            : null}
        </Typography>
      ),
    },
    {
      field: "reason",
      width: 150,
      headerName: "Reason",
    },
    {
      field: "ePaymentType",
      width: 150,
      headerName: "Epayment Type",
      renderCell: (params) => (
        <Typography>
          {params.row.ePaymentType ? params.row.ePaymentType : "----"}
        </Typography>
      ),
    },
    {
      field: "createdAt",
      width: 180,
      headerName: "Date",
      renderCell: (params) => (
        <Typography>
          {params.row.createdAt &&
            moment(params.row.createdAt).format("yyyy-MM-DD / hh:mm a")}{" "}
        </Typography>
      ),
    },
    {
      field: "orderDate",
      width: 180,
      headerName: "Order Date",
      renderCell: (params) => (
        <Typography>
          {params.row.orderDate &&
            moment(params.row.orderDate).format("yyyy-MM-DD / hh:mm a")}{" "}
        </Typography>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <Box>
        <Box>
          <Box>
            <Card>
              <CardHeader
                title="Transactions Reports"
                subheader={
                  <Stack direction="column" spacing={1}>
                    <Typography variant="caption">
                      {props.date.startDate + " - " + props.date.endDate}
                    </Typography>
                  </Stack>
                }
              />
              <Divider />
            </Card>
          </Box>
        </Box>
        <Box>
          <GridToolbarContainer>
            <GridToolbarExport
              csvOptions={{
                fileName: `Transactions Report || Orderii || ${
                  props.date.startDate + " - " + props.date.endDate
                }`,
                utf8WithBom: true,
              }}
            />
          </GridToolbarContainer>
        </Box>
      </Box>
    );
  }
  return (
    <Card style={{ marginTop: 10 }}>
      <PerfectScrollbar>
        <Box>
          {loading ? (
            <CustomSpinner />
          ) : (
            <Box sx={{ height: "calc(100vh - 320px)" }}>
              <DataGrid
                sx={{
                  "& .MuiDataGrid-row ": {
                    textAlign: "center",
                  },
                  "& .css-204u17-MuiDataGrid-main": {
                    paddingLeft: "15px",
                  },
                  "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                    paddingLeft: "15px",
                  },
                }}
                columns={columns}
                rows={transactionsData.map((row, index) => {
                  return { ...row, sequence: index + 1 };
                })}
                getRowId={(params) => params.createdAt}
                components={{
                  Toolbar: CustomToolbar,
                }}
                disableSelectionOnClick
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[25, 75, 100]}
              />
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
