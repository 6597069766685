import {
  Card,
  CardHeader,
  Divider,
  Stack,
  Typography,
  Box,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  customerInfoReportExport,
  getCustomerInfoReport,
  saveCustomerInfoReportSearchBody,
  saveWalletsAndBanksAccountPageNo,
} from "src/actions/reports";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import moment from "moment";

export default function CustomerInfoReport(props) {
  const dispatch = new useDispatch();

  const {
    customerInfoReportData,
    customerInfoReportSearchBody,
    customerInfoReportPageNo,
    reload,
  } = useSelector((state) => state.reports);

  useEffect(() => {
    dispatch(
      getCustomerInfoReport(customerInfoReportPageNo + 1, {
        pageSize: customerInfoReportSearchBody.pageSize,
        fromCreatedAt: props.date.startDate,
        toCreatedAt: props.date.endDate,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.date,
    props.setSearch,
    customerInfoReportPageNo,
    customerInfoReportSearchBody,
  ]);

  const handlePageChange = (newPage) => {
    dispatch(saveWalletsAndBanksAccountPageNo(newPage));
  };

  const handleLimitChange = (newPageSize) => {
    dispatch(
      saveCustomerInfoReportSearchBody({
        ...customerInfoReportSearchBody,
        pageSize: newPageSize,
      })
    );
  };

  const columns = [
    {
      field: "sequence",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "id",
      headerName: "User Id",
      width: 300,
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
    },
    {
      field: "age",
      headerName: "Age",
      width: 100,
    },
    {
      field: "genderName",
      headerName: "Gender",
      width: 100,
    },
    {
      field: "delivery",
      headerName: "Delivery Location",
      width: 300,
      renderCell: (params) =>
        params.row.province?.nameUS
          ? params.row.province?.nameUS + " - " + params.row.city?.nameUS
          : "",
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
    },
    {
      field: "createdAt",
      headerName: "Date of Account Creation",
      width: 300,
      renderCell: (params) =>
        moment(params.row.createdAt).format("YYYY-MM-DD / hh:mm a"),
    },
    {
      field: "firstOrderAt",
      headerName: "First Order Date",
      width: 300,
      renderCell: (params) =>
        params.row.firstOrderAt
          ? moment(params.row.firstOrderAt).format("YYYY-MM-DD / hh:mm a")
          : "No Order",
    },
    {
      field: "isActive",
      headerName: "Account Activation",
      width: 300,
    },
  ];

  function CustomToolbar() {
    return (
      <div>
        <Box>
          <Box>
            <Card>
              <CardHeader
                title="Customer Information Report"
                subheader={
                  <Stack direction="column" spacing={1}>
                    <Typography variant="caption">
                      {props.date.startDate
                        ? props.date.startDate + " - " + props.date.endDate
                        : "No date selected"}
                    </Typography>
                  </Stack>
                }
              />
              <Divider />
            </Card>
          </Box>
          <Box>
            <GridToolbarContainer>
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(
                    customerInfoReportExport(customerInfoReportPageNo + 1, {
                      from: props.date.startDate,
                      to: props.date.endDate,
                      isExport: true,
                    })
                  );
                }}
              >
                Export
              </Button>
            </GridToolbarContainer>
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <Card style={{ marginTop: 10 }} ref={props.print}>
      <PerfectScrollbar>
        <Box sx={{ height: "calc(100vh - 320px)" }}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-row ": {
                textAlign: "center",
              },
              "& .css-204u17-MuiDataGrid-main": {
                paddingLeft: "15px",
              },
              "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                paddingLeft: "15px",
              },
            }}
            columns={columns}
            loading={reload}
            rows={customerInfoReportData?.data?.map((row, index) => {
              return { ...row, sequence: index + 1 };
            })}
            pagination
            pageSize={customerInfoReportSearchBody.pageSize}
            page={customerInfoReportPageNo}
            onPageChange={(newPage) => handlePageChange(newPage)}
            onPageSizeChange={(newPageSize) => handleLimitChange(newPageSize)}
            rowCount={customerInfoReportData.rowCount}
            getRowId={(params) => params.sequence}
            paginationMode="server"
            rowsPerPageOptions={[15, 25, 75, 100]}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            disableSelectionOnClick
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
