import * as React from "react";
import { Box } from "@mui/system";
import {
  Button,
  Card,
  Divider,
  Tooltip,
  Typography,
  Grid,
  Chip,
} from "@mui/material";
import moment from "moment";
import { getDetails, updateLockerNumber } from "src/actions/orders";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { Icon } from "@mui/material";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";
import { useState } from "react";
import { RiVipFill } from "react-icons/ri";
import { RiVipCrownFill } from "react-icons/ri";
import { CustomDialog } from "src/components/CustomDialog";
import OrderStatusMenu from "../../menu/OrderStatus";
import TimelineDialog from "../../timeline/OrderTimeline";
import GenerateInvoice from "../../generate/generateInvoice";
import CancelDialog from "../../CancelDialog/CancelDialog";
import GenerateLabel from "src/pages/cross/orders/generate/generateLabel";
import ReactStars from "react-rating-stars-component";
import UserTierMenu from "src/pages/cross/Users/userTierMenu";
import OrderActionsMenu from "src/pages/cross/orders/orderDetails/details/orderActions";
import {
  assignToLocker,
  clearLocker,
  openAvailableLockersDialog,
  openLockerHistoryDialog,
} from "src/actions/locker";
import AvailableLockersDialog from "src/pages/cross/lockers/AvailableLockersList";
import RolePermission from "src/components/RolePermission";
import LockerHistoryDialog from "src/pages/cross/lockers/lockerHistory";

export default function Details(props) {
  const dispatch = useDispatch();

  const order = useSelector((state) => state.orders.orderDetails);
  const _UserInfo = useSelector((state) => state.users.userInfo);
  const availableLockers = useSelector(
    (state) => state.locker.availableLockersDialog
  );
  const lockerHistoryDialog = useSelector(
    (state) => state.locker.lockerHistoryDialog
  );

  const [openLabelDialog, setOpenLabelDialog] = useState(false);
  const [openTimelineDialog, setOpenTimelineDialog] = useState(false);
  const [isOrder, setIsOrder] = useState(null);
  const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
  const [openCancelReasonsDialog, setOpenCancelReasonsDialog] = useState(false);
  const [openRatingDialog, setOpenRatingDialog] = useState(false);
  const [ratingData, setRatingData] = useState({});

  const orderId = window.location.pathname.replace("/app/order/", "");

  function handleLockerNumber() {
    dispatch(updateLockerNumber(orderId, ""));
  }

  function handleAutoAssignToLocker() {
    dispatch(
      assignToLocker(
        {
          orderId: props.selectedRows.length > 0 ? null : orderId,
          orderItemsIds: props.selectedRows.length ? props.selectedRows : null,
          lockerId: null,
        },
        orderId
      )
    ).then((x) => {
      if (x) {
        props.onSuccess();
      }
    });
  }

  function handleClearLocker() {
    dispatch(clearLocker(orderId)).then(() => {
      props.onSuccess();
    });
  }

  return (
    <Box>
      {availableLockers ? (
        <AvailableLockersDialog
          orderId={orderId}
          selectedRows={props.selectedRows}
          onSuccess={() => props.onSuccess()}
          onSelectedRows={(rows) => props.onSelectedRows(rows)}
        />
      ) : null}
      {lockerHistoryDialog ? <LockerHistoryDialog /> : null}
      <CustomDialog
        isOpen={openInvoiceDialog}
        handleClose={() => setOpenInvoiceDialog(false)}
        title={"INVOICE"}
        maxWidth="md"
      >
        <GenerateInvoice data={order} />
      </CustomDialog>

      <CustomDialog
        isOpen={openLabelDialog}
        handleClose={() => setOpenLabelDialog(false)}
        title={"Label"}
        maxWidth="md"
      >
        <GenerateLabel data={order} />
      </CustomDialog>

      <CancelDialog
        open={openCancelReasonsDialog}
        handleClose={() => setOpenCancelReasonsDialog(false)}
        type={isOrder ? "order" : "item"}
        id={orderId}
        orderId={orderId}
        onError={(msg) => dispatch(showErrorSnackbar(msg))}
        onSuccess={(msg) => {
          dispatch(showSuccessSnackbar(msg));
          props.refresh();
        }}
      />
      <TimelineDialog
        open={openTimelineDialog}
        handleClose={() => setOpenTimelineDialog(false)}
        orderId={order.id}
      />
      <CustomDialog
        isOpen={openRatingDialog}
        handleClose={() => setOpenRatingDialog(false)}
        title="Order Rating"
        maxWidth="md"
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h3">Average:</Typography>
          <ReactStars
            edit={false}
            value={ratingData?.ratingAverage}
            size={40}
          />
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={2}>
          {ratingData?.rates?.map((data, index) => (
            <Grid item xs={12} key={index}>
              <Typography variant="h4">
                {data?.ratingQuestion?.questionEn}
              </Typography>
              <ReactStars edit={false} value={data.rate} size={32} />
              {index !== ratingData?.rates?.length - 1 && <Divider />}
            </Grid>
          ))}
        </Grid>
      </CustomDialog>
      {order.items.length !== 0 ? (
        <Stack direction="column">
          <Card sx={{ padding: 2 }}>
            <Box>
              <Box display="flex" justifyContent="end">
                <OrderActionsMenu
                  order={order}
                  _UserInfo={_UserInfo}
                  setOpenInvoiceDialog={setOpenInvoiceDialog}
                  setOpenLabelDialog={setOpenLabelDialog}
                  setOpenTimelineDialog={setOpenTimelineDialog}
                  setOpenRatingDialog={setOpenRatingDialog}
                  setRatingData={setRatingData}
                  onSuccess={(e) => props.refresh()}
                  onCancel={() => {
                    setIsOrder(true);
                    setOpenCancelReasonsDialog(true);
                  }}
                />
              </Box>
              <Stack
                sx={{ justifyContent: "space-between", alignItems: "center" }}
                direction={"row"}
                spacing={1}
              >
                <OrderStatusMenu
                  orderId={order.id}
                  status={order.status}
                  onSuccess={(e) => props.refresh()}
                  onCancel={() => {
                    setIsOrder(true);
                    setOpenCancelReasonsDialog(true);
                  }}
                />
                {order.isVipShipped && (
                  <Stack direction="column" spacing={-0.8}>
                    <RiVipCrownFill size={30} color="#D99B12" />
                    <RiVipFill size={30} color="#D99B12" />
                  </Stack>
                )}
              </Stack>
              <Stack direction="row" spacing={1} sx={{ py: 0.5 }}>
                <Typography variant="h4">{order.number ?? ""}</Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{ py: 0.5 }}
                justifyContent="space-between"
              >
                <Typography variant="h4">Total amount:</Typography>
                <Typography variant="h4">{order.totalAmount ?? 0}$</Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{ py: 0.5 }}
                justifyContent="space-between"
              >
                <Typography variant="h5">Subtotal:</Typography>
                <Typography variant="h5">{order.subTotal ?? 0}$</Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{ py: 0.5 }}
                justifyContent="space-between"
              >
                <Typography variant="h5">IQ customs:</Typography>
                <Typography variant="h5">
                  {order.totalIQCustoms ?? 0}$
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{ py: 0.5 }}
                justifyContent="space-between"
              >
                <Typography variant="h5">Orderii Fee:</Typography>
                <Typography variant="h5">{order.orderiiFee ?? 0}$</Typography>
              </Stack>
              {order.aqsatiFee ? (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ py: 0.5 }}
                  justifyContent="space-between"
                >
                  <Typography variant="h5">Aqsati Fee:</Typography>
                  <Typography variant="h5">{order.aqsatiFee ?? 0}$</Typography>
                </Stack>
              ) : null}
              {order.hasUSItem && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ py: 0.5 }}
                  justifyContent="space-between"
                >
                  <Typography variant="h5">US shipping:</Typography>
                  <Typography variant="h5">
                    {order.totalUSShipping ?? 0}$
                  </Typography>
                </Stack>
              )}
              {order.hasTRItem && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ py: 0.5 }}
                  justifyContent="space-between"
                >
                  <Typography variant="h5">TR shipping:</Typography>
                  <Typography variant="h5">
                    {order.totalTRShipping ?? 0}$
                  </Typography>
                </Stack>
              )}
              {order.hasAGItem && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ py: 0.5 }}
                  justifyContent="space-between"
                >
                  <Typography variant="h5">AG shipping:</Typography>
                  <Typography variant="h5">
                    {order.totalAGShipping ?? 0}$
                  </Typography>
                </Stack>
              )}
              {order.hasEUItem && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ py: 0.5 }}
                  justifyContent="space-between"
                >
                  <Typography variant="h5">EU shipping:</Typography>
                  <Typography variant="h5">
                    {order.totalEUShiiping ?? 0}$
                  </Typography>
                </Stack>
              )}
              {order.hasWSMItem && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ py: 0.5 }}
                  justifyContent="space-between"
                >
                  <Typography variant="h5">WSM shipping:</Typography>
                  <Typography variant="h5">
                    {order.totalWSMShipping ?? 0}$
                  </Typography>
                </Stack>
              )}
              <Stack
                direction="row"
                spacing={1}
                sx={{ py: 0.5 }}
                justifyContent="space-between"
              >
                <Typography variant="h5">To be delivered:</Typography>
                <Stack direction="row" spacing={1}>
                  {order.toBeDelivered ? (
                    <>
                      <Icon color="success">check_circle</Icon>
                      <Typography color="success">
                        {order.deliveryCost}$
                      </Typography>
                    </>
                  ) : (
                    <Icon color="error">cancel</Icon>
                  )}
                </Stack>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{ py: 0.5 }}
                justifyContent="space-between"
              >
                <Typography variant="h5">Insurance:</Typography>
                <Stack direction="row" spacing={1}>
                  {order.isInsured ? (
                    <>
                      <Icon color="success">check_circle</Icon>
                      <Typography color="success">
                        {order.insuranceFee}$
                      </Typography>
                    </>
                  ) : (
                    <Icon color="error">cancel</Icon>
                  )}
                </Stack>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{ py: 0.5 }}
                justifyContent="space-between"
              >
                <Typography variant="h5">VIP Shipping:</Typography>
                <Stack direction="column" spacing={1}>
                  {order.isVipShipped ? (
                    <>
                      <Icon color="success">check_circle</Icon>
                    </>
                  ) : (
                    <Icon color="error">cancel</Icon>
                  )}
                </Stack>
              </Stack>
              {order.isVipShipped ? (
                <Box>
                  {order.hasUSItem && (
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ py: 0.5 }}
                      justifyContent="space-between"
                    >
                      <Typography variant="h5" color="success">
                        Total Vip US Tax:
                      </Typography>
                      <Typography variant="h5" color="success">
                        {order.totalVipUSTax}$
                      </Typography>
                    </Stack>
                  )}
                  {order.hasTRItem && (
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ py: 0.5 }}
                      justifyContent="space-between"
                    >
                      <Typography variant="h5" color="success">
                        Total Vip TR Tax:
                      </Typography>
                      <Typography variant="h5" color="success">
                        {order.totalVipTRTax}$
                      </Typography>
                    </Stack>
                  )}
                  {order.hasAGItem && (
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ py: 0.5 }}
                      justifyContent="space-between"
                    >
                      <Typography variant="h5" color="success">
                        Total Vip AG Tax:
                      </Typography>
                      <Typography variant="h5" color="success">
                        {order.totalVipAGTax}$
                      </Typography>
                    </Stack>
                  )}
                  {order.hasEUItem && (
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ py: 0.5 }}
                      justifyContent="space-between"
                    >
                      <Typography variant="h5" color="success">
                        Total Vip EU Tax:
                      </Typography>
                      <Typography variant="h5" color="success">
                        {order.totalVipEUTax}$
                      </Typography>
                    </Stack>
                  )}
                  {order.hasWSMItem && (
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ py: 0.5 }}
                      justifyContent="space-between"
                    >
                      <Typography variant="h5" color="success">
                        Total Vip WSM Tax:
                      </Typography>
                      <Typography variant="h5" color="success">
                        {order.totalVipWSMTax}$
                      </Typography>
                    </Stack>
                  )}
                </Box>
              ) : null}
              {order.promoCodeId && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ py: 0.5 }}
                  justifyContent="space-between"
                >
                  <Typography variant="h5">Promo Code:</Typography>
                  <Typography variant="h5">
                    {order.promoCodeName ?? ""}
                  </Typography>
                </Stack>
              )}
              {order.referralDiscount && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ py: 0.5 }}
                  justifyContent="space-between"
                >
                  <Typography variant="h5">Referral Code:</Typography>
                  <Typography variant="h5">{order.referralCode}</Typography>
                  <Icon color="success">check_circle</Icon>
                </Stack>
              )}
              <Stack
                direction="row"
                spacing={1}
                sx={{ py: 0.5 }}
                justifyContent="space-between"
              >
                <Typography variant="h5">Total discount:</Typography>
                <Typography variant="h5">
                  {order.totalDiscount ?? "0"}$
                </Typography>
              </Stack>
              {order.totalCashBack ? (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ py: 0.5 }}
                  justifyContent="space-between"
                >
                  <Typography variant="h5">Total CashBack:</Typography>
                  <Typography variant="h5">
                    {order.totalCashBack ?? "0"}$
                  </Typography>
                </Stack>
              ) : null}
              <Stack
                direction="row"
                spacing={1}
                sx={{ py: 0.5 }}
                justifyContent="space-between"
              >
                <Typography variant="h5">
                  Total Amount At Creation Date:
                </Typography>
                <Typography variant="h5">
                  {order.totalAmountAtCreationDate ?? "0"}$
                </Typography>
              </Stack>
              <Divider sx={{ margin: "5px 0" }}></Divider>
              {/* {order.lockerNumber ? (
                <RolePermission permission="22-14">
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography fontWeight="bold">
                      Locker: {order.lockerNumber ?? ""}
                    </Typography>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleLockerNumber}
                    >
                      Clear
                    </Button>
                  </Stack>
                  <Divider sx={{ margin: 1 }}></Divider>
                </RolePermission>
              ) : null} */}
            </Box>
            <Stack spacing={1}>
              <Stack direction={"row"} spacing={1}>
                <Tooltip title="Customer name">
                  <Icon>account_circle</Icon>
                </Tooltip>
                <Typography variant="body1">
                  {order.customer ? order.customer.fullName : ""}{" "}
                  <UserTierMenu
                    userId={order?.customer?.id}
                    tier={order?.customer?.customerTier}
                    onSuccess={() => dispatch(getDetails(order.id))}
                  />
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Tooltip title="Customer phone number">
                  <Icon>call</Icon>
                </Tooltip>
                <Typography variant="body1">
                  {order.customer ? order.customer.phoneNumber : ""}
                </Typography>
              </Stack>
              {order.customer.address ? (
                <Stack direction={"row"} spacing={1}>
                  <Tooltip title="Customer address">
                    <Icon>pin_drop</Icon>
                  </Tooltip>
                  <Typography variant="body1">
                    {order.customer ? order.customer.address : ""}
                  </Typography>
                </Stack>
              ) : null}
              {order.reviewer ? (
                <Stack direction={"row"} spacing={1}>
                  <Tooltip title="Reviewer name">
                    <Icon>assignment_ind</Icon>
                  </Tooltip>
                  <Typography variant="body1">{order.reviewer}</Typography>
                </Stack>
              ) : null}
              <Stack direction={"row"} spacing={1}>
                <Tooltip title="Order date">
                  <Icon>today</Icon>
                </Tooltip>
                <Typography variant="body1">
                  {moment(order.createdAt).format("yyy-MM-DD / hh:mm a")}
                </Typography>
              </Stack>
              <Divider />
            </Stack>
            <Grid container spacing={2} marginTop={1}>
              <Grid item xs={12}>
                <Typography textAlign="center" variant="h5">
                  Lockers
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  {[
                    ...new Set(
                      order.items
                        .filter(
                          (item) =>
                            item.locker !== null && item.locker !== undefined
                        )
                        .map((item) => item.locker.number)
                    ),
                  ].map((lockerNumber, index) => (
                    <Chip
                      sx={{ marginY: 0.5 }}
                      color="primary"
                      clickable
                      key={index}
                      label={lockerNumber}
                      onClick={() => {
                        const locker = order.items.find(
                          (item) => item.locker.number === lockerNumber
                        ).locker;
                        dispatch(openLockerHistoryDialog(locker.id));
                      }}
                    />
                  ))}
                </Stack>
              </Grid>
              <RolePermission permission="71-07">
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handleAutoAssignToLocker}
                  >
                    Assign
                  </Button>
                </Grid>
              </RolePermission>
              <RolePermission permission="71-09">
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="error"
                    disabled={order.lockerLastModifier ? false : true}
                    onClick={handleClearLocker}
                  >
                    clear
                  </Button>
                </Grid>
              </RolePermission>
              <RolePermission permission="71-07">
                <Grid item xs={12} md={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="warning"
                    onClick={() => dispatch(openAvailableLockersDialog())}
                  >
                    Select Locker
                  </Button>
                </Grid>
              </RolePermission>
            </Grid>
          </Card>
        </Stack>
      ) : null}
    </Box>
  );
}
