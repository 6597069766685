import {
  Grid,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Divider,
  Box,
  Stack,
  TextField,
  Card,
  CardContent,
  Tooltip,
  Autocomplete,
  Pagination,
  Select,
  MenuItem,
  DialogActions,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  getCartHistory,
  getReviewersList,
  openCartHistoryDialog,
  saveCartHistoryPageNo,
  saveCartHistorySearchBody,
} from "src/actions/cart";
import { FaLongArrowAltRight } from "react-icons/fa";
import CustomEmpty from "src/components/CustomEmpty";
import CustomSpinner from "src/components/CustomSpinner";
import DateRangepicker from "src/components/DateRangePicker";
import { cart_history_type } from "src/utils/common";
import { Search as SearchIcon } from "react-feather";
import Close from "@mui/icons-material/Close";

const CartHistoryDialog = (props) => {
  const dispatch = useDispatch();

  const {
    cartHistory,
    cartHistorySearchBody,
    cartHistoryDialog,
    selectedCart,
    cartHistoryPageNo,
    reload,
  } = useSelector((state) => state.cart);

  const [reviewersData, setReviewersData] = useState([]);

  useEffect(() => {
    dispatch(getReviewersList())
      .then((res) => {
        setReviewersData(res.data);
      })
      .catch((err) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCart) {
      dispatch(
        getCartHistory(cartHistoryPageNo, {
          ...cartHistorySearchBody,
          cartId: selectedCart,
          pageSize: 100,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartHistoryPageNo, cartHistorySearchBody]);

  const handlePageChange = (event, newPage) => {
    dispatch(saveCartHistoryPageNo(newPage));
  };

  const handleDialog = () => {
    dispatch(openCartHistoryDialog());
  };

  const camelCaseToCapitalized = (str) => {
    return str
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (char) => char.toUpperCase());
  };

  return (
    <Dialog
      open={cartHistoryDialog}
      keepMounted
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Typography color="primary">Cart History</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              options={reviewersData}
              getOptionLabel={(option) => (option ? option.name : "")}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Reviewers" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveCartHistorySearchBody({
                    ...cartHistorySearchBody,
                    reviewerId: value?.id,
                  })
                );
              }}
              value={
                reviewersData.filter(
                  (x) => x.id === cartHistorySearchBody.reviewerId
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              options={cart_history_type}
              getOptionLabel={(option) => (option ? option.name : "")}
              fullWidth
              size="small"
              renderInput={(params) => <TextField {...params} label="Types" />}
              onChange={(event, value) => {
                dispatch(
                  saveCartHistorySearchBody({
                    ...cartHistorySearchBody,
                    type: value?.id,
                  })
                );
              }}
              value={
                cart_history_type.filter(
                  (x) => x.id === cartHistorySearchBody.type
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DateRangepicker
              disabled={reload}
              startDate={cartHistorySearchBody.fromReviewAt}
              endDate={cartHistorySearchBody.toReviewAt}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  fromCreatedAt: moment(searchDate[0].startDate).format(
                    "YYYY-MM-DD"
                  ),
                  toCreatedAt: moment(searchDate[0].endDate).format(
                    "YYYY-MM-DD"
                  ),
                };
                if (
                  updatedFilter.fromCreatedAt !==
                    cartHistorySearchBody.fromCreatedAt ||
                  updatedFilter.toCreatedAt !==
                    cartHistorySearchBody.toCreatedAt
                ) {
                  dispatch(
                    saveCartHistorySearchBody({
                      ...cartHistorySearchBody,
                      fromReviewAt: moment(searchDate[0].startDate).format(
                        "YYYY-MM-DD"
                      ),
                      toReviewAt: moment(searchDate[0].endDate).format(
                        "YYYY-MM-DD"
                      ),
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack direction="row" spacing={2}>
              <IconButton
                disabled={reload}
                onClick={() => {
                  dispatch(saveCartHistoryPageNo(1));
                  dispatch(
                    saveCartHistorySearchBody({
                      ...cartHistorySearchBody,
                      cartId: selectedCart,
                    })
                  );
                }}
              >
                <Tooltip title="Search with filter">
                  <SearchIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                disabled={reload}
                onClick={() => {
                  dispatch(
                    saveCartHistorySearchBody({
                      pageSize: 100,
                      fromReviewAt: null,
                      toReviewAt: null,
                      reviewerId: null,
                    })
                  );
                  dispatch(saveCartHistoryPageNo(1));
                }}
              >
                <Tooltip title="Clear filter">
                  <Close />
                </Tooltip>
              </IconButton>
            </Stack>
          </Grid>
          {reload ? (
            <Grid
              item
              xs={12}
              sx={{ height: 500 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CustomSpinner />
            </Grid>
          ) : (
            <Grid item xs={12}>
              {cartHistory?.data.length > 0 ? (
                cartHistory?.data.map((review, index) => (
                  <Card
                    key={index}
                    sx={{ marginBottom: 2, border: "2px solid grey" }}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          border: "2px solid grey",
                          backgroundColor: "lightgrey",
                          borderRadius: 50,
                          width: 25,
                          height: 25,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: 1,
                        }}
                      >
                        <Typography variant="h6">{index + 1}</Typography>
                      </Box>
                      {review.typeName === "Clear Cart by Admin" ||
                      review.typeName === "Complete Review" ? (
                        <Box>
                          <Typography variant="h6">
                            Reviewer: {review.reviewer.name}
                          </Typography>
                          <Typography variant="h6" color="primary">
                            Change Type: {review.typeName}
                          </Typography>
                          <Typography variant="body1">
                            Review Date:{" "}
                            {moment(review.reviewAt).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </Typography>
                          <Divider sx={{ marginY: 2 }} />
                          <Typography
                            textAlign="center"
                            variant="h4"
                            color="primary"
                          >
                            {review.typeName}
                          </Typography>
                        </Box>
                      ) : (
                        <Box>
                          <Typography variant="h6">
                            Reviewer: {review.reviewer.name}
                          </Typography>
                          <Typography variant="h6" color="primary">
                            Change Type: {review.typeName}
                          </Typography>
                          <Typography variant="body1">
                            Review Date:{" "}
                            {moment(review.reviewAt).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </Typography>
                          <Divider sx={{ marginY: 2 }} />
                          <Typography variant="h6" color="error">
                            Changes:
                          </Typography>
                          <Grid container spacing={0.5}>
                            {review.changes &&
                              Object.entries(review.changes).map(
                                ([key, value]) =>
                                  value?.old === value?.new ? null : (
                                    <Grid item xs={6} key={key}>
                                      <Stack
                                        direction="row"
                                        spacing={0.5}
                                        alignItems="center"
                                      >
                                        <Typography variant="body2">
                                          {camelCaseToCapitalized(key)}:
                                        </Typography>
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                          alignItems="center"
                                        >
                                          <Typography
                                            variant="body2"
                                            color={"error"}
                                          >
                                            {`${value.old}`}
                                          </Typography>
                                          <Typography variant="body2">
                                            <FaLongArrowAltRight size={20} />
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            color={"green"}
                                          >
                                            {`${value.new}`}
                                          </Typography>
                                        </Stack>
                                      </Stack>
                                    </Grid>
                                  )
                              )}
                          </Grid>
                          <Divider sx={{ marginY: 2 }} />
                          <Typography variant="h6">Items:</Typography>
                          {review.items.map((item, idx) => (
                            <Box key={idx} sx={{ marginBottom: 2 }}>
                              <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="space-between"
                              >
                                <Box sx={{ width: "50%" }}>
                                  <Typography variant="body1">
                                    <Tooltip
                                      title={
                                        <Typography>
                                          {item.item.name}
                                        </Typography>
                                      }
                                    >
                                      <a
                                        rel="noreferrer"
                                        href={
                                          item.item.productUrl.includes("shein")
                                            ? item.item.productUrl.replace(
                                                /(https:\/\/\s*)m/g,
                                                "$1ar"
                                              )
                                            : item.item.productUrl
                                        }
                                        target="_blank"
                                      >
                                        <Typography variant="body1" noWrap>
                                          {item.item.name}
                                        </Typography>
                                      </a>
                                    </Tooltip>
                                  </Typography>
                                  <Typography variant="body2">
                                    Price: {item.item.price}
                                  </Typography>
                                  <Typography variant="body2">
                                    Quantity: {item.item.quantity}
                                  </Typography>
                                  <Typography variant="body2">
                                    Total Amount: {item.item.totalAmount}
                                  </Typography>
                                </Box>
                                <img
                                  src={item.item.imageUrl}
                                  alt={item.item.name}
                                  style={{
                                    width: 100,
                                    height: 100,
                                    objectFit: "contain",
                                  }}
                                />
                              </Stack>
                              <Typography variant="h6" color="error">
                                Item Changes:
                              </Typography>
                              {item.changes &&
                                Object.entries(item.changes).map(
                                  ([key, value]) =>
                                    value?.old === value?.new ? null : (
                                      <Stack
                                        direction="row"
                                        spacing={0.5}
                                        alignItems="center"
                                        key={key}
                                      >
                                        <Typography variant="body2">
                                          {camelCaseToCapitalized(key)}:
                                        </Typography>
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                          alignItems="center"
                                        >
                                          <Typography
                                            variant="body2"
                                            color={"error"}
                                          >
                                            {`${
                                              key ===
                                                "prohibitedItemsReasons" &&
                                              value.old === null
                                                ? "Approved"
                                                : value.old
                                            }`}
                                          </Typography>
                                          <Typography variant="body2">
                                            <FaLongArrowAltRight size={20} />
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            color={"green"}
                                          >
                                            {`${
                                              key ===
                                                "prohibitedItemsReasons" &&
                                              value.new === null
                                                ? "Approved"
                                                : value.new
                                            }`}
                                          </Typography>
                                        </Stack>
                                      </Stack>
                                    )
                                )}
                            </Box>
                          ))}
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                ))
              ) : (
                <CustomEmpty />
              )}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack
          sx={{ m: 2 }}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Pagination
            count={Math.ceil(
              cartHistory.rowCount / cartHistorySearchBody.pageSize
            )}
            page={cartHistoryPageNo}
            onChange={handlePageChange}
          />
          <Select
            size="small"
            value={cartHistorySearchBody.pageSize}
            onChange={(event) => {
              dispatch(
                saveCartHistorySearchBody({
                  ...cartHistorySearchBody,
                  pageSize: event.target.value,
                })
              );
            }}
          >
            <MenuItem value={16}>16</MenuItem>
            <MenuItem value={28}>28</MenuItem>
            <MenuItem value={52}>52</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
          <Typography>Total: {cartHistory.rowCount}</Typography>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
export default CartHistoryDialog;
