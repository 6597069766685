import reportsService from "src/api/services/reports.service";
import helper from "src/utils/helper";
import { openAlertDialog, showErrorSnackbar } from "./snackbarMessages";
import {
  GET_WALLET_AND_BANK_ACCOUNT,
  RELOAD_REPORT,
  SAVE_WALLET_AND_BANK_ACCOUNT_SEARCH_BODY,
  SAVE_WALLET_AND_BANK_ACCOUNT_SAVE_NO,
  SAVE_CUSTOMER_INFO_REPORT_SEARCH_BODY,
  GET_CUSTOMER_INFO_REPORT,
  SAVE_CUSTOMER_INFO_REPORT_SAVE_NO,
  SAVE_ITEMS_PURCHASED_REPORT_SAVE_NO,
  SAVE_ITEMS_PURCHASED_REPORT_SEARCH_BODY,
  GET_ITEMS_PURCHASED_REPORT,
  SAVE_USER_BEHAVIOR_REPORT_SAVE_NO,
  SAVE_USER_BEHAVIOR_REPORT_SEARCH_BODY,
  GET_USER_BEHAVIOR_REPORT,
  SAVE_PRODUCT_ECONOMICS_REPORT_SAVE_NO,
  SAVE_PRODUCT_ECONOMICS_REPORT_SEARCH_BODY,
  GET_PRODUCT_ECONOMICS_REPORT,
  SAVE_PRODUCT_DURATIONS_REPORT_SAVE_NO,
  SAVE_PRODUCT_DURATIONS_REPORT_SEARCH_BODY,
  GET_PRODUCT_DURATIONS_REPORT,
  SAVE_CART_CREATION_REPORT_SAVE_NO,
  SAVE_CART_CREATION_REPORT_SEARCH_BODY,
  GET_CART_CREATION_REPORT,
  GET_TABULATED_INFORMATION_REPORT,
  SAVE_TABULATED_INFORMATION_REPORT_SEARCH_BODY,
  SAVE_TABULATED_INFORMATION_REPORT_SAVE_NO,
} from "src/actions/type";

export const newCustomerPerDay = (data) => async (dispatch) => {
  try {
    const res = await reportsService.newCustomerPerDay(data);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const totalRevenuPromoVendor = (id) => async (dispatch) => {
  try {
    const res = await reportsService.totalRevenuPromoVendor(id);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getUnsedPromo = (id) => async (dispatch) => {
  try {
    const res = await reportsService.getUnsedPromo(id);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const totalDiscountPerDay = (data) => async (dispatch) => {
  try {
    const res = await reportsService.totalDiscountPerDay(data);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const allInfo = (data) => async (dispatch) => {
  try {
    const res = await reportsService.allInfo(data);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const regionPromoVendorRevenue = (data) => async (dispatch) => {
  try {
    const res = await reportsService.regionPromoVendorRevenue(data);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const cac = (id) => async (dispatch) => {
  try {
    const res = await reportsService.cac(id);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getTopCustomersByOrderCount = (filter) => async (dispatch) => {
  try {
    const res = await reportsService.getTopCustomersByOrderCount(filter);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getTopCustomersByOrderValue = (filter) => async (dispatch) => {
  try {
    const res = await reportsService.getTopCustomersByOrderValue(filter);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getTopTenVendersByClick = (filter) => async (dispatch) => {
  try {
    const res = await reportsService.getTopTenVendorsByClickCount(filter);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getTopTenVendersByOrder = (filter) => async (dispatch) => {
  try {
    const res = await reportsService.getTopTenVendorsByOrderCount(filter);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const transactions = (filter) => async (dispatch) => {
  try {
    const res = await reportsService.transactions(filter);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const userBalances = (filter) => async (dispatch) => {
  try {
    const res = await reportsService.userBalace(filter);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const topup = (filter) => async (dispatch) => {
  try {
    const res = await reportsService.topup(filter);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const walletliability = (filter) => async (dispatch) => {
  try {
    const res = await reportsService.walletLiability(filter);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const income = (filter) => async (dispatch) => {
  try {
    const res = await reportsService.income(filter);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const walletsnapshot = (filter) => async (dispatch) => {
  try {
    const res = await reportsService.walletSnapshot(filter);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const agentTopup = (filter) => async (dispatch) => {
  try {
    const res = await reportsService.agentTopUp(filter);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const reloadReports = () => (dispatch) => {
  dispatch({
    type: RELOAD_REPORT,
  });
};

// ===================== Wallets and Banks Account Report =====================

export const saveWalletsAndBanksAccountPageNo = (data) => (dispatch) => {
  dispatch({
    type: SAVE_WALLET_AND_BANK_ACCOUNT_SAVE_NO,
    payload: data,
  });
};

export const saveWalletsAndBanksAccountSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_WALLET_AND_BANK_ACCOUNT_SEARCH_BODY,
    payload: data,
  });
};

export const walletsAndBanksAccount =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadReports());
      const res = await reportsService.walletsAndBanksAccount(
        pageNo,
        searchBody
      );
      dispatch({
        type: GET_WALLET_AND_BANK_ACCOUNT,
        payload: res.data.data,
      });
      dispatch(reloadReports());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadReports());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const walletsAndBanksAccountExport =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadReports());
      const res = await reportsService.walletsAndBanksAccountExport(
        pageNo,
        searchBody
      );
      const blob = new Blob([res.data], { type: "text/csv" });
      const fileName = "wallets_and_banks_account_report.csv";
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(reloadReports());
      return Promise.resolve();
    } catch (err) {
      dispatch(reloadReports());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

// ===================== Customer Info Report =====================

export const saveCustomerInfoReportPageNo = (data) => (dispatch) => {
  dispatch({
    type: SAVE_CUSTOMER_INFO_REPORT_SAVE_NO,
    payload: data,
  });
};

export const saveCustomerInfoReportSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_CUSTOMER_INFO_REPORT_SEARCH_BODY,
    payload: data,
  });
};

export const getCustomerInfoReport =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadReports());
      const res = await reportsService.customerInfoReport(pageNo, searchBody);
      dispatch({
        type: GET_CUSTOMER_INFO_REPORT,
        payload: res.data.data,
      });
      dispatch(reloadReports());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadReports());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const customerInfoReportExport =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadReports());
      const res = await reportsService.customerInfoReportExport(
        pageNo,
        searchBody
      );
      const blob = new Blob([res.data], { type: "text/csv" });
      const fileName = "customer_information.csv";
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(reloadReports());
      return Promise.resolve();
    } catch (err) {
      dispatch(reloadReports());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

// ===================== Items Purchased Report =====================

export const saveItemsPurchasedReportPageNo = (data) => (dispatch) => {
  dispatch({
    type: SAVE_ITEMS_PURCHASED_REPORT_SAVE_NO,
    payload: data,
  });
};

export const saveItemsPurchasedReportSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_ITEMS_PURCHASED_REPORT_SEARCH_BODY,
    payload: data,
  });
};

export const getItemsPurchasedReport =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadReports());
      const res = await reportsService.itemsPurchased(pageNo, searchBody);
      dispatch({
        type: GET_ITEMS_PURCHASED_REPORT,
        payload: res.data.data,
      });
      dispatch(reloadReports());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadReports());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const ItemsPurchasedReportExport =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadReports());
      const res = await reportsService.itemsPurchasedExport(pageNo, searchBody);
      const blob = new Blob([res.data], { type: "text/csv" });
      const fileName = "purchased_items.csv";
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(reloadReports());
      return Promise.resolve();
    } catch (err) {
      dispatch(reloadReports());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

// ===================== User Behavior Report =====================

export const saveUserBehaviorReportPageNo = (data) => (dispatch) => {
  dispatch({
    type: SAVE_USER_BEHAVIOR_REPORT_SAVE_NO,
    payload: data,
  });
};

export const saveUserBehaviorReportSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_USER_BEHAVIOR_REPORT_SEARCH_BODY,
    payload: data,
  });
};

export const getUserBehaviorReport =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadReports());
      const res = await reportsService.userBehavior(pageNo, searchBody);
      dispatch({
        type: GET_USER_BEHAVIOR_REPORT,
        payload: res.data.data,
      });
      dispatch(reloadReports());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadReports());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const userBehaviorReportExport =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadReports());
      const res = await reportsService.userBehaviorExport(pageNo, searchBody);
      const blob = new Blob([res.data], { type: "text/csv" });
      const fileName = "user_behavior.csv";
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(reloadReports());
      return Promise.resolve();
    } catch (err) {
      dispatch(reloadReports());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

// ===================== Product Economics Report =====================

export const saveProductEconomicsReportPageNo = (data) => (dispatch) => {
  dispatch({
    type: SAVE_PRODUCT_ECONOMICS_REPORT_SAVE_NO,
    payload: data,
  });
};

export const saveProductEconomicsReportSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_PRODUCT_ECONOMICS_REPORT_SEARCH_BODY,
    payload: data,
  });
};

export const getProductEconomicsReport =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadReports());
      const res = await reportsService.productEconomics(pageNo, searchBody);
      dispatch({
        type: GET_PRODUCT_ECONOMICS_REPORT,
        payload: res.data.data,
      });
      dispatch(reloadReports());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadReports());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const ProductEconomicsReportExport =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadReports());
      const res = await reportsService.productEconomicsExport(
        pageNo,
        searchBody
      );
      const blob = new Blob([res.data], { type: "text/csv" });
      const fileName = "product_economics.csv";
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(reloadReports());
      return Promise.resolve();
    } catch (err) {
      dispatch(reloadReports());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

// ===================== Product Durations Report =====================

export const saveProductDurationsReportPageNo = (data) => (dispatch) => {
  dispatch({
    type: SAVE_PRODUCT_DURATIONS_REPORT_SAVE_NO,
    payload: data,
  });
};

export const saveProductDurationsReportSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_PRODUCT_DURATIONS_REPORT_SEARCH_BODY,
    payload: data,
  });
};

export const getProductDurationsReport =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadReports());
      const res = await reportsService.productDurations(pageNo, searchBody);
      dispatch({
        type: GET_PRODUCT_DURATIONS_REPORT,
        payload: res.data.data,
      });
      dispatch(reloadReports());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadReports());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const ProductDurationsReportExport =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadReports());
      const res = await reportsService.productDurationsExport(
        pageNo,
        searchBody
      );
      const blob = new Blob([res.data], { type: "text/csv" });
      const fileName = "product_durations.csv";
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(reloadReports());
      return Promise.resolve();
    } catch (err) {
      dispatch(reloadReports());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

// ===================== Cart Creation Report =====================

export const saveCartCreationReportPageNo = (data) => (dispatch) => {
  dispatch({
    type: SAVE_CART_CREATION_REPORT_SAVE_NO,
    payload: data,
  });
};

export const saveCartCreationReportSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_CART_CREATION_REPORT_SEARCH_BODY,
    payload: data,
  });
};

export const getCartCreationReport =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadReports());
      const res = await reportsService.cartCreation(pageNo, searchBody);
      dispatch({
        type: GET_CART_CREATION_REPORT,
        payload: res.data.data,
      });
      dispatch(reloadReports());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadReports());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const CartCreationReportExport =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadReports());
      const res = await reportsService.cartCreationExport(pageNo, searchBody);
      const blob = new Blob([res.data], { type: "text/csv" });
      const fileName = "cart_creation.csv";
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(reloadReports());
      return Promise.resolve();
    } catch (err) {
      dispatch(reloadReports());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

// ===================== Tabulated Information Report =====================

export const saveTabulatedInformationReportPageNo = (data) => (dispatch) => {
  dispatch({
    type: SAVE_TABULATED_INFORMATION_REPORT_SAVE_NO,
    payload: data,
  });
};

export const saveTabulatedInformationReportSearchBody =
  (data) => (dispatch) => {
    dispatch({
      type: SAVE_TABULATED_INFORMATION_REPORT_SEARCH_BODY,
      payload: data,
    });
  };

export const getTabulatedInformationReport =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadReports());
      const res = await reportsService.tabulatedInformation(pageNo, searchBody);
      dispatch({
        type: GET_TABULATED_INFORMATION_REPORT,
        payload: res.data.data,
      });
      dispatch(reloadReports());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadReports());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const tabulatedInformationReportExport =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadReports());
      const res = await reportsService.tabulatedInformationExport(
        pageNo,
        searchBody
      );
      const blob = new Blob([res.data], { type: "text/csv" });
      const fileName = "tabulated_information.csv";
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(reloadReports());
      return Promise.resolve();
    } catch (err) {
      dispatch(reloadReports());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };
