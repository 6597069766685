import { Card, CardHeader, Divider, Typography, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { walletliability } from "src/actions/reports";
import CustomSpinner from "src/components/CustomSpinner";
import PerfectScrollbar from "react-perfect-scrollbar";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { red } from "@mui/material/colors";

export default function WalletLiability(props) {
  const dispatch = new useDispatch();
  const [loading, setLoading] = useState(false);
  const [walletLiability, setWalletLiability] = useState([]);

  useEffect(() => {
    walletLiabilityReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.setSearch]);

  function walletLiabilityReport() {
    setLoading(true);
    dispatch(walletliability()).then((res) => {
      setWalletLiability(res.data);
      setLoading(false);
    });
  }

  const colums = [
    {
      field: "sequence",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "liabilities",
      headerName: "Liabilities ($)",
      flex: 1,
    },
    {
      field: "segment",
      headerName: "Segment",
      flex: 1,
    },
    {
      field: "userCount",
      headerName: "User Count",
      flex: 1,
    },
  ];

  function CustomToolbar() {
    return (
      <div>
        <Box>
          <Box>
            <Card>
              <CardHeader title="Wallet Liability Report" />
              <Divider />
            </Card>
          </Box>
          <Box>
            <GridToolbarContainer>
              <GridToolbarExport
                csvOptions={{
                  fileName: "Wallet Liability Report || Orderii",
                  utf8WithBom: true,
                }}
              />
            </GridToolbarContainer>
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <Card style={{ marginTop: 10 }} ref={props.print}>
      <PerfectScrollbar>
        <Box>
          {loading ? (
            <CustomSpinner />
          ) : (
            <Box>
              {walletLiability.length === 0 ? (
                <Box sx={{ textAlign: "center", marginY: 2 }}>
                  <Typography sx={{ color: red[600] }}>
                    <DangerousOutlinedIcon fontSize="large" />
                  </Typography>
                  <Typography>No Data</Typography>
                </Box>
              ) : (
                <Box sx={{ height: "calc(100vh - 320px)" }}>
                  <DataGrid
                    sx={{
                      "& .MuiDataGrid-row ": {
                        textAlign: "center",
                      },
                      "& .css-204u17-MuiDataGrid-main": {
                        paddingLeft: "15px",
                      },
                      "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                        paddingLeft: "15px",
                      },
                    }}
                    columns={colums}
                    rows={walletLiability.map((row, index) => {
                      return { ...row, sequence: index + 1 };
                    })}
                    disableSelectionOnClick
                    getRowId={(params) => params.sequence}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
