import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  TextField,
  Tooltip,
  InputAdornment,
  SvgIcon,
  Autocomplete,
  Grid,
  Button,
  Divider,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Drawer,
  Badge,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Search as SearchIcon } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { item_statuses, shipping_types } from "src/utils/common";
import Close from "@mui/icons-material/Close";
import { getAllVendors } from "src/actions/vendors";
import { saveSearchBody } from "src/actions/orderItems";
import HideFromFedExAccount from "src/components/hideFromFedEx";
import { FaArrowDown, FaArrowUp, FaFilter } from "react-icons/fa";
import { changeFiltersType } from "src/actions/users";
import RolePermission from "src/components/RolePermission";

export default function OrderItemsSearchFilter({ pageNo = function () {} }) {
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.users.userInfo);
  const reload = useSelector((state) => state.orderItems.reload);
  const _searchBody = useSelector((state) => state.orderItems.searchBody);
  const vendors = useSelector((state) => state.vendors.dataList);

  const filterType = useSelector((state) => state.users.filterType);

  const [isSearch, setIsSearch] = useState(false);
  const [searchBody, setSearchBody] = useState(_searchBody);

  const activeFilters = useMemo(() => {
    const { pageSize, ...restOfSearchBody } = searchBody;
    const truthyValuesCount = Object.values(restOfSearchBody).filter((item) =>
      item !== "" && item?.length > 0 && item !== undefined && item !== null
        ? item
        : null
    ).length;
    return userInfo.id === "7d059cd8-f0fb-45db-ae1b-832e80a047da" ||
      userInfo.id === "f441e0d3-abf1-4a67-a57e-4b5f5d4f1106"
      ? truthyValuesCount > 0
        ? truthyValuesCount - 1
        : truthyValuesCount
      : truthyValuesCount;
  }, [searchBody, userInfo.id]);

  useEffect(() => {
    if (userInfo.roleOEndpoint.map((data) => data.Code).includes("33-11")) {
      dispatch(getAllVendors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(saveSearchBody({ ..._searchBody, ...searchBody }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearch]);

  const region = [
    { name: "Turkey", key: "TR" },
    { name: "United State", key: "US" },
    { name: "Europe", key: "EU" },
    { name: "Arabic Gulf", key: "AG" },
    { name: "WSM", key: "WSM" },
  ];

  const isVendorExcluded = [
    { name: "True", type: true },
    { name: "False", type: false },
  ];

  const [openSearch, setOpenSearch] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpenSearch(newOpen);
  };

  return (
    <Box sx={{ padding: 2, width: "100%" }}>
      <Drawer open={openSearch} onClose={toggleDrawer(false)}>
        <Divider />
        <Grid container spacing={2} padding={2} sx={{ width: 400 }}>
          <HideFromFedExAccount>
            <Grid item xs={12}>
              <TextField
                disabled={reload}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                placeholder="International tracking number"
                variant="outlined"
                value={searchBody.internationalTrackingNumber ?? ""}
                sx={{ marginRight: 1 }}
                onChange={(e) => {
                  setSearchBody({
                    ...searchBody,
                    internationalTrackingNumber: e.target.value,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(saveSearchBody(searchBody));
                    pageNo(0);
                  }
                }}
              />
            </Grid>
          </HideFromFedExAccount>
          <Grid item xs={12}>
            <TextField
              disabled={reload}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              type="number"
              placeholder="Orderii number"
              variant="outlined"
              value={searchBody.orderiiNumbers ?? ""}
              sx={{ marginRight: 1 }}
              fullWidth
              onChange={(e) => {
                setSearchBody({
                  ...searchBody,
                  orderiiNumbers: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(saveSearchBody(searchBody));
                  pageNo(0);
                }
              }}
            />
          </Grid>
          <HideFromFedExAccount>
            <Grid item xs={12}>
              <TextField
                disabled={reload}
                size="small"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Item Name"
                sx={{ marginRight: 1 }}
                variant="outlined"
                value={searchBody.itemName ?? ""}
                onChange={(e) => {
                  setSearchBody({
                    ...searchBody,
                    itemName: e.target.value,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(saveSearchBody(searchBody));
                    pageNo(0);
                  }
                }}
              />
            </Grid>
          </HideFromFedExAccount>
          <RolePermission permission="23-01">
            <HideFromFedExAccount>
              <Grid item xs={12}>
                <TextField
                  disabled={reload}
                  size="small"
                  type="number"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Phone Number"
                  sx={{ marginRight: 1 }}
                  variant="outlined"
                  value={searchBody.phoneNumber ?? ""}
                  onChange={(e) => {
                    var phoneNumber = e.target.value;
                    setSearchBody({
                      ...searchBody,
                      phoneNumber:
                        phoneNumber[0] === "0"
                          ? phoneNumber.substring(1)
                          : phoneNumber,
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      dispatch(saveSearchBody(searchBody));
                      pageNo(0);
                    }
                  }}
                />
              </Grid>
            </HideFromFedExAccount>
          </RolePermission>
          <RolePermission permission="23-01">
            <Grid item xs={12}>
              <Autocomplete
                multiple
                disabled={reload}
                options={item_statuses || []}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Status" />
                )}
                onChange={(event, value) => {
                  var ids = value.map((x) => x.id);
                  setSearchBody({
                    ...searchBody,
                    itemStatus: value ? ids : searchBody.itemStatus,
                  });
                  pageNo(0);
                }}
                value={item_statuses?.filter((x) =>
                  searchBody.itemStatus
                    ? searchBody.itemStatus.includes(x.id)
                    : null
                )}
              />
            </Grid>
          </RolePermission>
          <RolePermission permission="23-01">
            <Grid item xs={12}>
              <Autocomplete
                disabled={reload}
                options={shipping_types}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Shipping Type" />
                )}
                onChange={(event, value) => {
                  setSearchBody({
                    ...searchBody,
                    shippingOptionName: value ? value.name : null,
                  });
                  pageNo(0);
                }}
                value={
                  shipping_types.filter(
                    (x) => x.name === searchBody.shippingOptionName
                  )[0] ?? null
                }
              />
            </Grid>
          </RolePermission>
          <RolePermission permission="23-01">
            <Grid item xs={12}>
              <Autocomplete
                disabled={
                  reload ||
                  userInfo.id === "7d059cd8-f0fb-45db-ae1b-832e80a047da" ||
                  userInfo.id === "f441e0d3-abf1-4a67-a57e-4b5f5d4f1106"
                }
                options={region}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Region" />
                )}
                onChange={(event, value) => {
                  setSearchBody({
                    ...searchBody,
                    region: value ? value.key : null,
                  });
                  pageNo(0);
                }}
                value={
                  region.filter((x) => x.key === searchBody.region)[0] ?? null
                }
              />
            </Grid>
          </RolePermission>
          <RolePermission permission="23-01">
            <HideFromFedExAccount>
              <Grid item xs={12}>
                <Autocomplete
                  disabled={reload}
                  multiple
                  id="vendors"
                  options={vendors || []}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Vendors" />
                  )}
                  onChange={(event, value) => {
                    var ids = value.map((x) => x.id);
                    setSearchBody({
                      ...searchBody,
                      vendorIds: value ? ids : searchBody.vendorIds,
                    });
                    pageNo(0);
                  }}
                  value={vendors?.filter((x) =>
                    searchBody?.vendorIds
                      ? searchBody?.vendorIds.includes(x.id)
                      : null
                  )}
                />
              </Grid>
            </HideFromFedExAccount>
          </RolePermission>
          <RolePermission permission="23-01">
            <HideFromFedExAccount>
              <Grid item xs={12}>
                <Autocomplete
                  disabled={reload}
                  options={isVendorExcluded}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Exclude Vendors" />
                  )}
                  onChange={(event, value) => {
                    setSearchBody({
                      ...searchBody,
                      isVendorExcluded: value ? value.type : null,
                    });
                    pageNo(0);
                  }}
                  value={
                    isVendorExcluded.filter(
                      (x) => x.type === searchBody?.isVendorExcluded
                    )[0] ?? null
                  }
                />
              </Grid>
            </HideFromFedExAccount>
          </RolePermission>
          {/* <HideFromFedExAccount>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={reload}
                    checked={searchBody.isWrongTrackingNumber ?? false}
                    onChange={(e) => {
                      setSearchBody({
                        ...searchBody,
                        isWrongTrackingNumber: e.target.checked,
                      });
                    }}
                  />
                }
                label="Invalid Track !"
              />
            </Grid>
          </HideFromFedExAccount>
          <HideFromFedExAccount>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={reload}
                    checked={searchBody.hasPurchaseError ?? false}
                    onChange={(e) => {
                      setSearchBody({
                        ...searchBody,
                        hasPurchaseError: e.target.checked,
                      });
                    }}
                  />
                }
                label="Purchase Error !"
              />
            </Grid>
          </HideFromFedExAccount>
          <HideFromFedExAccount>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={reload}
                    checked={searchBody.isDamaged ?? false}
                    onChange={(e) => {
                      setSearchBody({
                        ...searchBody,
                        isDamaged: e.target.checked,
                      });
                    }}
                  />
                }
                label="Damaged"
              />
            </Grid>
          </HideFromFedExAccount>
          <HideFromFedExAccount>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={reload}
                    checked={searchBody.isDangerousGoods ?? false}
                    onChange={(e) => {
                      setSearchBody({
                        ...searchBody,
                        isDangerousGoods: e.target.checked,
                      });
                    }}
                  />
                }
                label="Dangerous Goods"
              />
            </Grid>
          </HideFromFedExAccount> */}
          <HideFromFedExAccount>
            <Grid item xs={12}>
              <ToggleButtonGroup
                fullWidth
                disabled={reload}
                size="small"
                color="primary"
                value={
                  searchBody.sortByCreatedAtAsc
                    ? "sortByCreatedAtDsc"
                    : "sortByCreatedAtAsc"
                }
                exclusive
                aria-label="sortByCreatedAtAsc"
              >
                <ToggleButton
                  value="sortByCreatedAtAsc"
                  onClick={() => {
                    setSearchBody({
                      ...searchBody,
                      sortByCreatedAtAsc: false,
                    });
                    pageNo(0);
                  }}
                >
                  Descend
                </ToggleButton>
                <ToggleButton
                  value="sortByCreatedAtDsc"
                  onClick={() => {
                    setSearchBody({
                      ...searchBody,
                      sortByCreatedAtAsc: true,
                    });
                    pageNo(0);
                  }}
                >
                  Ascend
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </HideFromFedExAccount>
          <Grid item xs={6}>
            <Button
              fullWidth
              disabled={reload}
              variant="contained"
              onClick={(e) => {
                setIsSearch(!isSearch);
                pageNo(0);
              }}
            >
              Apply
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              disabled={reload}
              variant="contained"
              color="warning"
              onClick={() => {
                dispatch(
                  saveSearchBody({
                    pageSize: 10,
                    sortByCreatedAtAsc: false,
                  })
                );
                setSearchBody({
                  pageSize: 10,
                  sortByCreatedAtAsc: false,
                });
                // setOrderNumberData([]);
                // setOrderNumber("");
                setIsSearch(!isSearch);
              }}
            >
              Clear Filter
            </Button>
          </Grid>
        </Grid>
        <Divider />
      </Drawer>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <TextField
            disabled={reload}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            placeholder="Invoice number"
            variant="outlined"
            value={searchBody.invoiceNumber ?? ""}
            sx={{ marginRight: 1 }}
            fullWidth
            onChange={(e) => {
              setSearchBody({
                ...searchBody,
                invoiceNumber: e.target.value,
              });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatch(saveSearchBody(searchBody));
                pageNo(0);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextField
            disabled={reload}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            fullWidth
            placeholder="Tracking number"
            variant="outlined"
            value={searchBody.trackingNumber ?? ""}
            sx={{ marginRight: 1 }}
            onChange={(e) => {
              setSearchBody({
                ...searchBody,
                trackingNumber: e.target.value,
              });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatch(saveSearchBody(searchBody));
                pageNo(0);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextField
            disabled={reload}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            placeholder="Shipping number"
            variant="outlined"
            value={searchBody.shippingNumber ?? ""}
            sx={{ marginRight: 1 }}
            fullWidth
            onChange={(e) => {
              setSearchBody({
                ...searchBody,
                shippingNumber: e.target.value,
              });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatch(saveSearchBody(searchBody));
                pageNo(0);
              }
            }}
          />
        </Grid>
        {filterType === "1" ? null : (
          <>
            <HideFromFedExAccount>
              <Grid item xs={12} md={3}>
                <TextField
                  disabled={reload}
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  placeholder="International tracking number"
                  variant="outlined"
                  value={searchBody.internationalTrackingNumber ?? ""}
                  sx={{ marginRight: 1 }}
                  onChange={(e) => {
                    setSearchBody({
                      ...searchBody,
                      internationalTrackingNumber: e.target.value,
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      dispatch(saveSearchBody(searchBody));
                      pageNo(0);
                    }
                  }}
                />
              </Grid>
            </HideFromFedExAccount>
            <Grid item xs={12} md={3}>
              <TextField
                disabled={reload}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                type="number"
                placeholder="Orderii number"
                variant="outlined"
                value={searchBody.orderiiNumbers ?? ""}
                sx={{ marginRight: 1 }}
                fullWidth
                onChange={(e) => {
                  setSearchBody({
                    ...searchBody,
                    orderiiNumbers: e.target.value,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(saveSearchBody(searchBody));
                    pageNo(0);
                  }
                }}
              />
            </Grid>
            <HideFromFedExAccount>
              <Grid item xs={12} md={3}>
                <TextField
                  disabled={reload}
                  size="small"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Item Name"
                  sx={{ marginRight: 1 }}
                  variant="outlined"
                  value={searchBody.itemName ?? ""}
                  onChange={(e) => {
                    setSearchBody({
                      ...searchBody,
                      itemName: e.target.value,
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      dispatch(saveSearchBody(searchBody));
                      pageNo(0);
                    }
                  }}
                />
              </Grid>
            </HideFromFedExAccount>
            <RolePermission permission="23-01">
              <HideFromFedExAccount>
                <Grid item xs={12} md={3}>
                  <TextField
                    disabled={reload}
                    size="small"
                    type="number"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Phone Number"
                    sx={{ marginRight: 1 }}
                    variant="outlined"
                    value={searchBody.phoneNumber ?? ""}
                    onChange={(e) => {
                      var phoneNumber = e.target.value;
                      setSearchBody({
                        ...searchBody,
                        phoneNumber:
                          phoneNumber[0] === "0"
                            ? phoneNumber.substring(1)
                            : phoneNumber,
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        dispatch(saveSearchBody(searchBody));
                        pageNo(0);
                      }
                    }}
                  />
                </Grid>
              </HideFromFedExAccount>
            </RolePermission>
            <RolePermission permission="23-01">
              <Grid item xs={12} md={3}>
                <Autocomplete
                  multiple
                  disabled={reload}
                  options={item_statuses || []}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Status" />
                  )}
                  onChange={(event, value) => {
                    var ids = value.map((x) => x.id);
                    setSearchBody({
                      ...searchBody,
                      itemStatus: value ? ids : searchBody.itemStatus,
                    });
                    pageNo(0);
                  }}
                  value={item_statuses?.filter((x) =>
                    searchBody.itemStatus
                      ? searchBody.itemStatus.includes(x.id)
                      : null
                  )}
                />
              </Grid>
            </RolePermission>
            <RolePermission permission="23-01">
              <Grid item xs={12} md={3}>
                <Autocomplete
                  disabled={
                    reload ||
                    userInfo.id === "7d059cd8-f0fb-45db-ae1b-832e80a047da" ||
                    userInfo.id === "f441e0d3-abf1-4a67-a57e-4b5f5d4f1106"
                  }
                  options={region}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Region" />
                  )}
                  onChange={(event, value) => {
                    setSearchBody({
                      ...searchBody,
                      region: value ? value.key : null,
                    });
                    pageNo(0);
                  }}
                  value={
                    region.filter((x) => x.key === searchBody.region)[0] ?? null
                  }
                />
              </Grid>
            </RolePermission>
            <RolePermission permission="23-01">
              <HideFromFedExAccount>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    disabled={reload}
                    multiple
                    id="vendors"
                    options={vendors || []}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Vendors" />
                    )}
                    onChange={(event, value) => {
                      var ids = value.map((x) => x.id);
                      setSearchBody({
                        ...searchBody,
                        vendorIds: value ? ids : searchBody.vendorIds,
                      });
                      pageNo(0);
                    }}
                    value={vendors?.filter((x) =>
                      searchBody.vendorIds
                        ? searchBody.vendorIds.includes(x.id)
                        : null
                    )}
                  />
                </Grid>
              </HideFromFedExAccount>
            </RolePermission>
            <RolePermission permission="23-01">
              <HideFromFedExAccount>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    disabled={reload}
                    options={isVendorExcluded}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Exclude Vendors" />
                    )}
                    onChange={(event, value) => {
                      setSearchBody({
                        ...searchBody,
                        isVendorExcluded: value ? value.type : null,
                      });
                      pageNo(0);
                    }}
                    value={
                      isVendorExcluded.filter(
                        (x) => x.type === searchBody.isVendorExcluded
                      )[0] ?? null
                    }
                  />
                </Grid>
              </HideFromFedExAccount>
            </RolePermission>
            <RolePermission permission="23-01">
              <HideFromFedExAccount>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    disabled={reload}
                    options={shipping_types}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Shipping Type" />
                    )}
                    onChange={(event, value) => {
                      setSearchBody({
                        ...searchBody,
                        shippingOptionName: value ? value.name : null,
                      });
                      pageNo(0);
                    }}
                    value={
                      shipping_types.filter(
                        (x) => x.name === searchBody.shippingOptionName
                      )[0] ?? null
                    }
                  />
                </Grid>
                {/* <Grid item xs={12} md={3}>
                <Autocomplete
                  disabled={reload}
                  options={shippingType}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Shipping type" />
                  )}
                  onChange={(event, value) => {
                    setSearchBody({
                      ...searchBody,
                      isVip: value ? value.type : null,
                    });

                    pageNo(0);
                  }}
                  value={
                    shippingType.filter(
                      (x) => x.type === searchBody.isVip
                    )[0] ?? null
                  }
                />
              </Grid> */}
              </HideFromFedExAccount>
            </RolePermission>
            {/* <HideFromFedExAccount>
              <Grid item xs={12} md={3}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={reload}
                      checked={searchBody.isWrongTrackingNumber ?? false}
                      onChange={(e) => {
                        setSearchBody({
                          ...searchBody,
                          isWrongTrackingNumber: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Invalid Track !"
                />
              </Grid>
            </HideFromFedExAccount>
            <HideFromFedExAccount>
              <Grid item xs={12} md={3}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={reload}
                      checked={searchBody.hasPurchaseError ?? false}
                      onChange={(e) => {
                        setSearchBody({
                          ...searchBody,
                          hasPurchaseError: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Purchase Error !"
                />
              </Grid>
            </HideFromFedExAccount>
            <HideFromFedExAccount>
              <Grid item xs={12} md={3}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={reload}
                      checked={searchBody.isDamaged ?? false}
                      onChange={(e) => {
                        setSearchBody({
                          ...searchBody,
                          isDamaged: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Damaged"
                />
              </Grid>
            </HideFromFedExAccount>
            <HideFromFedExAccount>
              <Grid item xs={12} md={3}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={reload}
                      checked={searchBody.isDangerousGoods ?? false}
                      onChange={(e) => {
                        setSearchBody({
                          ...searchBody,
                          isDangerousGoods: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Dangerous Goods"
                />
              </Grid>
            </HideFromFedExAccount> */}
            <HideFromFedExAccount>
              <Grid item xs={12} md={3}>
                <ToggleButtonGroup
                  fullWidth
                  disabled={reload}
                  size="small"
                  color="primary"
                  value={
                    searchBody.sortByCreatedAtAsc
                      ? "sortByCreatedAtDsc"
                      : "sortByCreatedAtAsc"
                  }
                  exclusive
                  aria-label="sortByCreatedAtAsc"
                >
                  <ToggleButton
                    value="sortByCreatedAtAsc"
                    onClick={() => {
                      setSearchBody({
                        ...searchBody,
                        sortByCreatedAtAsc: false,
                      });
                      pageNo(0);
                    }}
                  >
                    Descend
                  </ToggleButton>
                  <ToggleButton
                    value="sortByCreatedAtDsc"
                    onClick={() => {
                      setSearchBody({
                        ...searchBody,
                        sortByCreatedAtAsc: true,
                      });
                      pageNo(0);
                    }}
                  >
                    Ascend
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </HideFromFedExAccount>
          </>
        )}
        <Grid
          item
          xs={12}
          md={
            filterType === "1"
              ? 3
              : userInfo?.roleOEndpoint?.filter(
                  (api) => api.Code === "23-20"
                )[0]
              ? 3
              : 9
          }
        >
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Stack direction="row" spacing={2}>
              {filterType === "1" ? (
                <Tooltip title="Advanced Search">
                  <IconButton
                    onClick={() => {
                      setOpenSearch(true);
                    }}
                  >
                    <Badge badgeContent={activeFilters} color="error">
                      <FaFilter />
                    </Badge>
                  </IconButton>
                </Tooltip>
              ) : null}
              <IconButton
                disabled={reload}
                onClick={() => {
                  dispatch(saveSearchBody({ ...searchBody }));
                  pageNo(0);
                }}
              >
                <Tooltip title="Search with filter">
                  <SearchIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                disabled={reload}
                onClick={() => {
                  dispatch(
                    saveSearchBody({
                      pageSize: 10,
                      sortByCreatedAtAsc: false,
                    })
                  );
                  setSearchBody({
                    pageSize: 10,
                    sortByCreatedAtAsc: false,
                  });
                  pageNo(0);
                }}
              >
                <Tooltip title="Clear filter">
                  <Close />
                </Tooltip>
              </IconButton>
            </Stack>
            <IconButton
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(changeFiltersType(filterType === "1" ? "0" : "1"));
              }}
            >
              {filterType === "1" ? (
                <FaArrowDown size={30} />
              ) : (
                <FaArrowUp size={30} />
              )}
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
