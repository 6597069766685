import React, { useState, useEffect, useMemo } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  LinearProgress,
  Icon,
  Grid,
  TextField,
  InputAdornment,
  Autocomplete,
  CardContent,
  Button,
  Divider,
  Drawer,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Badge,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  deleteShipments,
  getAllShipments,
  getById,
  openDialog,
  savePageNo,
  saveSearchBody,
} from "src/actions/shipments";
import CustomSpinner from "src/components/CustomSpinner";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import { MdDeleteForever } from "react-icons/md";
import moment from "moment";
import { CustomDialog } from "src/components/CustomDialog";
import { AiOutlineSearch } from "react-icons/ai";
import DateRangepicker from "src/components/DateRangePicker";
import CloseIcon from "@mui/icons-material/Close";
import { FaArrowDown, FaArrowUp, FaFilter } from "react-icons/fa";
import { getAllVendors } from "src/actions/vendors";
import ShipmentStatusMenu from "src/pages/cross/shipments/components/menu";
import { shipments_status } from "src/utils/common";
import HideFromFedExAccount from "src/components/hideFromFedEx";
import { changeFiltersType } from "src/actions/users";
import { Search as SearchIcon } from "react-feather";
import useCanShow from "src/components/CanShow";
import { VscNote } from "react-icons/vsc";
import RolePermission from "src/components/RolePermission";

export default function ShipmentsTable() {
  const dispatch = useDispatch();

  const shipmentDetailsRole = useCanShow("59-01");
  const deleteRole = useCanShow("59-04");

  const _UserInfo = useSelector((state) => state.users.userInfo);
  const _ShipmentsItem = useSelector((state) => state.shipments.shipmentsData);
  const _ShipmentItem = useSelector((state) => state.shipments.form);
  const _SearchBody = useSelector((state) => state.shipments.searchBody);
  const PageNo = useSelector((state) => state.shipments.pageNo);
  const reload = useSelector((state) => state.shipments.reload);
  const vendors = useSelector((state) => state.vendors.dataList);
  const filterType = useSelector((state) => state.users.filterType);

  const [searchBody, setSearchBody] = useState(_SearchBody);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [page, setPage] = useState(PageNo ? PageNo : 0);

  const [isSearch, setIsSearch] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpenSearch(newOpen);
  };

  const region = [
    { name: "Turkey", key: "TR" },
    { name: "United State", key: "US" },
    // { name: "Europe", key: "EU" },
    // { name: "Arabic Gulf", key: "AG" },
    // { name: "WSM", key: "WSM" },
  ];

  const isVendorExcluded = [
    { name: "True", type: true },
    { name: "False", type: false },
  ];

  useEffect(() => {
    if (_UserInfo.roleOEndpoint.map((data) => data.Code).includes("33-11")) {
      dispatch(getAllVendors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      getAllShipments(page + 1, {
        ...searchBody,
        region:
          _UserInfo.id === "7d059cd8-f0fb-45db-ae1b-832e80a047da" ||
          _UserInfo.id === "f441e0d3-abf1-4a67-a57e-4b5f5d4f1106" ||
          _UserInfo?.roleOEndpoint?.filter((api) => api.Code === "23-20")[0]
            ? "US"
            : searchBody.region,
      })
    ).then((x) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearch, page, dispatch]);

  useEffect(() => {
    dispatch(savePageNo(page));
  }, [dispatch, page]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    setIsSearch(!isSearch);
  };

  const handleLimitChange = (newPageSize) => {
    setSearchBody({ ...searchBody, pageSize: newPageSize });
    dispatch(saveSearchBody({ ...searchBody, pageSize: newPageSize }));
    setPage(0);
    setIsSearch(!isSearch);
  };

  const deleteSelectedShipments = (id) => {
    setOpenDeleteDialog(false);
    dispatch(deleteShipments(id, page + 1, searchBody));
  };

  // const activeFilters = () => {
  //   const { pageSize, ...restOfSearchBody } = searchBody;
  //   const truthyValuesCount =
  //     Object.values(restOfSearchBody).filter(Boolean).length;
  //   return truthyValuesCount;
  // };

  const activeFilters = useMemo(() => {
    if (
      searchBody.shippingNumber === "" &&
      searchBody.orderiiNumber === "" &&
      searchBody.itemName === "" &&
      searchBody.phoneNumber === "" &&
      searchBody.internationalTrackingNumber === "" &&
      searchBody.fromCreatedAt === null &&
      searchBody.toCreatedAt === null &&
      searchBody.status === null &&
      searchBody.region === null &&
      searchBody.isDamaged === null &&
      searchBody.isDangerousGoods === null &&
      (searchBody.vendorIds === null || searchBody.vendorIds?.length === 0) &&
      searchBody.isVendorExcluded === null
    ) {
      return 0;
    } else {
      const truthyValuesCount = {
        shippingNumber: searchBody.shippingNumber.length > 0 ? 1 : 0,
        orderiiNumber: searchBody.orderiiNumber.length > 0 ? 1 : 0,
        itemName: searchBody.itemName.length > 0 ? 1 : 0,
        phoneNumber: searchBody.phoneNumber.length > 0 ? 1 : 0,
        internationalTrackingNumber:
          searchBody.internationalTrackingNumber.length > 0 ? 1 : 0,
        fromCreatedAt: searchBody.fromCreatedAt !== null ? 1 : 0,
        toCreatedAt: searchBody.toCreatedAt !== null ? 1 : 0,
        status: searchBody.status !== null ? 1 : 0,
        region: searchBody.region !== null ? 1 : 0,
        isDamaged: searchBody.isDamaged !== null ? 1 : 0,
        isDangerousGoods: searchBody.isDangerousGoods !== null ? 1 : 0,
        vendorIds: searchBody.vendorIds?.length > 0 ? 1 : 0,
        isVendorExcluded: searchBody.isVendorExcluded !== null ? 1 : 0,
      };
      const activeFilters = Object.values(truthyValuesCount).filter((item) =>
        item === 1 ? true : false
      )?.length;
      return activeFilters;
    }
  }, [searchBody]);

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "shippingNumber",
      headerName: "Shipping Number",
      flex: 1,
    },
    {
      field: "region",
      headerName: "Region",
      flex: 0.5,
    },
    {
      field: "itemsCount",
      headerName: "Items Count",
      flex: 0.5,
    },
    {
      field: "usersCount",
      headerName: "Related Users",
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <ShipmentStatusMenu
          shipmentId={params.row.id}
          status={params.row.statusName}
          statusId={params.row.status}
          onSuccess={(newStatus) => {
            setIsSearch(!isSearch);
          }}
        />
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) =>
        moment(params.row.createdAt).format("YYYY-MM-DD / hh:mm a"),
    },
    {
      field: "creator",
      headerName: "Creator",
      flex: 1,
      renderCell: (params) => params.row.creator.name,
    },
    {
      field: "notes",
      headerName: "Notes",
      headerAlign: "center",
      flex: 0.3,
      renderHeader: (params) => (
        <Typography
          sx={{
            whiteSpace: "pre-wrap",
            fontSize: "12px",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          Notes
        </Typography>
      ),
      renderCell: (params) =>
        params.row.notes && (
          <Tooltip
            title={<Typography variant="h5">{params.row.notes}</Typography>}
            followCursor
          >
            <Typography variant="body" color="green">
              <VscNote size={30} />
            </Typography>
          </Tooltip>
        ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      // renderCell: (params) => <Icon color="primary">visibility</Icon>,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Icon color="primary">visibility</Icon>}
          label="Shipment Details"
          hidden={!shipmentDetailsRole}
          showInMenu
          onClick={(e) => {
            dispatch(getById(params.row.id)).then((x) =>
              dispatch(openDialog("shipmentDetails"))
            );
          }}
        />,
        <GridActionsCellItem
          icon={<MdDeleteForever size={25} color="red" />}
          label="Delete"
          hidden={!deleteRole}
          showInMenu
          disabled={params.row.itemsCount > 0}
          onClick={(e) => {
            dispatch(getById(params.row.id)).then((x) =>
              setOpenDeleteDialog(true)
            );
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      {openDeleteDialog && (
        <CustomDialog
          isOpen={openDeleteDialog}
          handleClose={() => setOpenDeleteDialog(false)}
          isActiveDelete
          isRequierdActions
          title="Delete confirmation"
          onDelete={() => deleteSelectedShipments(_ShipmentItem.id)}
          selectedObject={`${_ShipmentItem.shippingNumber}`}
        ></CustomDialog>
      )}
      <Drawer open={openSearch} onClose={toggleDrawer(false)}>
        <Divider />
        <Grid container spacing={2} padding={2} sx={{ width: 400 }}>
          <Grid item xs={12}>
            <TextField
              disabled={reload}
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Item Name"
              variant="outlined"
              value={searchBody.itemName}
              onChange={(e) => {
                setSearchBody({
                  ...searchBody,
                  itemName: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(saveSearchBody(searchBody));
                  setPage(0);
                  setIsSearch(!isSearch);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={reload}
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Orderii Number"
              variant="outlined"
              value={searchBody.orderiiNumber}
              onChange={(e) => {
                setSearchBody({
                  ...searchBody,
                  orderiiNumber: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(saveSearchBody(searchBody));
                  setPage(0);
                  setIsSearch(!isSearch);
                }
              }}
            />
          </Grid>
          {_UserInfo?.roleOEndpoint?.filter(
            (api) => api.Code === "23-20"
          )[0] ? null : (
            <Grid item xs={12}>
              <TextField
                disabled={reload}
                size="small"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AiOutlineSearch />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search Customer Name"
                variant="outlined"
                value={searchBody.userName}
                onChange={(e) => {
                  setSearchBody({
                    ...searchBody,
                    userName: e.target.value,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(saveSearchBody(searchBody));
                    setPage(0);
                    setIsSearch(!isSearch);
                  }
                }}
              />
            </Grid>
          )}
          {_UserInfo?.roleOEndpoint?.filter(
            (api) => api.Code === "23-20"
          )[0] ? null : (
            <Grid item xs={12}>
              <TextField
                disabled={reload}
                size="small"
                fullWidth
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AiOutlineSearch />
                    </InputAdornment>
                  ),
                  inputProps: {
                    maxLength: 10,
                  },
                }}
                placeholder="Search Phone Number"
                variant="outlined"
                value={searchBody.phoneNumber}
                onChange={(e) => {
                  setSearchBody({
                    ...searchBody,
                    phoneNumber: e.target.value.replace(/^0+/, ""),
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(saveSearchBody(searchBody));
                    setPage(0);
                    setIsSearch(!isSearch);
                  }
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              disabled={reload}
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search International Tracking Number"
              variant="outlined"
              value={searchBody.internationalTrackingNumber}
              onChange={(e) => {
                setSearchBody({
                  ...searchBody,
                  internationalTrackingNumber: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(saveSearchBody(searchBody));
                  setPage(0);
                  setIsSearch(!isSearch);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disabled={reload}
              id="status"
              options={shipments_status || []}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => <TextField {...params} label="Status" />}
              onChange={(event, value) => {
                setSearchBody({
                  ...searchBody,
                  status: value ? value.id : null,
                });
              }}
              value={
                shipments_status?.filter(
                  (x) => x.id === searchBody.status
                )[0] ?? null
              }
            />
          </Grid>
          <RolePermission permission="33-11">
            <Grid item xs={12}>
              <Autocomplete
                disabled={reload}
                multiple
                id="vendors"
                options={vendors || []}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Vendors" />
                )}
                onChange={(event, value) => {
                  var ids = value.map((x) => x.id);
                  setSearchBody({
                    ...searchBody,
                    vendorIds: value ? ids : searchBody.vendorIds,
                  });
                }}
                value={vendors?.filter((x) =>
                  searchBody.vendorIds
                    ? searchBody.vendorIds.includes(x.id)
                    : null
                )}
              />
            </Grid>
          </RolePermission>
          <RolePermission permission="33-11">
            <Grid item xs={12}>
              <Autocomplete
                disabled={reload}
                options={isVendorExcluded}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Exclude Vendors" />
                )}
                onChange={(event, value) => {
                  setSearchBody({
                    ...searchBody,
                    isVendorExcluded: value ? value.type : null,
                  });
                }}
                value={
                  isVendorExcluded.filter(
                    (x) => x.type === searchBody.isVendorExcluded
                  )[0] ?? null
                }
              />
            </Grid>
          </RolePermission>
          <Grid item xs={12}>
            <ToggleButtonGroup
              disabled={reload}
              fullWidth
              size="small"
              color="primary"
              value={
                searchBody.isDamaged === null ? null : searchBody.isDamaged
              }
              exclusive
              aria-label="isDamaged"
            >
              <ToggleButton
                value={false}
                onClick={() => {
                  setSearchBody({
                    ...searchBody,
                    isDamaged: searchBody.isDamaged === false ? null : false,
                  });
                }}
              >
                Not Damaged
              </ToggleButton>
              <ToggleButton
                value={true}
                onClick={() => {
                  setSearchBody({
                    ...searchBody,
                    isDamaged: searchBody.isDamaged === true ? null : true,
                  });
                }}
              >
                Is Damaged
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              disabled={reload}
              fullWidth
              size="small"
              color="primary"
              value={
                searchBody.isDangerousGoods === null
                  ? null
                  : searchBody.isDangerousGoods
              }
              exclusive
              aria-label="isDangerousGoods"
            >
              <ToggleButton
                value={false}
                onClick={() => {
                  setSearchBody({
                    ...searchBody,
                    isDangerousGoods:
                      searchBody.isDangerousGoods === false ? null : false,
                  });
                }}
              >
                Not DG
              </ToggleButton>
              <ToggleButton
                value={true}
                onClick={() => {
                  setSearchBody({
                    ...searchBody,
                    isDangerousGoods:
                      searchBody.isDangerousGoods === true ? null : true,
                  });
                }}
              >
                DG
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              disabled={reload}
              onClick={(e) => {
                setIsSearch(!isSearch);
                setPage(0);
              }}
            >
              Apply
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={reload}
              fullWidth
              variant="contained"
              color="warning"
              onClick={() => {
                dispatch(
                  saveSearchBody({
                    pageSize: 15,
                    fromCreatedAt: null,
                    toCreatedAt: null,
                    shippingNumber: "",
                    orderiiNumber: "",
                    itemName: "",
                    isVendorExcluded: null,
                    status: null,
                    userName: "",
                    phoneNumber: "",
                    vendorIds: null,
                    internationalTrackingNumber: "",
                    region: null,
                    isDamaged: null,
                    isDangerousGoods: null,
                  })
                );
                setSearchBody({
                  pageSize: 15,
                  fromCreatedAt: null,
                  toCreatedAt: null,
                  shippingNumber: "",
                  orderiiNumber: "",
                  itemName: "",
                  isVendorExcluded: null,
                  status: null,
                  userName: "",
                  phoneNumber: "",
                  vendorIds: null,
                  internationalTrackingNumber: "",
                  region: null,
                  isDamaged: null,
                  isDangerousGoods: null,
                });
                setPage(0);
                setIsSearch(!isSearch);
              }}
            >
              Clear Filter
            </Button>
          </Grid>
        </Grid>
        <Divider />
      </Drawer>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              disabled={reload}
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Shipment number"
              variant="outlined"
              value={searchBody.shippingNumber}
              onChange={(e) => {
                setSearchBody({
                  ...searchBody,
                  shippingNumber: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(saveSearchBody(searchBody));
                  setPage(0);
                  setIsSearch(!isSearch);
                }
              }}
            />
          </Grid>
          {_UserInfo?.roleOEndpoint?.filter(
            (api) => api.Code === "23-20"
          )[0] ? null : (
            <HideFromFedExAccount>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  disabled={reload}
                  options={region}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Region" />
                  )}
                  onChange={(event, value) => {
                    setSearchBody({
                      ...searchBody,
                      region: value?.key ?? null,
                    });
                  }}
                  value={
                    region.filter((x) => x.key === searchBody.region)[0] ?? null
                  }
                />
              </Grid>
            </HideFromFedExAccount>
          )}
          {filterType === "1" ? null : (
            <>
              <Grid item xs={12} md={3}>
                <TextField
                  disabled={reload}
                  size="small"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AiOutlineSearch />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Item Name"
                  variant="outlined"
                  value={searchBody.itemName}
                  onChange={(e) => {
                    setSearchBody({
                      ...searchBody,
                      itemName: e.target.value,
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      dispatch(saveSearchBody(searchBody));
                      setPage(0);
                      setIsSearch(!isSearch);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  disabled={reload}
                  size="small"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AiOutlineSearch />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Orderii Number"
                  variant="outlined"
                  value={searchBody.orderiiNumber}
                  onChange={(e) => {
                    setSearchBody({
                      ...searchBody,
                      orderiiNumber: e.target.value,
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      dispatch(saveSearchBody(searchBody));
                      setPage(0);
                      setIsSearch(!isSearch);
                    }
                  }}
                />
              </Grid>
              {_UserInfo?.roleOEndpoint?.filter(
                (api) => api.Code === "23-20"
              )[0] ? null : (
                <Grid item xs={12} md={3}>
                  <TextField
                    disabled={reload}
                    size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AiOutlineSearch />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Search Customer Name"
                    variant="outlined"
                    value={searchBody.userName}
                    onChange={(e) => {
                      setSearchBody({
                        ...searchBody,
                        userName: e.target.value,
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        dispatch(saveSearchBody(searchBody));
                        setPage(0);
                        setIsSearch(!isSearch);
                      }
                    }}
                  />
                </Grid>
              )}
              {_UserInfo?.roleOEndpoint?.filter(
                (api) => api.Code === "23-20"
              )[0] ? null : (
                <Grid item xs={12} md={3}>
                  <TextField
                    disabled={reload}
                    size="small"
                    fullWidth
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AiOutlineSearch />
                        </InputAdornment>
                      ),
                      inputProps: {
                        maxLength: 10,
                      },
                    }}
                    placeholder="Search Phone Number"
                    variant="outlined"
                    value={searchBody.phoneNumber}
                    onChange={(e) => {
                      setSearchBody({
                        ...searchBody,
                        phoneNumber: e.target.value.replace(/^0+/, ""),
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        dispatch(saveSearchBody(searchBody));
                        setPage(0);
                        setIsSearch(!isSearch);
                      }
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <TextField
                  disabled={reload}
                  size="small"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AiOutlineSearch />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search International Tracking Number"
                  variant="outlined"
                  value={searchBody.internationalTrackingNumber}
                  onChange={(e) => {
                    setSearchBody({
                      ...searchBody,
                      internationalTrackingNumber: e.target.value,
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      dispatch(saveSearchBody(searchBody));
                      setPage(0);
                      setIsSearch(!isSearch);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  disabled={reload}
                  id="status"
                  options={shipments_status || []}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Status" />
                  )}
                  onChange={(event, value) => {
                    dispatch(
                      saveSearchBody({
                        ...searchBody,
                        status: value ? value.id : null,
                      })
                    );
                    setPage(0);
                  }}
                  value={
                    shipments_status?.filter(
                      (x) => x.id === searchBody.status
                    )[0] ?? null
                  }
                />
              </Grid>
              <RolePermission permission="33-11">
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    disabled={reload}
                    multiple
                    id="vendors"
                    options={vendors || []}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Vendors" />
                    )}
                    onChange={(event, value) => {
                      var ids = value.map((x) => x.id);
                      setSearchBody({
                        ...searchBody,
                        vendorIds: value ? ids : searchBody.vendorIds,
                      });
                    }}
                    value={vendors?.filter((x) =>
                      searchBody.vendorIds
                        ? searchBody.vendorIds.includes(x.id)
                        : null
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    disabled={reload}
                    options={isVendorExcluded}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Exclude Vendors" />
                    )}
                    onChange={(event, value) => {
                      setSearchBody({
                        ...searchBody,
                        isVendorExcluded: value ? value.type : null,
                      });
                    }}
                    value={
                      isVendorExcluded.filter(
                        (x) => x.type === searchBody.isVendorExcluded
                      )[0] ?? null
                    }
                  />
                </Grid>
              </RolePermission>
              <Grid item xs={12} md={3}>
                <ToggleButtonGroup
                  disabled={reload}
                  fullWidth
                  size="small"
                  color="primary"
                  value={
                    searchBody.isDamaged === null ? null : searchBody.isDamaged
                  }
                  exclusive
                  aria-label="isDamaged"
                >
                  <ToggleButton
                    value={false}
                    onClick={() => {
                      setSearchBody({
                        ...searchBody,
                        isDamaged:
                          searchBody.isDamaged === false ? null : false,
                      });
                    }}
                  >
                    Not Damaged
                  </ToggleButton>
                  <ToggleButton
                    value={true}
                    onClick={() => {
                      setSearchBody({
                        ...searchBody,
                        isDamaged: searchBody.isDamaged === true ? null : true,
                      });
                    }}
                  >
                    Is Damaged
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} md={3}>
                <ToggleButtonGroup
                  disabled={reload}
                  fullWidth
                  size="small"
                  color="primary"
                  value={
                    searchBody.isDangerousGoods === null
                      ? null
                      : searchBody.isDangerousGoods
                  }
                  exclusive
                  aria-label="isDangerousGoods"
                >
                  <ToggleButton
                    value={false}
                    onClick={() => {
                      setSearchBody({
                        ...searchBody,
                        isDangerousGoods:
                          searchBody.isDangerousGoods === false ? null : false,
                      });
                    }}
                  >
                    Not DG
                  </ToggleButton>
                  <ToggleButton
                    value={true}
                    onClick={() => {
                      setSearchBody({
                        ...searchBody,
                        isDangerousGoods:
                          searchBody.isDangerousGoods === true ? null : true,
                      });
                    }}
                  >
                    DG
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </>
          )}
          <Grid item xs={3}>
            <DateRangepicker
              disabled={reload}
              startDate={searchBody.fromCreatedAt}
              endDate={searchBody.toCreatedAt}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  fromCreatedAt: moment(searchDate[0].startDate).format(
                    "YYYY-MM-DD"
                  ),
                  toCreatedAt: moment(searchDate[0].endDate).format(
                    "YYYY-MM-DD"
                  ),
                };
                if (
                  updatedFilter.fromCreatedAt !== searchBody.fromCreatedAt ||
                  updatedFilter.toCreatedAt !== searchBody.toCreatedAt
                ) {
                  setSearchBody({
                    ...searchBody,
                    fromCreatedAt: moment(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    toCreatedAt: moment(searchDate[0].endDate).format(
                      "YYYY-MM-DD"
                    ),
                  });
                }
              }}
            />
          </Grid>
          <Grid
            item
            xs={
              filterType === "1"
                ? 3
                : !_UserInfo.roleOEndpoint
                    .map((data) => data.Code)
                    .includes("33-11")
                ? 12
                : 9
            }
          >
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Stack direction="row" spacing={2}>
                {filterType === "1" ? (
                  <Tooltip title="Advanced Search">
                    <IconButton
                      onClick={() => {
                        setOpenSearch(true);
                      }}
                    >
                      <Badge badgeContent={activeFilters} color="error">
                        <FaFilter />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                ) : null}
                <IconButton
                  sx={{ marginLeft: 1 }}
                  onClick={() => {
                    setIsSearch(!isSearch);
                    setPage(0);
                  }}
                >
                  <Tooltip title="Search with filter" disabled={reload}>
                    <SearchIcon />
                  </Tooltip>
                </IconButton>
                <Tooltip title="Clear filter">
                  <IconButton
                    disabled={reload}
                    onClick={() => {
                      dispatch(
                        saveSearchBody({
                          pageSize: 15,
                          fromCreatedAt: null,
                          toCreatedAt: null,
                          shippingNumber: "",
                          orderiiNumber: "",
                          itemName: "",
                          isVendorExcluded: null,
                          status: null,
                          userName: "",
                          phoneNumber: "",
                          vendorIds: null,
                          internationalTrackingNumber: "",
                          region: null,
                          isDamaged: null,
                          isDangerousGoods: null,
                        })
                      );
                      setSearchBody({
                        pageSize: 15,
                        fromCreatedAt: null,
                        toCreatedAt: null,
                        shippingNumber: "",
                        orderiiNumber: "",
                        itemName: "",
                        isVendorExcluded: null,
                        status: null,
                        userName: "",
                        phoneNumber: "",
                        vendorIds: null,
                        internationalTrackingNumber: "",
                        region: null,
                        isDamaged: null,
                        isDangerousGoods: null,
                      });
                      setPage(0);
                      setIsSearch(!isSearch);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              <IconButton
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch(changeFiltersType(filterType === "1" ? "0" : "1"));
                }}
              >
                {filterType === "1" ? (
                  <FaArrowDown size={30} />
                ) : (
                  <FaArrowUp size={30} />
                )}
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box>
                {!_ShipmentsItem.data ? (
                  <CustomSpinner />
                ) : (
                  <Box
                    sx={{
                      height: {
                        md:
                          filterType === "1"
                            ? "calc(100vh - 275px)"
                            : "calc(100vh - 390px)",
                        xs: 1000,
                      },
                    }}
                  >
                    <DataGrid
                      sx={{
                        ".highlight": {
                          bgcolor: "lightgray",
                          "&:hover": {
                            bgcolor: "darkgrey",
                          },
                        },
                        ".quantity": {
                          justifyContent: "center",
                        },
                      }}
                      loading={reload}
                      rows={_ShipmentsItem.data}
                      getRowId={(params) => params.id}
                      columns={columns}
                      rowHeight={57}
                      getRowClassName={(params) => {
                        return params.row.status === 5 ||
                          params.row.status === 6
                          ? "highlight"
                          : "";
                      }}
                      disableSelectionOnClick
                      pagination
                      pageSize={_SearchBody.pageSize}
                      page={page}
                      onPageChange={(newPage) => handlePageChange(newPage)}
                      onPageSizeChange={(newPageSize) =>
                        handleLimitChange(newPageSize)
                      }
                      rowCount={_ShipmentsItem.rowCount}
                      paginationMode="server"
                      rowsPerPageOptions={[15, 25, 75, 100]}
                      components={{
                        LoadingOverlay: LinearProgress,
                        NoRowsOverlay: CustomNoRowsOverlay,
                      }}
                    />
                  </Box>
                )}
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
