import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
import { indigo } from "@mui/material/colors";
import StoreIcon from "@mui/icons-material/Store";

const TotalVendors = (props) => (
  <Card {...props}>
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{
          height: 98,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Typography color="textSecondary" gutterBottom variant="h6">
            TOTAL VENDORS
          </Typography>
          <Typography color="textPrimary" variant="h3">
            {props.data ? props.data.toLocaleString() : ""}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: indigo[600],
              height: 56,
              width: 56,
            }}
          >
            <StoreIcon />
          </Avatar>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default TotalVendors;
