import { Helmet } from "react-helmet";
import { Box, Stack } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { openUsersDialog, userPhoneCodeHistoryDialog } from "src/actions/users";
import UsersForm from "../../Form";
import { FaUsers } from "react-icons/fa";
import RolePermission from "src/components/RolePermission";
import { MdOutlinePhoneAndroid } from "react-icons/md";

const UsersHeader = () => {
  const dispatch = useDispatch();

  const _Users = useSelector((state) => state.users.openDialog);

  return (
    <>
      <Helmet>
        <title>Users | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Users
              </Typography>
            }
            avatar={<FaUsers size={20} />}
            action={
              <Stack direction="row" spacing={2}>
                <RolePermission permission="31-01">
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<AddIcon />}
                    onClick={() => dispatch(openUsersDialog(_Users))}
                  >
                    Create
                  </Button>
                </RolePermission>
                <RolePermission permission="31-20">
                  <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    startIcon={<MdOutlinePhoneAndroid />}
                    onClick={() => {
                      dispatch(userPhoneCodeHistoryDialog());
                    }}
                  >
                    Unregistered Phone Codes
                  </Button>
                </RolePermission>
              </Stack>
            }
          />
          {_Users ? <UsersForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default UsersHeader;
