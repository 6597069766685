import { DeleteForever } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Icon, InputAdornment } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useState } from "react";
import { FaBoxOpen, FaRegCopy } from "react-icons/fa";
import { RiVipCrownFill, RiVipFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderItemByQr,
  generateShippingNumber,
  updateShipmentItemsStatus,
} from "src/actions/orderItems";
import {
  getById,
  getByShipmentNumber,
  openDialog,
  printPackingList,
  resetForm,
  updateShipments,
} from "src/actions/shipments";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";
import { CustomDialog } from "src/components/CustomDialog";
import RolePermission from "src/components/RolePermission";
import theme from "src/theme";
import { VENDORS_LOGO } from "src/utils/common";

export default function ShipmentsForm(props) {
  const dispatch = useDispatch();

  const _UserInfo = useSelector((state) => state.users.userInfo);

  const shipments = useSelector((state) => state.shipments);
  const searchBody = useSelector((state) => state.shipments.searchBody);
  const pageNo = useSelector((state) => state.shipments.pageNo);
  const reload = useSelector((state) => state.shipments.reload);
  const orderItemSearchBody = useSelector(
    (state) => state.orderItems.searchBody
  );
  const orderItemsPageNo = useSelector(
    (state) => state.orderItems.saveItemsPageNum
  );
  const [shippingNumber, setShippingNumber] = useState("");
  const [orderiiNumber, setOrderiiNumber] = useState("");
  const [shipmentNote, setShipmentNotes] = useState(shipments.form.notes ?? "");
  const [items, setItems] = useState(shipments.form.orderItems ?? []);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [searchShipmentNumber, setSearchShipmentNumber] = useState("");
  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);

  const region = [
    { name: "Turkey", key: "TR" },
    { name: "United State", key: "US" },
    // { name: "Europe", key: "EU" },
    // { name: "Arabic Gulf", key: "AG" },
    // { name: "WSM", key: "WSM" },
  ];

  useEffect(() => {
    setItems(shipments.form.orderItems ?? []);
    setShipmentNotes(shipments.form.notes ?? "");
    setOrderiiNumber("");
    setShippingNumber("");
  }, [shipments.form.notes, shipments.form.orderItems]);

  function searchItemByQr(e) {
    e.preventDefault();
    if (orderiiNumber === "")
      dispatch(showErrorSnackbar("Please enter Orderii number"));
    else {
      let ids = items.map((number) => String(number.orderiiNumber));
      if (ids.includes(orderiiNumber))
        dispatch(showErrorSnackbar("This items is already added"));
      else {
        dispatch(
          getOrderItemByQr(1, {
            orderiiNumber: orderiiNumber,
            isVip: null,
            sortByCreatedAtAsc: false,
          })
        ).then((res) => {
          var result = res?.data?.data;
          if (result?.length > 0) {
            setItems([...result, ...items]);
          } else
            dispatch(
              showErrorSnackbar(
                "There is no item with this Orderii number: " + orderiiNumber
              )
            );
        });
      }
    }
  }

  function addShipment() {
    if (selectedRegion === null) {
      dispatch(
        showErrorSnackbar("Region is required, please select a region first.")
      );
    } else {
      var ids = items.map((x) => x.id);
      dispatch(
        generateShippingNumber(
          { orderItemIds: ids, region: selectedRegion, notes: shipmentNote },
          orderItemsPageNo + 1,
          _UserInfo?.roleOEndpoint?.filter((api) => api.Code === "23-01")[0]
            ? "orderItemPage"
            : "orderItemFilteredOnlyPage",
          {
            ...orderItemSearchBody,
            sortByCreatedAtAsc: false,
          }
        )
      ).then((data) => {
        if (data?.message === "Success") {
          if (props && props.hasOwnProperty("handleClose")) {
            props.handleClose();
          }
          setShippingNumber(data.data);
          dispatch(resetForm());
        }
      });
    }
  }

  function updateSelectedShipments() {
    var ids = items.map((x) => x.id);
    dispatch(
      updateShipments(
        shipments.form.id,
        {
          orderItemIds: ids,
          notes: shipmentNote,
        },
        shipments.type === "shipmentDetails" ? "shipmentPage" : "orderItemPage",
        shipments.type === "shipmentDetails"
          ? pageNo + 1
          : orderItemsPageNo + 1,
        shipments.type === "shipmentDetails"
          ? searchBody
          : {
              ...orderItemSearchBody,
              sortByCreatedAtAsc: false,
            }
      )
    ).then((data) => {
      if (props && props.hasOwnProperty("handleClose")) {
        props.handleClose();
      }
      setShippingNumber(data?.data);
      dispatch(resetForm());
    });
  }
  function changeItemStatus(item) {
    setLoadingChangeStatus(true);
    dispatch(updateShipmentItemsStatus(item))
      .then(() => {
        dispatch(getById(shipments.form.id)).then((x) => {});
        setLoadingChangeStatus(false);
        // document.getElementById("textQr").select();
      })
      .catch((err) => {
        setLoadingChangeStatus(false);
        dispatch(showErrorSnackbar("Network Error"));
      });
  }

  function undo(item) {
    var list = items.filter((product) => product !== item);
    setItems(list);
  }

  var columns = [
    {
      field: "vendor",
      headerName: "Vendor",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column" spacing={1} sx={{ alignItems: "center" }}>
          <img
            alt="vendor logo"
            src={
              params.row.vendorLogo
                ? params.row.vendorLogo
                : VENDORS_LOGO.filter((vendor) =>
                    vendor.name === params.vendorName ? vendor.path : null
                  )[0]?.path
            }
            width={95}
          />
          {params.row.isVipShipped && (
            <Stack direction="column" spacing={-0.8}>
              <RiVipCrownFill size={30} color="#D99B12" />
              <RiVipFill size={30} color="#D99B12" />
            </Stack>
          )}
          {params.shippingOptionNameId != null ? (
            <Chip
              size="small"
              label={params.shippingOptionNameEn}
              color={
                params.shippingOptionNameEn === "Sea"
                  ? "info"
                  : params.shippingOptionNameEn === "Express"
                  ? "warning"
                  : "success"
              }
            />
          ) : null}
        </Stack>
      ),
    },
    {
      field: "imageUrl",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => (
        <Box
          component="img"
          sx={{
            height: 130,
            objectFit: "contain",
          }}
          alt="itemImage"
          src={params.value}
        />
      ),
    },
    {
      field: "itemName",
      flex: 2,
      headerName: "Name",
      renderCell: (params) => (
        <Stack direction="column" spacing={1} alignItems="center">
          <Tooltip title={params.row.itemName}>
            <Typography
              variant="body2"
              sx={{
                whiteSpace: "normal",
                overflowWrap: "break-word",
                wordWrap: "break-word",
                hyphens: "auto",
              }}
            >
              {params.row.itemName}
            </Typography>
          </Tooltip>
          {params.row?.isDamaged ? (
            <Chip
              size="small"
              label="Damaged"
              color="error"
              variant="outlined"
            />
          ) : null}
          {params.row?.isDangerousGoods ? (
            <Chip
              size="small"
              label="Dangerous Goods"
              color="error"
              variant="outlined"
            />
          ) : null}
        </Stack>
      ),
    },
    {
      field: "itemStatus",
      flex: 2,
      headerName: "Item Status",
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={params.row.itemStatusString}
            color={"secondary"}
          />
        );
      },
    },
    { field: "orderiiNumber", flex: 1, headerName: "Orderii Number" },
    {
      field: "shippingNumber",
      flex: 2,
      headerName: "Shipping Number",
      renderCell: (params) =>
        params.row.shippingNumber?.shippingNumber ? (
          <Tooltip title={params.row.shippingNumber?.shippingNumber ?? +"---"}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  params.row.shippingNumber?.shippingNumber
                );
                dispatch(
                  showSuccessSnackbar("Shipment number copied to clipboard")
                );
              }}
            >
              <Typography variant="body" noWrap color="primary">
                <FaRegCopy />{" "}
                {params.row.shippingNumber?.shippingNumber ?? +"---"}
              </Typography>
            </Button>
          </Tooltip>
        ) : null,
    },
    { field: "quantity", flex: 1, headerName: "Quantity" },
    {
      field: "subTotal",
      flex: 1,
      headerName: "Subtotal $",
      renderCell: (params) => (
        <Typography variant="subtitle2">
          {params.row?.subTotal?.toLocaleString() ?? ""}
        </Typography>
      ),
    },
    {
      field: "user",
      flex: 1,
      headerName: "Customer",
      renderCell: (params) =>
        params.row.user ? (
          <Stack justifyContent="center" alignItems="center">
            <Typography variant="subtitle2">
              {params.row.user?.name ?? ""}
            </Typography>
            <Typography variant="subtitle2">
              {params.row.user?.phoneNumber ?? ""}
            </Typography>
            {params.row.user?.customerTierName ? (
              <Chip
                size="small"
                label={params.row.user?.customerTierName}
                color="success"
              />
            ) : null}
          </Stack>
        ) : (
          <Typography color={theme.palette.warning.dark} variant="subtitle2">
            Waiting
          </Typography>
        ),
    },
    {
      field: "actions",
      flex: 1,
      headerName: "Actions",
      renderCell: (params) => (
        <IconButton
          color="error"
          aria-label="delete"
          onClick={(e) => {
            undo(params.row);
          }}
        >
          <Tooltip title="Delete" aria-label="delete">
            <DeleteForever />
          </Tooltip>
        </IconButton>
      ),
    },
  ];

  columns = columns.filter((column) =>
    shipments.type === "shipmentDetails" || shipments.type === "updateShipment"
      ? column
      : column.field !== "user"
  );

  return (
    <CustomDialog
      isOpen={shipments.openDialog}
      handleClose={() => {
        dispatch(resetForm());
        dispatch(openDialog(""));
        setItems([]);
        setShipmentNotes("");
        if (props && props.hasOwnProperty("handleClose")) {
          props.handleClose();
        }
      }}
      title={
        shipments.type === "shipmentDetails" ||
        shipments.type === "updateShipment"
          ? shipments.form.shippingNumber
          : shippingNumber.length > 0
          ? shippingNumber
          : "Generate Shipment"
      }
      maxWidth="xl"
      Draggable={true}
      loading={reload}
      // isRequierdActions
      // isRequierdAdd
      // onAdd={searchItemByQr}
      // onClear={() => {
      //   setItems([]);
      //   setOrderiiNumber("");
      //   setShippingNumber("");
      // }}
    >
      {reload ? (
        <LinearProgress sx={{ marginTop: -2, marginBottom: 2 }} />
      ) : null}
      <Box>
        <Grid container spacing={2}>
          {shipments.type === "shipmentDetails" ? (
            <Grid item xs={12} display="flex" justifyContent="end">
              <RolePermission permission="23-16">
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    changeItemStatus(items.map((x) => x.id));
                  }}
                  loading={loadingChangeStatus}
                  disabled={shipments.form.statusName === "SentToIraq"}
                >
                  Change Status And Notify Users
                </LoadingButton>
              </RolePermission>
              <RolePermission permission="59-08">
                <Button
                  sx={{ marginLeft: 1 }}
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    dispatch(
                      printPackingList(shipments.form.shippingNumber, true)
                    );
                  }}
                >
                  Print Packing List
                </Button>
              </RolePermission>
            </Grid>
          ) : null}
          {shipments.type === "updateShipment" ? (
            <Grid item xs={12}>
              <TextField
                id="textQr"
                size="small"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FaBoxOpen size={25} />
                    </InputAdornment>
                  ),
                }}
                placeholder="Shipment Number"
                sx={{ marginRight: 1 }}
                variant="outlined"
                value={searchShipmentNumber}
                onChange={(e) => {
                  setSearchShipmentNumber(e.target.value);
                }}
                onKeyDown={(e) =>
                  e.key === "Enter"
                    ? dispatch(
                        getByShipmentNumber(searchShipmentNumber, items)
                      ).then((x) => {})
                    : null
                }
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextField
              id="textQr"
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon color="action">search</Icon>
                  </InputAdornment>
                ),
              }}
              placeholder="Orderii number (Scan QR or enter manually)"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={orderiiNumber}
              onChange={(e) => {
                setOrderiiNumber(e.target.value);
              }}
              onKeyDown={(e) => (e.key === "Enter" ? searchItemByQr(e) : null)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="note"
              size="small"
              fullWidth
              placeholder="Notes"
              sx={{ marginRight: 1 }}
              variant="outlined"
              multiline
              rows={3}
              value={shipmentNote}
              onChange={(e) => {
                setShipmentNotes(e.target.value);
              }}
            />
          </Grid>
          {shipments.type === "shipmentDetails" ||
          shipments.type === "updateShipment" ? null : (
            <Grid item xs={12}>
              <Autocomplete
                disabled={reload}
                options={region}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Region" />
                )}
                onChange={(event, value) => {
                  setSelectedRegion(value ? value.key : null);
                }}
                value={
                  region.filter((x) => x.key === selectedRegion)[0] ?? null
                }
              />
            </Grid>
          )}
          <Grid item xs={12}>
            {items.length > 0 ? (
              <Box
                sx={{
                  height: { md: "calc(100vh - 460px)", xs: 1000 },
                  minWidth: 1050,
                }}
              >
                {shippingNumber && (
                  <Typography variant="h4" sx={{ mb: 2 }}>
                    {`Shipping number: ${shippingNumber}`}{" "}
                  </Typography>
                )}
                <DataGrid
                  rowHeight={150}
                  rows={items}
                  columns={columns}
                  pageSize={30}
                  rowsPerPageOptions={[30]}
                  selectionModel={[]}
                />
              </Box>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {shipments.type === "shipmentDetails" ||
            shipments.type === "updateShipment" ? (
              <strong style={{ marginRight: "20px" }}>
                Note: After adding items, please click on the update shipment
                button
              </strong>
            ) : (
              <span></span>
            )}
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 1 }}
              disabled={
                shipments.type === "updateShipment" &&
                !shipments.form.shippingNumber
              }
              onClick={() => {
                shipments.type === "shipmentDetails" ||
                shipments.type === "updateShipment"
                  ? updateSelectedShipments()
                  : addShipment();
              }}
            >
              {shipments.type === "shipmentDetails" ||
              shipments.type === "updateShipment"
                ? "Update Shipment"
                : "Generate Shipment"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
}
