import {
  Card,
  CardHeader,
  Divider,
  Stack,
  Typography,
  Box,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getItemsPurchasedReport,
  ItemsPurchasedReportExport,
  saveItemsPurchasedReportPageNo,
  saveItemsPurchasedReportSearchBody,
} from "src/actions/reports";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import moment from "moment";

export default function ItemsPurchasedReport(props) {
  const dispatch = new useDispatch();

  const {
    itemsPurchasedReportData,
    itemsPurchasedReportSearchBody,
    itemsPurchasedReportPageNo,
    reload,
  } = useSelector((state) => state.reports);

  useEffect(() => {
    dispatch(
      getItemsPurchasedReport(itemsPurchasedReportPageNo + 1, {
        pageSize: itemsPurchasedReportSearchBody.pageSize,
        year: props.selectedYear,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.selectedYear,
    itemsPurchasedReportPageNo,
    itemsPurchasedReportSearchBody,
  ]);

  const handlePageChange = (newPage) => {
    dispatch(saveItemsPurchasedReportPageNo(newPage));
  };

  const handleLimitChange = (newPageSize) => {
    dispatch(
      saveItemsPurchasedReportSearchBody({
        ...itemsPurchasedReportSearchBody,
        pageSize: newPageSize,
      })
    );
  };

  const columns = [
    {
      field: "sequence",
      headerName: "#",
      width: 80,
    },
    {
      field: "userId",
      headerName: "User Id",
      width: 200,
    },
    {
      field: "orderiiNumber",
      headerName: "Orderii Number",
      width: 200,
    },
    {
      field: "itemPurchasePrice",
      headerName: "Item Cost Price",
      width: 200,
    },
    {
      field: "estimatedShippingCost",
      headerName: "Estimated Shipping Costs",
      width: 200,
    },
    {
      field: "estimatedTimeToDelivery",
      headerName: "Estimated Time to Delivery",
      width: 200,
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      width: 200,
      renderCell: (params) =>
        moment(params.row.orderDate).format("YYYY-MM-DD / hh:mm a"),
    },
    {
      field: "vendorInvoiceNumber",
      headerName: "Vendor Invoice Number",
      width: 200,
    },
    {
      field: "shippingInvoiceNumber",
      headerName: "Shipping Invoice Number",
      width: 200,
    },
    {
      field: "internationalTrackingNumber",
      headerName: "International Tracking Number",
      width: 230,
    },
    {
      field: "purchasedDate",
      headerName: "Purchase Date",
      width: 200,
      renderCell: (params) =>
        params.row.purchasedDate
          ? moment(params.row.purchasedDate).format("YYYY-MM-DD / hh:mm a")
          : "",
    },
    {
      field: "awaitingSupplierShipmentDate",
      headerName: "Awaiting Supplier Shipment",
      width: 200,
      renderCell: (params) =>
        params.row.awaitingSupplierShipmentDate
          ? moment(params.row.awaitingSupplierShipmentDate).format(
              "YYYY-MM-DD / hh:mm a"
            )
          : "",
    },
    {
      field: "delayedBySupplierDate",
      headerName: "Delayed By Supplier",
      width: 200,
      renderCell: (params) =>
        params.row.delayedBySupplierDate
          ? moment(params.row.delayedBySupplierDate).format(
              "YYYY-MM-DD / hh:mm a"
            )
          : "",
    },
    {
      field: "shippedToRegionalWareHouseDate",
      headerName: "Shipped To Region Warehouse",
      width: 200,
      renderCell: (params) =>
        params.row.shippedToRegionalWareHouseDate
          ? moment(params.row.shippedToRegionalWareHouseDate).format(
              "YYYY-MM-DD / hh:mm a"
            )
          : "",
    },
    {
      field: "notDeliveredBySellerDate",
      headerName: "Not Delivered By Seller",
      width: 200,
      renderCell: (params) =>
        params.row.notDeliveredBySellerDate
          ? moment(params.row.notDeliveredBySellerDate).format(
              "YYYY-MM-DD / hh:mm a"
            )
          : "",
    },
    {
      field: "inRegionalWareHouseDate",
      headerName: "In Region Warehouse",
      width: 200,
      renderCell: (params) =>
        params.row.inRegionalWareHouseDate
          ? moment(params.row.inRegionalWareHouseDate).format(
              "YYYY-MM-DD / hh:mm a"
            )
          : "",
    },
    {
      field: "itemIsBeingReturnedDate",
      headerName: "Item Is Being Returned",
      width: 200,
      renderCell: (params) =>
        params.row.itemIsBeingReturnedDate
          ? moment(params.row.itemIsBeingReturnedDate).format(
              "YYYY-MM-DD / hh:mm a"
            )
          : "",
    },
    {
      field: "itemReturnedSuccessfullyDate",
      headerName: "Item Returned Successfully",
      width: 200,
      renderCell: (params) =>
        params.row.itemReturnedSuccessfullyDate
          ? moment(params.row.itemReturnedSuccessfullyDate).format(
              "YYYY-MM-DD / hh:mm a"
            )
          : "",
    },
    {
      field: "internationalShippingDate",
      headerName: "International Shipping",
      width: 200,
      renderCell: (params) =>
        params.row.internationalShippingDate
          ? moment(params.row.internationalShippingDate).format(
              "YYYY-MM-DD / hh:mm a"
            )
          : "",
    },
    {
      field: "shipmentPreparingDate",
      headerName: "Shipment Preparing",
      width: 200,
      renderCell: (params) =>
        params.row.shipmentPreparingDate
          ? moment(params.row.shipmentPreparingDate).format(
              "YYYY-MM-DD / hh:mm a"
            )
          : "",
    },
    {
      field: "shippedToIraq",
      headerName: "Shipped To Iraq",
      width: 200,
      renderCell: (params) =>
        params.row.shippedToIraqDate
          ? moment(params.row.shippedToIraqDate).format("YYYY-MM-DD / hh:mm a")
          : "",
    },
    {
      field: "inTransitToTheFinalDestinationDate",
      headerName: "In Transit To The Final Destination",
      width: 200,
      renderCell: (params) =>
        params.row.inTransitToTheFinalDestinationDate
          ? moment(params.row.inTransitToTheFinalDestinationDate).format(
              "YYYY-MM-DD / hh:mm a"
            )
          : "",
    },
    {
      field: "iraqCustomsDate",
      headerName: "Iraq Customs",
      width: 200,
      renderCell: (params) =>
        params.row.iraqCustomsDate
          ? moment(params.row.iraqCustomsDate).format("YYYY-MM-DD / hh:mm a")
          : "",
    },
    {
      field: "holdByCustomsDate",
      headerName: "Hold By Customs",
      width: 200,
      renderCell: (params) =>
        params.row.holdByCustomsDate
          ? moment(params.row.holdByCustomsDate).format("YYYY-MM-DD / hh:mm a")
          : "",
    },
    {
      field: "onTheWayToBaghdadWarehouseDate",
      headerName: "On The Way To Baghdad Warehouse",
      width: 200,
      renderCell: (params) =>
        params.row.onTheWayToBaghdadWarehouseDate
          ? moment(params.row.onTheWayToBaghdadWarehouseDate).format(
              "YYYY-MM-DD / hh:mm a"
            )
          : "",
    },
    {
      field: "inBaghdadWareHouseDate",
      headerName: "In Baghdad Warehouse",
      width: 200,
      renderCell: (params) =>
        params.row.inBaghdadWareHouseDate
          ? moment(params.row.inBaghdadWareHouseDate).format(
              "YYYY-MM-DD / hh:mm a"
            )
          : "",
    },
    {
      field: "readyForCollectionDate",
      headerName: "Ready For Collection",
      width: 200,
      renderCell: (params) =>
        params.row.readyForCollectionDate
          ? moment(params.row.readyForCollectionDate).format(
              "YYYY-MM-DD / hh:mm a"
            )
          : "",
    },
    {
      field: "readyForDeliveryDate",
      headerName: "Ready For Delivery",
      width: 200,
      renderCell: (params) =>
        params.row.readyForDeliveryDate
          ? moment(params.row.readyForDeliveryDate).format(
              "YYYY-MM-DD / hh:mm a"
            )
          : "",
    },
    {
      field: "outOfDeliveryDate",
      headerName: "Out For Delivery",
      width: 200,
      renderCell: (params) =>
        params.row.outOfDeliveryDate
          ? moment(params.row.outOfDeliveryDate).format("YYYY-MM-DD / hh:mm a")
          : "",
    },
    {
      field: "deliveredDate",
      headerName: "Delivered",
      width: 200,
      renderCell: (params) =>
        params.row.deliveredDate
          ? moment(params.row.deliveredDate).format("YYYY-MM-DD / hh:mm a")
          : "",
    },
    {
      field: "canceledByCustomerDate",
      headerName: "Canceled By Customer",
      width: 200,
      renderCell: (params) =>
        params.row.canceledByCustomerDate
          ? moment(params.row.canceledByCustomerDate).format(
              "YYYY-MM-DD / hh:mm a"
            )
          : "",
    },
    {
      field: "canceledDate",
      headerName: "Canceled",
      width: 200,
      renderCell: (params) =>
        params.row.canceledDate
          ? moment(params.row.canceledDate).format("YYYY-MM-DD / hh:mm a")
          : "",
    },
  ];

  function CustomToolbar() {
    return (
      <div>
        <Box>
          <Box>
            <Card>
              <CardHeader
                title="Items Purchased Report"
                subheader={
                  <Stack direction="column" spacing={1}>
                    <Typography variant="caption">
                      {props.selectedYear}
                    </Typography>
                  </Stack>
                }
              />
              <Divider />
            </Card>
          </Box>
          <Box>
            <GridToolbarContainer>
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(
                    ItemsPurchasedReportExport(itemsPurchasedReportPageNo + 1, {
                      year: props.selectedYear,
                      isExport: true,
                    })
                  );
                }}
              >
                Export
              </Button>
            </GridToolbarContainer>
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <Card style={{ marginTop: 10 }} ref={props.print}>
      <PerfectScrollbar>
        <Box sx={{ height: "calc(100vh - 320px)" }}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-row ": {
                textAlign: "center",
              },
              "& .css-204u17-MuiDataGrid-main": {
                paddingLeft: "15px",
              },
              "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                paddingLeft: "15px",
              },
            }}
            columns={columns}
            loading={reload}
            rows={itemsPurchasedReportData?.data?.map((row, index) => {
              return { ...row, sequence: index + 1 };
            })}
            pagination
            pageSize={itemsPurchasedReportSearchBody.pageSize}
            page={itemsPurchasedReportPageNo}
            onPageChange={(newPage) => handlePageChange(newPage)}
            onPageSizeChange={(newPageSize) => handleLimitChange(newPageSize)}
            rowCount={itemsPurchasedReportData.rowCount}
            getRowId={(params) => params.sequence}
            paginationMode="server"
            rowsPerPageOptions={[15, 25, 75, 100]}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            disableSelectionOnClick
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
