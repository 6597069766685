import {
  Grid,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Divider,
  LinearProgress,
  Box,
  Stack,
  TextField,
  Autocomplete,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  getNoteHistory,
  getNoteHistoryReviewersList,
  openNoteHistoryDialog,
  saveNoteHistoryPageNo,
  saveNoteHistorySearchBody,
} from "src/actions/orders";
import { DataGrid } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import DateRangepicker from "src/components/DateRangePicker";
import { Search as SearchIcon } from "react-feather";
import Close from "@mui/icons-material/Close";

const NoteHistoryDialog = (props) => {
  const dispatch = useDispatch();

  const {
    orderHistory,
    orderHistorySearchBody,
    orderHistoryDialog,
    orderHistoryPageNo,
    selectedId,
    selectedIdType,
    orderDetailsReload,
  } = useSelector((state) => state.orders);

  const [reviewersData, setReviewersData] = useState([]);
  const [orderNumber, setOrderNumber] = useState(
    orderHistorySearchBody?.orderNumber ?? ""
  );
  const [giftOrderNumber, setGiftOrderNumber] = useState(
    orderHistorySearchBody?.giftOrderNumber ?? ""
  );
  const [orderiiNumber, setOrderiiNumber] = useState(
    orderHistorySearchBody?.orderiiNumber ?? ""
  );

  useEffect(() => {
    if (selectedId) {
      dispatch(
        getNoteHistory(orderHistoryPageNo + 1, {
          ...orderHistorySearchBody,
          orderItemId: selectedIdType === "orderItem" ? selectedId : null,
          orderId: selectedIdType === "order" ? selectedId : null,
          giftOrderId: selectedIdType === "giftOrder" ? selectedId : null,
          pageSize: 100,
        })
      );
    }
    console.log(orderHistorySearchBody);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderHistoryPageNo, orderHistorySearchBody]);

  const handlePageChange = (newPage) => {
    dispatch(saveNoteHistoryPageNo(newPage));
  };

  const handleDialog = () => {
    dispatch(
      openNoteHistoryDialog({
        id: null,
        type: null,
      })
    );
  };

  useEffect(() => {
    dispatch(getNoteHistoryReviewersList())
      .then((res) => {
        setReviewersData(res.data);
      })
      .catch((err) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "reviewer",
      headerName: "Reviewer",
      flex: 1,
      renderCell: (params) => params.row.reviewer?.name,
    },
    {
      field: "oldNotes",
      headerName: "Old Value",
      flex: 1.5,
      renderCell: (params) => (
        <Tooltip
          title={
            <Typography variant="body1" noWrap>
              {params.row.oldNotes}
            </Typography>
          }
        >
          <Typography variant="body1" noWrap>
            {params.row.oldNotes}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "newNotes",
      headerName: "New Value",
      flex: 1.5,
      renderCell: (params) => (
        <Tooltip
          title={
            <Typography variant="body1" noWrap>
              {params.row.newNotes}
            </Typography>
          }
        >
          <Typography variant="body1" noWrap>
            {params.row.newNotes}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "order",
      headerName: "Order / Order Item",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body" noWrap>
            {params.row.orderItem?.orderiiNumber ||
              params.row.order?.number ||
              params.row.giftOrder?.number}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "owner",
      headerName: "Order Owner",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body">
            {params.row.orderItem?.owner?.name ||
              params.row.order?.owner?.name ||
              params.row.giftOrder?.owner?.name}
          </Typography>
          <Typography variant="caption">
            {params.row.orderItem?.owner?.phoneNumber ||
              params.row.order?.owner?.phoneNumber ||
              params.row.giftOrder?.owner?.phoneNumber}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "reviewAt",
      headerName: "Date",
      flex: 0.5,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body">
            {moment(params.row.reviewAt).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="caption">
            {moment(params.row.reviewAt).format("hh:mm a")}
          </Typography>
        </Stack>
      ),
    },
  ];

  return (
    <Dialog
      open={orderHistoryDialog}
      keepMounted
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Typography color="primary">Note History</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              options={reviewersData}
              getOptionLabel={(option) => (option ? option.name : "")}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Reviewers" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveNoteHistorySearchBody({
                    ...orderHistorySearchBody,
                    reviewerId: value?.id,
                  })
                );
              }}
              value={
                reviewersData.filter(
                  (x) => x.id === orderHistorySearchBody.reviewerId
                )[0] ?? null
              }
            />
          </Grid>
          {selectedIdType === "order" && (
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                fullWidth
                placeholder="Order Number"
                sx={{ marginRight: 1 }}
                variant="outlined"
                value={orderNumber}
                onChange={(e) => {
                  setOrderNumber(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(
                      saveNoteHistorySearchBody({
                        ...orderHistorySearchBody,
                        orderNumber: orderNumber,
                      })
                    );
                  }
                }}
              />
            </Grid>
          )}
          {selectedIdType === "giftOrder" && (
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                fullWidth
                placeholder="Gift Order Number"
                sx={{ marginRight: 1 }}
                variant="outlined"
                value={giftOrderNumber}
                onChange={(e) => {
                  setGiftOrderNumber(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(
                      saveNoteHistorySearchBody({
                        ...orderHistorySearchBody,
                        giftOrderNumber: giftOrderNumber,
                      })
                    );
                  }
                }}
              />
            </Grid>
          )}
          {selectedIdType === "orderItem" && (
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                fullWidth
                placeholder="Ordrii Number"
                sx={{ marginRight: 1 }}
                variant="outlined"
                value={orderiiNumber}
                onChange={(e) => {
                  setOrderiiNumber(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(
                      saveNoteHistorySearchBody({
                        ...orderHistorySearchBody,
                        orderiiNumber: orderiiNumber,
                      })
                    );
                  }
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <DateRangepicker
              disabled={orderDetailsReload}
              startDate={orderHistorySearchBody.fromReviewAt}
              endDate={orderHistorySearchBody.toReviewAt}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  fromCreatedAt: moment(searchDate[0].startDate).format(
                    "YYYY-MM-DD"
                  ),
                  toCreatedAt: moment(searchDate[0].endDate).format(
                    "YYYY-MM-DD"
                  ),
                };
                if (
                  updatedFilter.fromCreatedAt !==
                    orderHistorySearchBody.fromCreatedAt ||
                  updatedFilter.toCreatedAt !==
                    orderHistorySearchBody.toCreatedAt
                ) {
                  dispatch(
                    saveNoteHistorySearchBody({
                      ...orderHistorySearchBody,
                      fromReviewAt: moment(searchDate[0].startDate).format(
                        "YYYY-MM-DD"
                      ),
                      toReviewAt: moment(searchDate[0].endDate).format(
                        "YYYY-MM-DD"
                      ),
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack direction="row" spacing={2}>
              <IconButton
                disabled={orderDetailsReload}
                onClick={() => {
                  dispatch(saveNoteHistoryPageNo(0));
                  dispatch(
                    saveNoteHistorySearchBody({
                      ...orderHistorySearchBody,
                      cartId: selectedId,
                    })
                  );
                }}
              >
                <Tooltip title="Search with filter">
                  <SearchIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                disabled={orderDetailsReload}
                onClick={() => {
                  dispatch(
                    saveNoteHistorySearchBody({
                      pageSize: 100,
                      fromReviewAt: null,
                      toReviewAt: null,
                      reviewerId: null,
                    })
                  );
                  dispatch(saveNoteHistoryPageNo(0));
                  setOrderiiNumber("");
                  setOrderNumber("");
                  setGiftOrderNumber("");
                }}
              >
                <Tooltip title="Clear filter">
                  <Close />
                </Tooltip>
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box sx={{ height: { md: "calc(100vh - 270px)", xs: 1000 } }}>
                <DataGrid
                  sx={{
                    "& .css-204u17-MuiDataGrid-main": {
                      padding: "15px",
                    },
                    "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                      padding: "15px",
                    },
                  }}
                  columns={columns}
                  rows={orderHistory?.data}
                  loading={orderDetailsReload}
                  disableSelectionOnClick
                  rowHeight={65}
                  pagination
                  paginationMode="server"
                  rowCount={orderHistory.rowCount}
                  pageSize={orderHistorySearchBody.pageSize}
                  page={orderHistoryPageNo}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  onPageSizeChange={(newPageSize) =>
                    dispatch(
                      saveNoteHistorySearchBody({
                        pageSize: newPageSize,
                      })
                    )
                  }
                  rowsPerPageOptions={[100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default NoteHistoryDialog;
