import helper from "src/utils/helper";
import CartDataService from "../api/services/cart.service";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import {
  GET_ALL_CART,
  GET_BY_ID_CART,
  GET_CART_HISTORY,
  OPEN_CART_DIALOG,
  OPEN_CART_HISTORY_DIALOG,
  RELOAD_CART,
  RESET_FORM_CART,
  SAVE_CART_HISTORY_PAGE_NO,
  SAVE_CART_HISTORY_SEARCH_BODY,
  SAVE_SEARCH_BODY_CART,
} from "./type";

export const openCartDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_CART_DIALOG,
    payload: data,
  });
};

export const reloadCart = (data) => (dispatch) => {
  dispatch({
    type: RELOAD_CART,
    payload: data,
  });
};

export const getByIdCart = (data) => (dispatch) => {
  dispatch({
    type: GET_BY_ID_CART,
    payload: data,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: RESET_FORM_CART,
  });
};

export const saveSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SEARCH_BODY_CART,
    payload: data,
  });
};

// ========================= cart history =========================

export const saveCartHistorySearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_CART_HISTORY_SEARCH_BODY,
    payload: data,
  });
};

export const saveCartHistoryPageNo = (data) => (dispatch) => {
  dispatch({
    type: SAVE_CART_HISTORY_PAGE_NO,
    payload: data,
  });
};

export const openCartHistoryDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_CART_HISTORY_DIALOG,
    payload: data,
  });
};

export const getCartHistory = (pageNo, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadCart());
    const res = await CartDataService.getCartHistory(pageNo, searchBody);
    dispatch({
      type: GET_CART_HISTORY,
      payload: res.data.data,
    });
    dispatch(reloadCart());
  } catch (err) {
    dispatch(reloadCart());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getReviewersList = () => async (dispatch) => {
  try {
    const res = await CartDataService.getReviewersList();
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

// ========================= end of cart history =========================

export const updateCart = (data) => async (dispatch) => {
  try {
    const res = await CartDataService.update(data);
    dispatch(showSuccessSnackbar("Updated successfully!"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getCartById = (id) => async (dispatch) => {
  try {
    dispatch(reloadCart());
    const res = await CartDataService.getById(id);
    dispatch({
      type: GET_ALL_CART,
      payload: res.data.data,
    });
    dispatch(reloadCart());
    // return Promise.resolve(res.data);
  } catch (err) {
    dispatch({
      type: GET_ALL_CART,
      payload: [],
    });
    dispatch(reloadCart());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getCartReview = (pageNo, data) => async (dispatch) => {
  try {
    const res = await CartDataService.getReviewByType(pageNo, data);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const completeReview = (cartId) => async (dispatch) => {
  try {
    dispatch(reloadCart());
    const res = await CartDataService.completeReviewById(cartId);
    dispatch(reloadCart());
    dispatch(showSuccessSnackbar("Cart review Completed"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const clearGlobalCart = (cartId) => async (dispatch) => {
  try {
    dispatch(reloadCart());
    const res = await CartDataService.clearGlobalCart(cartId);
    dispatch(reloadCart());
    dispatch(showSuccessSnackbar("Clear Cart Completed"));
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadCart());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteGlobalCartItem = (itemId, cartId) => async (dispatch) => {
  try {
    dispatch(reloadCart());
    const res = await CartDataService.deleteGlobalCartItem(itemId, cartId);
    dispatch(reloadCart());
    dispatch(showSuccessSnackbar("Clear Cart Completed"));
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadCart());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateProhibitedCartItems = (data) => async (dispatch) => {
  try {
    dispatch(reloadCart());
    const res = await CartDataService.updateProhibitedCartItems(data);
    dispatch(reloadCart());
    dispatch(showSuccessSnackbar("Cart Completed"));
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadCart());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
