import { Helmet } from "react-helmet";
import { Box, Chip, Stack } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import RolePermission from "src/components/RolePermission";
import {
  openDialog,
  openLockerHistoryDialog,
  saveLockerHistoryPageNo,
} from "src/actions/locker";
import LockerForm from "../../from/Form";
import { IoMdBasket, IoMdSearch } from "react-icons/io";
import LockerHistoryDialog from "src/pages/cross/lockers/lockerHistory";

const LockerHeader = () => {
  const dispatch = useDispatch();

  const _Locker = useSelector((state) => state.locker.openDialog);
  const lockerHistoryDialog = useSelector(
    (state) => state.locker.lockerHistoryDialog
  );
  const lockerData = useSelector((state) => state.locker.lockerData.data);

  return (
    <>
      <Helmet>
        <title>Warehouse | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-around"
                sx={{ marginRight: "10px" }}
              >
                <Typography variant="h4" component="div">
                  Warehouse
                </Typography>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    fontSize: "25px",
                    width: "100%",
                  }}
                  justifyContent="center"
                >
                  <Chip
                    label={
                      <Typography>All Lockers: {lockerData?.length}</Typography>
                    }
                    size="small"
                    color="warning"
                    variant="outlined"
                  />
                  <Chip
                    label={
                      <Typography>
                        Available Lockers:{" "}
                        {
                          lockerData.filter(
                            (locker) =>
                              locker.isEmpty === true &&
                              locker.isActive === true
                          )?.length
                        }
                      </Typography>
                    }
                    size="small"
                    color="success"
                    variant="outlined"
                  />
                  <Chip
                    label={
                      <Typography>
                        Used Lockers:{" "}
                        {
                          lockerData.filter(
                            (locker) => locker.isEmpty === false
                          )?.length
                        }
                      </Typography>
                    }
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                  <Chip
                    label={
                      <Typography>
                        Inactive Lockers:{" "}
                        {
                          lockerData.filter(
                            (locker) => locker.isActive === false
                          )?.length
                        }
                      </Typography>
                    }
                    size="small"
                    color="error"
                    variant="outlined"
                  />
                </Stack>
              </Stack>
            }
            avatar={<IoMdBasket size={20} />}
            action={
              <Stack direction="row" spacing={2}>
                <RolePermission permission="71-08">
                  <Button
                    variant="contained"
                    color="warning"
                    size="medium"
                    startIcon={<IoMdSearch />}
                    onClick={() => {
                      dispatch(saveLockerHistoryPageNo(0));
                      dispatch(openLockerHistoryDialog());
                    }}
                  >
                    Search Locker History
                  </Button>
                </RolePermission>
                <RolePermission permission="71-01">
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<AddIcon />}
                    onClick={() => dispatch(openDialog("add"))}
                  >
                    Manage Lockers
                  </Button>
                </RolePermission>
              </Stack>
            }
          />
          {_Locker ? <LockerForm /> : null}
          {lockerHistoryDialog ? <LockerHistoryDialog /> : null}
        </Card>
      </Box>
    </>
  );
};

export default LockerHeader;
