import {
  Grid,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { openDeviceInfoDialog } from "src/actions/users";

const DeviceInfoDialog = () => {
  const dispatch = useDispatch();

  const _DeviceInfo = useSelector((state) => state.users.deviceInfo);

  const handleDialog = () => {
    dispatch(openDeviceInfoDialog());
  };

  return (
    <Dialog
      open={_DeviceInfo.dialog}
      keepMounted
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Typography color="primary">Device Information</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">{_DeviceInfo.data ?? ""}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default DeviceInfoDialog;
