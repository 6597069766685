import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  colors,
  useTheme,
} from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const WalletLiabilityDashboard = (props) => {
  const theme = useTheme();

  const color = props.data.segment?.map((status) =>
    status === "0 - 10"
      ? "#ffa726"
      : status === "30 - 50"
      ? "#d32f2f"
      : status === "10 - 30"
      ? "#66bb6a"
      : status === "500 - 1000"
      ? "#d32f2f"
      : status === "50 - 100"
      ? "#01579b"
      : status === "Above 1K"
      ? "#0288d1"
      : status === "100 - 300"
      ? "#9C27B0"
      : status === "300 - 500"
      ? "#42a5f5"
      : null
  );

  const data = {
    datasets: [
      {
        data: props.data.data,
        backgroundColor: color,
        borderWidth: 2,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
      },
    ],
    labels: props.data.labels,
  };

  const options = {
    animation: true,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <Card>
      <CardHeader title="Wallet Liability" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: "relative",
          }}
        >
          <Doughnut data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default WalletLiabilityDashboard;
