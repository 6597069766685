import {
  Box,
  Card,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { allInfo } from "src/actions/reports";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import moment from "moment";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";

export default function AllInfromation(props) {
  const dispatch = new useDispatch();
  const [loading, setLoading] = useState(false);
  const [allInformation, setAllInformation] = useState([]);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    allinfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.date, props.setSearch]);

  function allinfo() {
    setLoading(true);
    dispatch(
      allInfo({
        startDate: props.date.startDate,
        endDate: props.date.endDate,
        forCancelled: props.forCancelled,
      })
    ).then((res) => {
      setAllInformation(res.data ?? []);
      setLoading(false);
    });
  }

  function CustomToolbar() {
    return (
      <Box>
        <Box>
          <Box>
            <Card>
              <CardHeader
                title="All Information Report"
                subheader={
                  <Stack direction="column" spacing={1}>
                    <Typography variant="caption">
                      {props.date.startDate
                        ? props.date.startDate + " - " + props.date.endDate
                        : "No date selected"}
                    </Typography>
                  </Stack>
                }
              />
              <Divider />
            </Card>
          </Box>
        </Box>
        <Box>
          <GridToolbarContainer>
            <GridToolbarExport
              printOptions={{
                disableToolbarButton: true,
                hideFooter: true,
                hideToolbar: true,
              }}
              csvOptions={{
                fileName: `All Information Report || Orderii || ${
                  moment(props.date.startDate).format("YYYY-MM-DD") +
                  " - " +
                  moment(props.date.endDate).format("YYYY-MM-DD")
                }`,
                utf8WithBom: true,
                allColumns: true,
              }}
            />
          </GridToolbarContainer>
        </Box>
      </Box>
    );
  }
  return (
    <Card style={{ marginTop: 10 }}>
      <PerfectScrollbar>
        <Box sx={{ height: "calc(100vh - 320px)" }}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-row ": {
                textAlign: "center",
              },
              "& .css-204u17-MuiDataGrid-main": {
                paddingLeft: "15px",
              },
              "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                paddingLeft: "15px",
              },
            }}
            columns={[
              {
                field: "id",
                width: 80,
                headerName: "#",
              },
              {
                field: "userId",
                width: 300,
                headerName: "User Id",
                renderCell: (params) => params.row.userId ?? "",
              },
              {
                field: "orderNumber",
                width: 200,
                headerName: "Order Number",
              },
              {
                field: "userName",
                width: 150,
                headerName: "User Name",
              },
              {
                field: "fullAddress",
                width: 150,
                headerName: "Address",
              },
              {
                field: "orderDate",
                width: 150,
                headerName: "Order Date",
                valueGetter: (params) =>
                  moment(params.row.orderDate).format("YYYY-MM-DD"),
                renderCell: (params) =>
                  moment(params.row.orderDate).format("YYYY-MM-DD"),
              },
              {
                field: "itemPurchaseDate",
                width: 150,
                headerName: "Purchased Date",
                valueGetter: (params) =>
                  params.row.itemPurchaseDate
                    ? moment(params.row.itemPurchaseDate).format("YYYY-MM-DD")
                    : "",
                renderCell: (params) =>
                  params.row.itemPurchaseDate
                    ? moment(params.row.itemPurchaseDate).format("YYYY-MM-DD")
                    : "",
              },
              {
                field: "orderiiNumber",
                width: 130,
                headerName: "Orderii Number",
              },
              {
                field: "vendorName",
                width: 250,
                headerName: "Vendor Name",
              },
              {
                field: "region",
                width: 80,
                headerName: "Region",
              },
              {
                field: "itemStatusName",
                width: 200,
                headerName: "Item Status Name",
              },
              {
                field: "itemPrice",
                width: 100,
                headerName: "Item Price ($)",
              },
              {
                field: "itemCustomFees",
                width: 160,
                headerName: "Item Custom Fees ($)",
              },
              {
                field: "itemFinalWeight",
                width: 160,
                headerName: "Item Final Weight",
              },
              {
                field: "insuranceFee",
                width: 160,
                headerName: "Insurance Fee ($)",
              },
              {
                field: "orderiiFee",
                width: 160,
                headerName: "Orderii Fee ($)",
              },
              {
                field: "vipShippingFee",
                width: 160,
                headerName: "VIP Shipping Fee($)",
              },
              {
                field: "itemDGFee",
                width: 160,
                headerName: "Item DG Fee ($)",
              },
              {
                field: "itemDGVolume",
                width: 160,
                headerName: "Item DG Volume",
              },
              {
                field: "itemLocalShipping",
                width: 160,
                headerName: "Item Local Shipping ($)",
              },
              {
                field: "itemInternationalShipping",
                width: 230,
                headerName: "Item International Shipping ($)",
              },
              {
                field: "itemSubTotal",
                width: 130,
                headerName: "Item SubTotal ($)",
              },
              {
                field: "itemQuantity",
                width: 130,
                headerName: "Item Quantity",
              },
              {
                field: "itemTotalAmount",
                width: 150,
                headerName: "Item Total Amount ($)",
              },
              {
                field: "promoCodeName",
                width: 160,
                headerName: "Promo Code Name",
              },
              {
                field: "promoCodeLimit",
                width: 160,
                headerName: "Promo Code Limit ($)",
              },
              {
                field: "promoCodePercentage",
                width: 200,
                headerName: "Promo Code Percentage",
              },
              {
                field: "totalValue",
                width: 200,
                headerName: "Total Value From Promo ($)",
                renderCell: (params) =>
                  params.row.totalValue == null
                    ? "No promo code used"
                    : params.row.totalValue === false
                    ? "From shipping only"
                    : params.row.totalValue === true
                    ? "From total value"
                    : "",
                valueGetter: (params) =>
                  params.row.totalValue == null
                    ? "No promo code used"
                    : params.row.totalValue === false
                    ? "From shipping only"
                    : params.row.totalValue === true
                    ? "From total value"
                    : "",
              },
              {
                field: "discountAmount",
                width: 160,
                headerName: "Discount Amount ($)",
              },
              {
                field: "homeDelivery",
                width: 160,
                headerName: "Home Delivery ($)",
              },
              {
                field: "invoiceId",
                width: 300,
                headerName: "Invoice Id",
              },
              {
                field: "regionalTrackingNumber",
                width: 360,
                headerName: "Regional Tracking Number",
              },
              {
                field: "internationalTrackingNumber",
                width: 360,
                headerName: "International Tracking Number",
              },
              {
                field: "realWeight",
                headerName: "Real Weight",
                width: 100,
              },
              {
                field: "invoiceShippingDate",
                headerName: "Invoice Shipping Date",
                width: 150,
                renderCell: (params) =>
                  params.row.invoiceShippingDate
                    ? moment(params.row.invoiceShippingDate).format(
                        "YYYY-MM-DD"
                      )
                    : "",
              },
              {
                field: "shippingCompany",
                headerName: "Shipping Company",
                width: 150,
              },
              {
                field: "shippingInvoiceNumber",
                headerName: "Shipping Invoice Number",
                width: 360,
              },
              {
                field: "orderTotalAmountAtCreationDate",
                headerName: "Order Total Amount At Creation Date",
                width: 150,
              },
            ]}
            rows={allInformation?.map((data, index) => {
              return { ...data, id: index + 1 };
            })}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            pageSize={pageSize}
            loading={loading}
            disableSelectionOnClick
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[25, 75, 100]}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
