import {
  GET_WALLET_AND_BANK_ACCOUNT,
  RELOAD_REPORT,
  SAVE_WALLET_AND_BANK_ACCOUNT_SEARCH_BODY,
  SAVE_WALLET_AND_BANK_ACCOUNT_SAVE_NO,
  SAVE_CUSTOMER_INFO_REPORT_SAVE_NO,
  SAVE_CUSTOMER_INFO_REPORT_SEARCH_BODY,
  GET_CUSTOMER_INFO_REPORT,
  SAVE_ITEMS_PURCHASED_REPORT_SEARCH_BODY,
  SAVE_ITEMS_PURCHASED_REPORT_SAVE_NO,
  GET_ITEMS_PURCHASED_REPORT,
  GET_USER_BEHAVIOR_REPORT,
  SAVE_USER_BEHAVIOR_REPORT_SAVE_NO,
  SAVE_USER_BEHAVIOR_REPORT_SEARCH_BODY,
  GET_PRODUCT_ECONOMICS_REPORT,
  SAVE_PRODUCT_ECONOMICS_REPORT_SAVE_NO,
  SAVE_PRODUCT_ECONOMICS_REPORT_SEARCH_BODY,
  GET_PRODUCT_DURATIONS_REPORT,
  SAVE_PRODUCT_DURATIONS_REPORT_SAVE_NO,
  SAVE_PRODUCT_DURATIONS_REPORT_SEARCH_BODY,
  GET_CART_CREATION_REPORT,
  SAVE_CART_CREATION_REPORT_SAVE_NO,
  SAVE_CART_CREATION_REPORT_SEARCH_BODY,
  GET_TABULATED_INFORMATION_REPORT,
  SAVE_TABULATED_INFORMATION_REPORT_SAVE_NO,
  SAVE_TABULATED_INFORMATION_REPORT_SEARCH_BODY,
} from "../actions/type";

const initialState = {
  reload: false,
  // wallet And Bank Account Report
  walletAndBankAccountReportPageNo: 0,
  walletAndBankAccountReportData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  walletAndBankAccountReportSearchBody: {
    fromCreatedAt: "",
    toCreatedAt: "",
    isExport: "",
    pageSize: 15,
  },
  // Customer Info Report
  customerInfoReportPageNo: 0,
  customerInfoReportData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  customerInfoReportSearchBody: {
    fromCreatedAt: "",
    toCreatedAt: "",
    isExport: "",
    pageSize: 15,
  },
  // Items Purchased Report
  itemsPurchasedReportPageNo: 0,
  itemsPurchasedReportData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  itemsPurchasedReportSearchBody: {
    fromCreatedAt: "",
    toCreatedAt: "",
    isExport: "",
    pageSize: 15,
  },
  // Items Purchased Report
  userBehaviorReportPageNo: 0,
  userBehaviorReportData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  userBehaviorReportSearchBody: {
    fromCreatedAt: "",
    toCreatedAt: "",
    isExport: "",
    pageSize: 15,
  },

  // Product Economics Report
  productEconomicsReportPageNo: 0,
  productEconomicsReportData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  productEconomicsReportSearchBody: {
    fromCreatedAt: "",
    toCreatedAt: "",
    isExport: "",
    pageSize: 15,
  },

  // Product Durations Report
  productDurationsReportPageNo: 0,
  productDurationsReportData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  productDurationsReportSearchBody: {
    fromCreatedAt: "",
    toCreatedAt: "",
    isExport: "",
    pageSize: 15,
  },

  // Product Durations Report
  cartCreationReportPageNo: 0,
  cartCreationReportData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  cartCreationReportSearchBody: {
    fromCreatedAt: "",
    toCreatedAt: "",
    isExport: "",
    pageSize: 15,
  },

  // Tabulated Information Report
  tabulatedInformationReportPageNo: 0,
  tabulatedInformationReportData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  tabulatedInformationReportSearchBody: {
    fromCreatedAt: "",
    toCreatedAt: "",
    isExport: "",
    pageSize: 15,
  },
};

function reportsReducer(reports = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RELOAD_REPORT:
      return { ...reports, reload: !reports.reload };

    case GET_WALLET_AND_BANK_ACCOUNT:
      reports.walletAndBankAccountReportData.data = payload.data;
      reports.walletAndBankAccountReportData.total = payload.pageCount;
      reports.walletAndBankAccountReportData.rowCount = payload.rowCount;
      return reports;

    case SAVE_WALLET_AND_BANK_ACCOUNT_SAVE_NO:
      return { ...reports, walletAndBankAccountReportPageNo: payload };

    case SAVE_WALLET_AND_BANK_ACCOUNT_SEARCH_BODY:
      return { ...reports, walletAndBankAccountReportSearchBody: payload };

    case GET_CUSTOMER_INFO_REPORT:
      reports.customerInfoReportData.data = payload.data;
      reports.customerInfoReportData.total = payload.pageCount;
      reports.customerInfoReportData.rowCount = payload.rowCount;
      return reports;

    case SAVE_CUSTOMER_INFO_REPORT_SAVE_NO:
      return { ...reports, customerInfoReportPageNo: payload };

    case SAVE_CUSTOMER_INFO_REPORT_SEARCH_BODY:
      return { ...reports, customerInfoReportSearchBody: payload };

    case GET_ITEMS_PURCHASED_REPORT:
      reports.itemsPurchasedReportData.data = payload.data;
      reports.itemsPurchasedReportData.total = payload.pageCount;
      reports.itemsPurchasedReportData.rowCount = payload.rowCount;
      return reports;

    case SAVE_ITEMS_PURCHASED_REPORT_SAVE_NO:
      return { ...reports, itemsPurchasedReportPageNo: payload };

    case SAVE_ITEMS_PURCHASED_REPORT_SEARCH_BODY:
      return { ...reports, itemsPurchasedReportSearchBody: payload };

    case GET_PRODUCT_ECONOMICS_REPORT:
      reports.productEconomicsReportData.data = payload.data;
      reports.productEconomicsReportData.total = payload.pageCount;
      reports.productEconomicsReportData.rowCount = payload.rowCount;
      return reports;

    case SAVE_PRODUCT_ECONOMICS_REPORT_SAVE_NO:
      return { ...reports, productEconomicsReportPageNo: payload };

    case SAVE_PRODUCT_ECONOMICS_REPORT_SEARCH_BODY:
      return { ...reports, productEconomicsReportSearchBody: payload };

    case GET_USER_BEHAVIOR_REPORT:
      reports.userBehaviorReportData.data = payload.data;
      reports.userBehaviorReportData.total = payload.pageCount;
      reports.userBehaviorReportData.rowCount = payload.rowCount;
      return reports;

    case SAVE_USER_BEHAVIOR_REPORT_SAVE_NO:
      return { ...reports, userBehaviorReportPageNo: payload };

    case SAVE_USER_BEHAVIOR_REPORT_SEARCH_BODY:
      return { ...reports, userBehaviorReportSearchBody: payload };

    case GET_PRODUCT_DURATIONS_REPORT:
      reports.productDurationsReportData.data = payload.data;
      reports.productDurationsReportData.total = payload.pageCount;
      reports.productDurationsReportData.rowCount = payload.rowCount;
      return reports;

    case SAVE_PRODUCT_DURATIONS_REPORT_SAVE_NO:
      return { ...reports, productDurationsReportPageNo: payload };

    case SAVE_PRODUCT_DURATIONS_REPORT_SEARCH_BODY:
      return { ...reports, productDurationsReportSearchBody: payload };

    case GET_CART_CREATION_REPORT:
      reports.cartCreationReportData.data = payload.data;
      reports.cartCreationReportData.total = payload.pageCount;
      reports.cartCreationReportData.rowCount = payload.rowCount;
      return reports;

    case SAVE_CART_CREATION_REPORT_SAVE_NO:
      return { ...reports, cartCreationReportPageNo: payload };

    case SAVE_CART_CREATION_REPORT_SEARCH_BODY:
      return { ...reports, cartCreationReportSearchBody: payload };

    case GET_TABULATED_INFORMATION_REPORT:
      reports.tabulatedInformationReportData.data = payload.data;
      reports.tabulatedInformationReportData.total = payload.pageCount;
      reports.tabulatedInformationReportData.rowCount = payload.rowCount;
      return reports;

    case SAVE_TABULATED_INFORMATION_REPORT_SAVE_NO:
      return { ...reports, tabulatedInformationReportPageNo: payload };

    case SAVE_TABULATED_INFORMATION_REPORT_SEARCH_BODY:
      return { ...reports, tabulatedInformationReportSearchBody: payload };

    default:
      return reports;
  }
}

export default reportsReducer;
