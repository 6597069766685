import axios from "../axios";

class ReportsService {
  newCustomerPerDay(data) {
    return axios.post(`/report/newcustomerperday`, data);
  }

  totalRevenuPromoVendor(id) {
    return axios.post(`report/totalrevenuepromovendor?promoCodeId=${id}`);
  }

  getUnsedPromo(id) {
    return axios.post(`report/getunusedpromo?promoCodeId=${id}`);
  }

  totalDiscountPerDay(data) {
    return axios.post(`report/totaldiscountperday`, data);
  }

  allInfo(data) {
    return axios.post(`report/allinfo`, data);
  }

  regionPromoVendorRevenue(data) {
    return axios.post(`report/regionpromovendorrevenue`, data);
  }

  cac(id) {
    return axios.post(`report/cac`, id);
  }

  getTopCustomersByOrderCount(filter) {
    let url = `/report/topcustomersbyordercount`;
    var filterLink = "";
    filterLink += filter.count ? `?count=${filter.count}` : "?";

    return axios.get(url + filterLink);
  }

  getTopCustomersByOrderValue(filter) {
    let url = `/report/topcustomersbyordervalue`;
    var filterLink = "";
    filterLink += filter.count ? `?count=${filter.count}` : "?";

    return axios.get(url + filterLink);
  }

  getTopTenVendorsByClickCount(filter) {
    let url = `/report/topvendorsbyclickcount`;
    var filterLink = "";
    filterLink += filter.region ? `?region=${filter.region}` : "?";
    filterLink += filter.count ? `&count=${filter.count}` : "";

    return axios.get(url + filterLink);
  }

  getTopTenVendorsByOrderCount(filter) {
    let url = `/report/topvendorsbyordercount`;
    var filterLink = "";
    filterLink += filter.region ? `?region=${filter.region}` : "?";
    filterLink += filter.count ? `&count=${filter.count}` : "";

    return axios.get(url + filterLink);
  }

  transactions(filter) {
    let url = `/report/transactions`;
    var filterLink = "";
    filterLink += filter.from ? `?from=${filter.from}` : "?";
    filterLink += filter.to ? `&to=${filter.to}` : "";

    return axios.get(url + filterLink);
  }

  userBalace() {
    return axios.get(`report/userbalances`);
  }

  topup(filter) {
    let url = `/report/topupreport`;
    var filterLink = "";
    filterLink += filter.from ? `?from=${filter.from}` : "?";
    filterLink += filter.to ? `&to=${filter.to}` : "";

    return axios.get(url + filterLink);
  }

  walletLiability() {
    return axios.get(`report/walletliabilityreport`);
  }

  income(filter) {
    let url = `/report/incomereport`;
    var filterLink = "";
    filterLink += filter.from ? `?from=${filter.from}` : "?";
    filterLink += filter.to ? `&to=${filter.to}` : "";
    filterLink += `&forCancelled=${filter.forCancelled}`;

    return axios.get(url + filterLink);
  }

  walletSnapshot(filter) {
    let url = `/report/walletsnapshot`;
    var filterLink = "";
    filterLink += filter.to ? `?to=${filter.to}` : "";

    return axios.get(url + filterLink);
  }

  agentTopUp(filter) {
    let url = `/report/agenttopupreport`;
    var filterLink = "";
    filterLink += filter.from ? `?from=${filter.from}` : "";
    filterLink += filter.to ? `&to=${filter.to}` : "";

    return axios.get(url + filterLink);
  }

  giftAllInfo(data) {
    return axios.post(`report/allgiftinfo`, data);
  }

  allStockInfo() {
    return axios.get(`report/allstockinfo`);
  }

  walletsAndBanksAccount(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/report/walletsandbankaccounts/${pageNo}?${params}`);
  }

  walletsAndBanksAccountExport(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/report/walletsandbankaccounts/${pageNo}?${params}`);
  }

  customerInfoReport(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/report/customerinfo/${pageNo}?${params}`);
  }

  customerInfoReportExport(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/report/customerinfo/${pageNo}?${params}`);
  }

  itemsPurchased(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/report/itemspurchased/${pageNo}?${params}`);
  }

  itemsPurchasedExport(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/report/itemspurchased/${pageNo}?${params}`);
  }

  userBehavior(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/report/userbehavior/${pageNo}?${params}`);
  }

  userBehaviorExport(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/report/userbehavior/${pageNo}?${params}`);
  }

  productEconomics(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/report/productEconomicsData/${pageNo}?${params}`);
  }

  productEconomicsExport(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/report/productEconomicsData/${pageNo}?${params}`);
  }

  productDurations(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/report/productdurations/${pageNo}?${params}`);
  }

  productDurationsExport(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/report/productdurations/${pageNo}?${params}`);
  }

  cartCreation(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/report/cartCreations/${pageNo}?${params}`);
  }

  cartCreationExport(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/report/cartCreations/${pageNo}?${params}`);
  }

  tabulatedInformation(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/report/tabulatedInformation/${pageNo}?${params}`);
  }

  tabulatedInformationExport(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/report/tabulatedInformation/${pageNo}?${params}`);
  }
}

export default new ReportsService();
