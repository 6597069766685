import {
  DYNAMIC_AUTH_RESET_FORM,
  DYNAMIC_AUTH_GET_BY_ID,
  DYNAMIC_AUTH_PAGE_NUMBER,
  DYNAMIC_AUTH_OPEN_DIALOG,
  DYNAMIC_AUTH_RELOAD,
  DYNAMIC_AUTH_GET_ALL_LIST,
  DYNAMIC_AUTH_GET_ALL_ROLE,
  DYNAMIC_AUTH_GET_ALL_ORDERII_END_POINT,
  DYNAMIC_AUTH_GET_ALL_ROLE_END_POINT,
  DYNAMIC_AUTH_GET_ALL_STAFF_USERS,
  DYNAMIC_AUTH_SEARCH_BODY,
  DYNAMIC_AUTH_OPEN_STAFF_USERS_DIALOG,
  DYNAMIC_AUTH_RESET_STAFF_USERS_FORM,
  DYNAMIC_AUTH_GET_BY_ID_STAFF_USERS,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  openStaffUsersDialog: false,
  pageNo: 1,
  pageSize: 10,
  staffUsersSearchBody: {
    search: "",
    pageNo: 1,
    pageSize: 100,
  },
  staffUsersForm: {
    userId: null,
    roleIds: [],
  },
  roleForm: {
    id: null,
    name: "",
    roleOEndpoints: [],
  },
  roleEndPointForm: {
    id: null,
    roleId: null,
    oEndpointsIds: [],
  },
  dynamicAuthRoleData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  dynamicAuthRoleEndPointData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  dynamicAuthOrderiiEndPointsData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  dynamicAuthStaffUsersData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  dynamicAuthDataList: [],
  getById: {},
};

function dynamicAuthReducer(dynamicAuth = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case DYNAMIC_AUTH_GET_ALL_ROLE:
      dynamicAuth.dynamicAuthRoleData.data = payload.data;
      return dynamicAuth;

    case DYNAMIC_AUTH_GET_ALL_ROLE_END_POINT:
      dynamicAuth.dynamicAuthRoleEndPointData.data = payload.data;
      dynamicAuth.dynamicAuthRoleEndPointData.total = payload.pageCount;
      dynamicAuth.dynamicAuthRoleEndPointData.rowCount = payload.rowCount;
      return dynamicAuth;

    case DYNAMIC_AUTH_GET_ALL_ORDERII_END_POINT:
      dynamicAuth.dynamicAuthOrderiiEndPointsData.data = payload.data;
      dynamicAuth.dynamicAuthOrderiiEndPointsData.total = payload.pageCount;
      dynamicAuth.dynamicAuthOrderiiEndPointsData.rowCount = payload.rowCount;
      return dynamicAuth;

    case DYNAMIC_AUTH_GET_ALL_STAFF_USERS:
      dynamicAuth.dynamicAuthStaffUsersData.data = payload.data;
      dynamicAuth.dynamicAuthStaffUsersData.total = payload.pageCount;
      dynamicAuth.dynamicAuthStaffUsersData.rowCount = payload.rowCount;
      return dynamicAuth;

    case DYNAMIC_AUTH_GET_ALL_LIST:
      dynamicAuth.dynamicAuthDataList = payload;
      return dynamicAuth;

    case DYNAMIC_AUTH_GET_BY_ID:
      dynamicAuth.roleForm = {
        ...payload,
        roleOEndpoints: payload.roleOEndpoints.map((data) => data.oEndpointId),
        userRoles: payload.roleOEndpoints,
      };
      return dynamicAuth;

    case DYNAMIC_AUTH_RESET_FORM:
      dynamicAuth.roleForm.id = null;
      dynamicAuth.roleForm.name = "";
      dynamicAuth.roleForm.roleOEndpoints = [];
      return dynamicAuth;

    case DYNAMIC_AUTH_GET_BY_ID_STAFF_USERS:
      return {
        ...dynamicAuth,
        staffUsersForm: {
          ...payload,
          userId: payload.id,
          roles: payload.roles.map((x) => x.id) || [],
        },
      };

    case DYNAMIC_AUTH_RESET_STAFF_USERS_FORM:
      dynamicAuth.staffUsersForm.userId = null;
      dynamicAuth.staffUsersForm.roleIds = [];
      return dynamicAuth;

    case DYNAMIC_AUTH_PAGE_NUMBER:
      return { ...dynamicAuth, pageNo: payload };

    case DYNAMIC_AUTH_SEARCH_BODY:
      return { ...dynamicAuth, staffUsersSearchBody: payload };

    case DYNAMIC_AUTH_OPEN_DIALOG:
      return {
        ...dynamicAuth,
        openDialog: !dynamicAuth.openDialog,
      };

    case DYNAMIC_AUTH_OPEN_STAFF_USERS_DIALOG:
      return {
        ...dynamicAuth,
        openStaffUsersDialog: !dynamicAuth.openStaffUsersDialog,
      };

    case DYNAMIC_AUTH_RELOAD:
      return { ...dynamicAuth, reload: !dynamicAuth.reload };

    default:
      return dynamicAuth;
  }
}

export default dynamicAuthReducer;
