import {
  Card,
  CardHeader,
  Divider,
  Stack,
  Typography,
  Box,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTabulatedInformationReport,
  saveTabulatedInformationReportPageNo,
  saveTabulatedInformationReportSearchBody,
  tabulatedInformationReportExport,
} from "src/actions/reports";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import moment from "moment";

export default function TabulatedInformation(props) {
  const dispatch = new useDispatch();

  const {
    tabulatedInformationReportData,
    tabulatedInformationReportSearchBody,
    tabulatedInformationReportPageNo,
    reload,
  } = useSelector((state) => state.reports);

  useEffect(() => {
    dispatch(
      getTabulatedInformationReport(tabulatedInformationReportPageNo + 1, {
        pageSize: tabulatedInformationReportSearchBody.pageSize,
        year: props.selectedYear,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.selectedYear,
    tabulatedInformationReportPageNo,
    tabulatedInformationReportSearchBody,
  ]);

  const handlePageChange = (newPage) => {
    dispatch(saveTabulatedInformationReportPageNo(newPage));
  };

  const handleLimitChange = (newPageSize) => {
    dispatch(
      saveTabulatedInformationReportSearchBody({
        ...tabulatedInformationReportSearchBody,
        pageSize: newPageSize,
      })
    );
  };

  const columns = [
    {
      field: "sequence",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "id",
      headerName: "User Id",
      width: 300,
      renderCell: (params) => params.row.user?.id,
    },
    {
      field: "name",
      headerName: "User Name",
      width: 200,
      renderCell: (params) => params.row.user?.name,
    },
    {
      field: "address",
      headerName: "Customer Address",
      width: 200,
      renderCell: (params) =>
        params.row?.provinceName + ", " + params.row?.cityName,
    },
    {
      field: "age",
      headerName: "Age",
      width: 200,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "Date Of Account Creation",
      width: 200,
      renderCell: (params) =>
        moment(params.row?.createdAt).format("YYYY-MM-DD"),
    },
    {
      field: "firstOrderAt",
      headerName: "Date Of First Order",
      width: 200,
      renderCell: (params) =>
        params.row?.firstOrderAt
          ? moment(params.row?.firstOrderAt).format("YYYY-MM-DD")
          : "No Order",
    },
    {
      field: "orderCount",
      headerName: "Order Count",
      width: 200,
    },
    {
      field: "transactionCount",
      headerName: "Transaction Count",
      width: 200,
    },
    {
      field: "gmv",
      headerName: "GMV",
      width: 200,
      renderCell: (params) => params.row.gmv.toLocaleString(),
    },
  ];

  function CustomToolbar() {
    return (
      <div>
        <Box>
          <Box>
            <Card>
              <CardHeader
                title="Tabulated Information Report"
                subheader={
                  <Stack direction="column" spacing={1}>
                    <Typography variant="caption">
                      {props.selectedYear}
                    </Typography>
                  </Stack>
                }
              />
              <Divider />
            </Card>
          </Box>
          <Box>
            <GridToolbarContainer>
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(
                    tabulatedInformationReportExport(
                      tabulatedInformationReportPageNo + 1,
                      {
                        year: props.selectedYear,
                        isExport: true,
                      }
                    )
                  );
                }}
              >
                Export
              </Button>
            </GridToolbarContainer>
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <Card style={{ marginTop: 10 }} ref={props.print}>
      <PerfectScrollbar>
        <Box sx={{ height: "calc(100vh - 320px)" }}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-row ": {
                textAlign: "center",
              },
              "& .css-204u17-MuiDataGrid-main": {
                paddingLeft: "15px",
              },
              "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                paddingLeft: "15px",
              },
            }}
            columns={columns}
            loading={reload}
            rows={tabulatedInformationReportData?.data.map((row, index) => {
              return { ...row, sequence: index + 1 };
            })}
            pagination
            pageSize={tabulatedInformationReportSearchBody.pageSize}
            page={tabulatedInformationReportPageNo}
            onPageChange={(newPage) => handlePageChange(newPage)}
            onPageSizeChange={(newPageSize) => handleLimitChange(newPageSize)}
            rowCount={tabulatedInformationReportData.rowCount}
            getRowId={(params) => params.sequence}
            paginationMode="server"
            rowsPerPageOptions={[15, 25, 75, 100]}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            disableSelectionOnClick
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
