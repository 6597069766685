import {
  Card,
  CardHeader,
  Divider,
  Stack,
  Typography,
  Box,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductDurationsReport,
  ProductDurationsReportExport,
  saveProductDurationsReportPageNo,
  saveProductDurationsReportSearchBody,
} from "src/actions/reports";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";

export default function ProductsDurations(props) {
  const dispatch = new useDispatch();

  const {
    productDurationsReportData,
    productDurationsReportSearchBody,
    productDurationsReportPageNo,
    reload,
  } = useSelector((state) => state.reports);

  useEffect(() => {
    dispatch(
      getProductDurationsReport(productDurationsReportPageNo + 1, {
        pageSize: productDurationsReportSearchBody.pageSize,
        year: props.selectedYear,
        isCanceled: props.forCancelled,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.forCancelled,
    props.selectedYear,
    productDurationsReportPageNo,
    productDurationsReportSearchBody,
  ]);

  const handlePageChange = (newPage) => {
    dispatch(saveProductDurationsReportPageNo(newPage));
  };

  const handleLimitChange = (newPageSize) => {
    dispatch(
      saveProductDurationsReportSearchBody({
        ...productDurationsReportSearchBody,
        pageSize: newPageSize,
      })
    );
  };

  const columns = [
    {
      field: "sequence",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "userId",
      headerName: "User Id",
      width: 300,
      renderCell: (params) => params.row.user?.id,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 200,
      renderCell: (params) => params.row.user?.name,
    },
    {
      field: "orderiiNumber",
      headerName: "Orderii Number",
      width: 200,
    },
    {
      field: "orderId",
      headerName: "Order Id",
      width: 300,
    },
    {
      field: "orderNumber",
      headerName: "Order Number",
      width: 200,
    },
    {
      field: "delivery",
      headerName: "Delivery Location",
      width: 200,
      renderCell: (params) =>
        params.row.province?.nameUS
          ? params.row.province?.nameUS + " - " + params.row.city?.nameUS
          : "",
    },
    {
      field: "regionLabel",
      headerName: "Region Label",
      width: 200,
    },
    {
      field: "vendorName",
      headerName: "Vendor Name",
      width: 200,
    },

    {
      field: "durationPurchaseTill3PLWarehouse",
      headerName: "Duration From Purchase Till 3PLWarehouse",
      width: 320,
    },
    {
      field: "durationRegionWarehouseTillShippedToIraq",
      headerName: "Duration From 3PLWarehouse Till Shipped To Iraq",
      width: 320,
    },
    {
      field: "durationShippedToIraqTillIraqCustoms",
      headerName: "Duration From Shipped To Iraq Till Iraq Customs",
      width: 320,
    },
    {
      field: "durationIraqiPortTillOrderiiWarehouse",
      headerName: "Duration From Iraqi Port Till Orderii Warehouse",
      width: 320,
    },
    {
      field: "durationOrderiiWarehouseTillCustomer",
      headerName: "Duration From Orderii Warehouse Till Customer",
      width: 320,
    },
  ];

  function CustomToolbar() {
    return (
      <div>
        <Box>
          <Box>
            <Card>
              <CardHeader
                title="Product Durations Report"
                subheader={
                  <Stack direction="column" spacing={1}>
                    <Typography variant="caption">
                      {props.selectedYear}
                    </Typography>
                  </Stack>
                }
              />
              <Divider />
            </Card>
          </Box>
          <Box>
            <GridToolbarContainer>
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(
                    ProductDurationsReportExport(
                      productDurationsReportPageNo + 1,
                      {
                        year: props.selectedYear,
                        isExport: true,
                        isCanceled: props.forCancelled,
                      }
                    )
                  );
                }}
              >
                Export
              </Button>
            </GridToolbarContainer>
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <Card style={{ marginTop: 10 }} ref={props.print}>
      <PerfectScrollbar>
        <Box sx={{ height: "calc(100vh - 320px)" }}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-row ": {
                textAlign: "center",
              },
              "& .css-204u17-MuiDataGrid-main": {
                paddingLeft: "15px",
              },
              "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                paddingLeft: "15px",
              },
            }}
            columns={columns}
            loading={reload}
            rows={productDurationsReportData?.data.map((row, index) => {
              return { ...row, sequence: index + 1 };
            })}
            pagination
            pageSize={productDurationsReportSearchBody.pageSize}
            page={productDurationsReportPageNo}
            onPageChange={(newPage) => handlePageChange(newPage)}
            onPageSizeChange={(newPageSize) => handleLimitChange(newPageSize)}
            rowCount={productDurationsReportData.rowCount}
            getRowId={(params) => params.sequence}
            paginationMode="server"
            rowsPerPageOptions={[15, 25, 75, 100]}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            disableSelectionOnClick
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
