import {
  Card,
  CardHeader,
  Divider,
  Stack,
  Typography,
  Box,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductEconomicsReport,
  ProductEconomicsReportExport,
  saveProductEconomicsReportPageNo,
  saveProductEconomicsReportSearchBody,
} from "src/actions/reports";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import moment from "moment";

export default function ProductsEconomics(props) {
  const dispatch = new useDispatch();

  const {
    productEconomicsReportData,
    productEconomicsReportSearchBody,
    productEconomicsReportPageNo,
    reload,
  } = useSelector((state) => state.reports);

  useEffect(() => {
    dispatch(
      getProductEconomicsReport(productEconomicsReportPageNo + 1, {
        pageSize: productEconomicsReportSearchBody.pageSize,
        year: props.selectedYear,
        isCanceled: props.forCancelled,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.selectedYear,
    props.forCancelled,
    productEconomicsReportPageNo,
    productEconomicsReportSearchBody,
  ]);

  const handlePageChange = (newPage) => {
    dispatch(saveProductEconomicsReportPageNo(newPage));
  };

  const handleLimitChange = (newPageSize) => {
    dispatch(
      saveProductEconomicsReportSearchBody({
        ...productEconomicsReportSearchBody,
        pageSize: newPageSize,
      })
    );
  };

  const columns = [
    {
      field: "sequence",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "orderiiNumber",
      headerName: "Orderii Number",
      width: 200,
    },
    {
      field: "orderNumber",
      headerName: "Order Number",
      width: 200,
    },
    {
      field: "userId",
      headerName: "User ID",
      width: 300,
    },
    {
      field: "delivery",
      headerName: "Delivery Location",
      width: 200,
      renderCell: (params) =>
        params.row.province?.nameUS
          ? params.row.province?.nameUS + " - " + params.row.city?.nameUS
          : "",
    },
    {
      field: "regionLabel",
      headerName: "Region Label",
      width: 200,
    },
    {
      field: "vendorName",
      headerName: "Vendor Name",
      width: 200,
    },
    {
      field: "cartLastModifiedAt",
      headerName: "Cart Last Modified At",
      width: 200,
      renderCell: (params) =>
        params.row.cartLastModifiedAt
          ? moment(params.row.cartLastModifiedAt).format("YYYY-MM-DD / hh:mm a")
          : "",
    },
    {
      field: "orderCreationDate",
      headerName: "Order Creation Date",
      width: 200,
      renderCell: (params) =>
        params.row.orderCreationDate
          ? moment(params.row.orderCreationDate).format("YYYY-MM-DD / hh:mm a")
          : "",
    },
    {
      field: "size",
      headerName: "Size",
      width: 200,
    },
    {
      field: "color",
      headerName: "Color",
      width: 200,
    },
    {
      field: "style",
      headerName: "Style",
      width: 200,
    },
    {
      field: "weight",
      headerName: "Weight",
      width: 200,
    },
    {
      field: "weightUnit",
      headerName: "Weight Unit",
      width: 200,
    },
    {
      field: "dimensions",
      headerName: "Dimensions",
      width: 200,
    },
    {
      field: "dimensionsUnit",
      headerName: "Dimensions Unit",
      width: 200,
    },
    {
      field: "volume",
      headerName: "Volume",
      width: 200,
    },
    {
      field: "isDangerousGoods",
      headerName: "Dangerous Goods",
      width: 200,
    },
    // {
    //   field: "durationFromPurchaseTill3PLWarehouse",
    //   headerName: "Duration From Purchase Till 3PLWarehouse",
    //   width: 320,
    // },
    // {
    //   field: "durationFrom3PLWarehouseTillIraqiPort",
    //   headerName: "Duration From 3PLWarehouse Till Iraq Customs",
    //   width: 320,
    // },
    // {
    //   field: "durationFromIraqiPortTillOrderiiWarehouse",
    //   headerName: "Duration From Iraqi Port Till Orderii Warehouse",
    //   width: 320,
    // },
    // {
    //   field: "durationFromOrderiiWarehouseTillCustomer",
    //   headerName: "Duration From Orderii Warehouse Till Customer",
    //   width: 320,
    // },
    {
      field: "productProcurementCost",
      headerName: "Product Procurement Cost",
      width: 200,
    },
    {
      field: "productSellingPrice",
      headerName: "Product Selling Price",
      width: 200,
    },
    {
      field: "shippingCost",
      headerName: "Selling Shipping Cost",
      width: 200,
    },
    {
      field: "internationalShippingCost",
      headerName: "Selling International Shipping Cost",
      width: 200,
    },
    {
      field: "customsSellingPrice",
      headerName: "Customs Selling Price",
      width: 200,
    },
    {
      field: "orderAdditionalFees",
      headerName: "Order Additional Fees",
      width: 200,
    },
    {
      field: "insuranceFeesSellingPrice",
      headerName: "Insurance Fees  Selling Price",
      width: 200,
    },
    {
      field: "lastMileDeliverySellingPrice",
      headerName: "Last Mile Delivery Selling Price",
      width: 250,
    },
    {
      field: "discountPromoCode",
      headerName: "Promo Code",
      width: 200,
    },
    {
      field: "discountAmountOnOrder",
      headerName: "Discount Amount On Order",
      width: 200,
    },
    {
      field: "deliveryCost",
      headerName: "Delivery Cost",
      width: 200,
    },
  ];

  function CustomToolbar() {
    return (
      <div>
        <Box>
          <Box>
            <Card>
              <CardHeader
                title="Product Economics Report"
                subheader={
                  <Stack direction="column" spacing={1}>
                    <Typography variant="caption">
                      {props.selectedYear}
                    </Typography>
                  </Stack>
                }
              />
              <Divider />
            </Card>
          </Box>
          <Box>
            <GridToolbarContainer>
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(
                    ProductEconomicsReportExport(
                      productEconomicsReportPageNo + 1,
                      {
                        year: props.selectedYear,
                        isExport: true,
                        isCanceled: props.forCancelled,
                      }
                    )
                  );
                }}
              >
                Export
              </Button>
            </GridToolbarContainer>
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <Card style={{ marginTop: 10 }} ref={props.print}>
      <PerfectScrollbar>
        <Box sx={{ height: "calc(100vh - 320px)" }}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-row ": {
                textAlign: "center",
              },
              "& .css-204u17-MuiDataGrid-main": {
                paddingLeft: "15px",
              },
              "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                paddingLeft: "15px",
              },
            }}
            columns={columns}
            loading={reload}
            rows={productEconomicsReportData?.data.map((row, index) => {
              return { ...row, sequence: index + 1 };
            })}
            pagination
            pageSize={productEconomicsReportSearchBody.pageSize}
            page={productEconomicsReportPageNo}
            onPageChange={(newPage) => handlePageChange(newPage)}
            onPageSizeChange={(newPageSize) => handleLimitChange(newPageSize)}
            rowCount={productEconomicsReportData.rowCount}
            getRowId={(params) => params.sequence}
            paginationMode="server"
            rowsPerPageOptions={[15, 25, 75, 100]}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            disableSelectionOnClick
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
