import { combineReducers } from "redux";
import users from "./users";
import auth from "./auth";
import vendors from "./vendors";
import orders from "./orders";
import orderItems from "./orderItems";
import promos from "./promos";
import addresses from "./addresses";
import banners from "./banners";
import orderReasons from "./orderReasons";
import itemReasons from "./itemReasons";
import shippingConfig from "./shippingConfig";
import expense from "./expense";
import expenseTypes from "./expenseTypes";
import mobileAppVersions from "./mobileAppVersion";
import snackbarMessages from "./snackbarMessages";
import inventory from "./inventory";
import tickets from "./tickets";
import ticketCategory from "./TicketCategory";
import ticketComment from "./ticketComment";
import ticketStatistic from "./ticketStatistic";
import department from "./department/department";
import employee from "./department/emplyee";
import departmentEmployee from "./department/departmentEmployee";
import shareCenter from "./UploadImageCenter";
import externalItem from "./externalItem";
import agentRequest from "./agentRequest";
import generalSetting from "./generalSetting";
import cart from "./cart";
import epayments from "./Epayments";
import referralCode from "./referralCode";
import userDeactivation from "./reasons/usersDeactivation";
import orderCancelReason from "./reasons/orderReasons";
import itemCancelReason from "./reasons/itemReasons";
import popUpPost from "./popUpPost";
import agentCityConfig from "./agentCityConfig";
import dynamicAuth from "./dynamicAuth";
import vendorsCategory from "./vendorsCategory";
import uploadAttachments from "./uploadAttachments";
import promotedItems from "./promotedItems";
import ratingQuestions from "./ratingQuestions";
import contactInfo from "./contactInfo";
import deals from "./deals";
import homeItems from "./homeItems";
import giftItemCategory from "./gifts/itemCategory";
import itemOptions from "./gifts/itemOptions";
import giftItems from "./gifts/items";
import variant from "./gifts/variant";
import wrapping from "./gifts/wrapping";
import giftOrder from "./gifts/order";
import itemSupplier from "./gifts/supplier";
import giftItemBrand from "./gifts/brand";
import events from "./gifts/events";
import userAddresses from "./gifts/userAddresses";
import favorite from "./gifts/favorite";
import faq from "./faq";
import giftCart from "./gifts/cart";
import notification from "./notifications";
import onboarding from "./onboarding";
import uncompletedCarts from "./uncompletedCarts";
import additionalFees from "./orderAdditionalFees";
import shipments from "./shipments";
import giftDashboard from "./gifts/dashboard";
import purchasingBill from "./purchasingBill";
import giftCollections from "./gifts/collections";
import shippingOption from "./shippingOptions";
import categoryBrands from "./gifts/categoryBrands";
import goIp from "./goip";
import unknownItems from "./unknownItems";
import prohibitedItems from "./reasons/prohibitedItems";
import scheduledActions from "./scheduledAction";
import locker from "./locker";
import reports from "./reports";

export default combineReducers({
  users,
  auth,
  vendors,
  orders,
  orderItems,
  promos,
  addresses,
  banners,
  orderReasons,
  itemReasons,
  shippingConfig,
  expense,
  expenseTypes,
  mobileAppVersions,
  snackbarMessages,
  inventory,
  tickets,
  ticketCategory,
  ticketComment,
  department,
  employee,
  departmentEmployee,
  ticketStatistic,
  shareCenter,
  externalItem,
  agentRequest,
  generalSetting,
  cart,
  epayments,
  referralCode,
  userDeactivation,
  orderCancelReason,
  itemCancelReason,
  popUpPost,
  agentCityConfig,
  dynamicAuth,
  vendorsCategory,
  uploadAttachments,
  promotedItems,
  deals,
  homeItems,
  ratingQuestions,
  contactInfo,
  faq,
  notification,
  onboarding,
  uncompletedCarts,
  additionalFees,
  shipments,
  purchasingBill,
  shippingOption,
  goIp,
  unknownItems,
  prohibitedItems,
  scheduledActions,
  locker,
  reports,
  //--------------------------------- gifts reducers---------------------------------------------
  giftItemCategory,
  itemOptions,
  giftItems,
  variant,
  wrapping,
  giftOrder,
  itemSupplier,
  giftItemBrand,
  events,
  userAddresses,
  favorite,
  giftCart,
  giftDashboard,
  giftCollections,
  categoryBrands,
});
