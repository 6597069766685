import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  Icon,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import OrdersSearchFilter from "./serachFilter";
import { getAllOrders, savePageNum, saveSearchBody } from "src/actions/orders";
import CancelDialog from "src/pages/cross/orders/CancelDialog/CancelDialog";
import TimelineDialog from "src/pages/cross/orders/timeline/OrderTimeline";
import OrderStatusMenu from "src/pages/cross/orders/menu/OrderStatus";
import { FaRegCopy } from "react-icons/fa";
import useCanShow from "src/components/CanShow";
import { BsTelegram } from "react-icons/bs";
import UserTierMenu from "src/pages/cross/Users/userTierMenu";

export default function OrdersTable() {
  const dispatch = useDispatch();

  const orderDetailsRole = useCanShow("22-03");
  const OrderTimelineRole = useCanShow("22-12");

  const savePageNO = useSelector((state) => state.orders.savePageNum);
  const _UserInfo = useSelector((state) => state.users.userInfo);

  let navigate = useNavigate();

  const [page, setPage] = useState(savePageNO ? savePageNO : 0);
  const [openTimelineDialog, setOpenTimelineDialog] = useState(false);
  const [openCancelReasonsDialog, setOpenCancelReasonsDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [reload, setReload] = useState(false);

  const initialOrderDetials = {
    id: null,
    number: "",
    destination: "",
    quantity: "",
    status: "",
    toBeDelivered: "",
    totalAmount: "",
    createdAt: "",
  };
  const [order, setOrder] = useState(initialOrderDetials);

  const orders = useSelector((state) => state.orders.data);
  const rowCount = useSelector((state) => state.orders.rowCount);
  const _searchBody = useSelector((state) => state.orders.searchBody);

  useEffect(() => {
    setReload(true);
    dispatch(
      getAllOrders(
        page + 1,
        _searchBody.pageSize,
        _searchBody ? _searchBody : {}
      )
    ).then((res) => {
      setReload(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_searchBody, page, refresh]);

  useEffect(() => {
    dispatch(savePageNum(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    dispatch(saveSearchBody(_searchBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_searchBody]);

  const orderToolbar = () => {
    return (
      <Box>
        <CancelDialog
          open={openCancelReasonsDialog}
          handleClose={() => setOpenCancelReasonsDialog(false)}
          type="order"
          id={order.id}
          onError={(msg) => dispatch(showErrorSnackbar(msg))}
          onSuccess={(msg) => {
            dispatch(showSuccessSnackbar(msg));
            setRefresh(!refresh);
          }}
        />
        <TimelineDialog
          open={openTimelineDialog}
          handleClose={() => setOpenTimelineDialog(false)}
          orderId={order.id}
        />
        <OrdersSearchFilter
          reload={reload}
          setPage={(newPage) => setPage(newPage)}
        />
      </Box>
    );
  };

  const columns = [
    {
      field: "sequence",
      headerName: "#",
      headerAlign: "center",
      width: 5,
    },
    {
      field: "number",
      headerName: "Number",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{ cursor: "pointer" }}
          onClick={(e) => {
            navigator.clipboard.writeText(params.row.number);
            dispatch(showSuccessSnackbar("Order Number Copied"));
          }}
        >
          <FaRegCopy />{" "}
          <span style={{ whiteSpace: "break-spaces", color: "#4C5C76" }}>
            {params.row.number}
          </span>
        </Box>
      ),
    },
    {
      field: "customerFullName",
      headerName: "Customer Name",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack justifyContent="center" alignItems="center" spacing={1}>
          <Typography variant="body" component="div" fontWeight="bold">
            {params.row.customerFullName}
          </Typography>
          <UserTierMenu
            userId={params.row?.userId}
            tier={params.row?.customerTier}
            onSuccess={() => setRefresh(!refresh)}
          />
        </Stack>
      ),
    },
    {
      field: "customerPhoneNumber",
      headerName: "Customer Phone",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "userAddress",
      headerName: "Customer Address",
      headerAlign: "center",
      flex: 0.7,
    },
    {
      field: "createdAt",
      headerName: "Creation date",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <>{moment(params.row.createdAt).format("YYYY-MM-DD / hh:mm a")}</>
      ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      headerAlign: "center",
      flex: 0.5,
    },
    {
      field: "totalAmount",
      headerName: "Total ($)",
      headerAlign: "center",
      flex: 0.5,
      renderCell: (params) => <>{params.row.totalAmount} $</>,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      flex: 1.2,
      renderCell: (params) => (
        <Box>
          <OrderStatusMenu
            orderId={params.row.id}
            status={params.row.status}
            onSuccess={() => setRefresh(!refresh)}
            onCancel={() => {
              setOpenCancelReasonsDialog(true);
              setOrder({ ...order, id: params.row.id });
            }}
          />
          {params.row.cancelReasonId ? (
            <IconButton>
              <Tooltip title={params.row.cancelReasonEN} aria-label="cancel">
                <InfoIcon color="error" />
              </Tooltip>
            </IconButton>
          ) : null}
        </Box>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Icon color="primary">visibility</Icon>}
          label="Order Details"
          hidden={orderDetailsRole ? false : true}
          showInMenu
          onClick={(e) => {
            navigate(`/app/order/${params.row.id}`);
          }}
        />,
        <GridActionsCellItem
          icon={<Icon color="warning">show_chart</Icon>}
          label="Order Timeline"
          hidden={OrderTimelineRole ? false : true}
          showInMenu
          onClick={(e) => {
            setOrder({ ...order, id: params.row.id });
            setOpenTimelineDialog(true);
          }}
        />,
        <GridActionsCellItem
          icon={<BsTelegram size={25} color="#229ED9" />}
          label="Chat Via Telegram"
          showInMenu
          onClick={(e) => {
            window.open(
              `https://t.me/+964${params.row.customerPhoneNumber}`,
              "_blank"
            );
          }}
        />,
        <GridActionsCellItem
          icon={<WhatsAppIcon color="success" />}
          label="Chat Via Whatsapp"
          showInMenu
          onClick={(e) => {
            window.open(
              `https://wa.me/+964${params.row.customerPhoneNumber}`,
              "_blank"
            );
          }}
        />,
      ],
    },
  ];

  function CustomToolbar() {
    return (
      _UserInfo.roles.includes("Export") && (
        <GridToolbarContainer>
          <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
        </GridToolbarContainer>
      )
    );
  }

  return (
    <Card>
      {orderToolbar()}
      <PerfectScrollbar>
        <Box>
          <Box sx={{ height: { md: "calc(100vh - 297px)", xs: 1000 } }}>
            <DataGrid
              sx={{
                ".MuiDataGrid-cell": {
                  justifyContent: "center",
                },
              }}
              rows={
                orders?.map((row, index) => {
                  return { ...row, sequence: index + 1 };
                }) || []
              }
              loading={reload}
              columns={columns}
              rowHeight={65}
              disableSelectionOnClick
              pagination
              onPageChange={(newPage) => {
                setPage(newPage);
              }}
              pageSize={_searchBody.pageSize}
              onPageSizeChange={(newPageSize) => {
                dispatch(
                  saveSearchBody({ ..._searchBody, pageSize: newPageSize })
                );
              }}
              page={page}
              rowCount={rowCount ? rowCount : 0}
              paginationMode="server"
              rowsPerPageOptions={[15, 25, 75, 100]}
              components={{
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: CustomNoRowsOverlay,
                Toolbar: CustomToolbar,
              }}
            />
          </Box>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
