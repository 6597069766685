import * as React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import {
  Card,
  IconButton,
  Tooltip,
  Typography,
  LinearProgress,
  Button,
  Chip,
} from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import CustomSpinner from "src/components/CustomSpinner";
import RolePermission from "src/components/RolePermission";
import { CustomDialog } from "src/components/CustomDialog";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { cancelOrderItem, refundOrderItem } from "src/actions/gifts/orders";
import { FaEye } from "react-icons/fa";
import { getProductInfo } from "src/actions/gifts/giftItem";
import ProductInfo from "src/pages/gifts/item/from/productInfo";
import { IoIosInformationCircleOutline } from "react-icons/io";

export default function OrderDetailsTable(props) {
  const dispatch = useDispatch();
  // const orderId = window.location.pathname.replace("/app/gifts/order/", "");

  const order = useSelector((state) => state.giftOrder.form);
  const reload = useSelector((state) => state.giftOrder.reload);
  const giftItemDialog = useSelector(
    (state) => state.giftItems.openProductInfoDialog
  );
  // const [selectedRows, setSelectedRows] = useState([]);
  const [showPanZoom, setShowPanZoom] = useState("");
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [openSupplierDialog, setOpenSupplierDialog] = useState(false);
  const [supplierData, setSupplierData] = useState({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [selectedItemData, setSelectedItemData] = useState({});

  const handleDialogClose = () => {
    setOpenImageDialog(false);
  };

  const handleOpenPanZoom = (url) => {
    setOpenImageDialog(true);
    setShowPanZoom(url);
  };

  const Item = ({ item }) => {
    return (
      <Stack spacing={0} sx={{ width: "400px" }}>
        <Stack
          direction="row"
          // sx={{
          //   lineBreak: "anywhere",
          // }}
        >
          <Box
            onClick={() =>
              handleOpenPanZoom(
                item?.giftItem?.giftItemOptions?.find(
                  (option) => option.attachments?.url
                )?.attachments?.url || item?.giftItem?.giftItemAttachments?.url
              )
            }
            component="img"
            sx={{
              height: "150px",
              width: "150px",
              objectFit: "contain",
            }}
            alt="itemImage"
            src={
              item?.giftItem?.giftItemOptions?.find(
                (option) => option.attachments?.url
              )?.attachments?.url || item?.giftItem?.giftItemAttachments?.url
            }
          />
          <Box
            sx={{
              marginLeft: "10px",
              width: "250px",
              overflowWrap: "break-word",
              wordWrap: "break-word",
            }}
          >
            <Tooltip
              title={
                <Typography variant="h5">{item?.giftItem?.nameEn}</Typography>
              }
              followCursor
            >
              <Typography
                variant="h5"
                style={{ whiteSpace: "normal", my: 1, fontWeight: "bold" }}
              >
                {item?.giftItem?.nameEn}
              </Typography>
            </Tooltip>
            <Tooltip
              title={
                <Typography variant="h5">
                  {item?.giftItem?.descriptionEn}
                </Typography>
              }
              followCursor
            >
              <Typography
                variant="h5"
                sx={{
                  my: 1,
                }}
                noWrap
              >
                <strong>description</strong>: {item?.giftItem?.descriptionEn}
              </Typography>
            </Tooltip>
            <Typography sx={{ my: 0.5 }} color="green">
              Sell Price: {item?.unitPrice?.toLocaleString()} IQD
            </Typography>
            <Typography sx={{ my: 0.5 }} color="blue">
              Cost Price: {item?.costPrice?.toLocaleString()} IQD
            </Typography>
            {item?.giftItem?.giftItemOptions.map((option, index) => (
              <Typography color="green" variant="body1" sx={{ my: 0.5 }}>
                <span key={index}>
                  {option?.options?.nameEn} : {option?.nameEn}{" "}
                  {option?.priceDifference > 0
                    ? `(+ ${option?.priceDifference?.toLocaleString()}) IQD`
                    : ""}
                </span>
              </Typography>
            ))}
          </Box>
        </Stack>
        {item?.isCanceled ? (
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Chip color="error" label={"Canceled"} size="small" />
            {item?.isMoneyRefunded ? (
              <Chip color="success" label={"Refunded"} size="small" />
            ) : (
              <Chip color="error" label={"Not Refunded"} size="small" />
            )}
            {item?.isCanceled & !item?.isMoneyRefunded ? (
              <RolePermission permission="47-15">
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsCancel(false);
                    setOpenConfirmDialog(true);
                    setSelectedItemData(item);
                  }}
                >
                  Refund
                </Button>
              </RolePermission>
            ) : null}
          </Stack>
        ) : null}
      </Stack>
    );
  };

  var columns = [
    {
      field: "sequence",
      headerName: "#",
      headerAlign: "center",
      flex: 0.1,
      sortable: false,
    },
    {
      field: "nameEn",
      headerName: "Name",
      headerAlign: "center",
      width: 400,
      renderCell: (params) => <Item item={params.row} />,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      headerAlign: "center",
      flex: 0.8,
      renderCell: (params) => (
        <Typography
          color={params.row.quantity > 1 ? "#ff0000" : ""}
          sx={{ fontWeight: params.row.quantity > 1 ? "bold" : null }}
        >
          {params.row?.quantity}
        </Typography>
      ),
    },
    {
      field: "sku",
      headerName: "SKU",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <div style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
          {params.row?.sku}
        </div>
      ),
    },
    {
      field: "giftItemCategories",
      headerName: "Categories",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack justifyContent="center" alignItems="center" spacing={1}>
          {params.row.giftItem?.giftItemCategories?.map((category, index) => (
            <Typography noWrap key={index}>
              {category?.nameEn}
            </Typography>
          ))}
        </Stack>
      ),
    },
    {
      field: "giftBrand",
      headerName: "Brand",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack alignItems="center" spacing={1}>
          <Typography variant="body">
            {params.row.giftItem?.giftBrand?.nameEn}
          </Typography>
          <Box
            onClick={() =>
              handleOpenPanZoom(params.row.giftItem?.giftBrand?.attachment?.url)
            }
            component="img"
            src={params.row.giftItem?.giftBrand?.attachment?.url}
            alt=""
            style={{
              cursor: "pointer",
              height: "100px",
              width: "100px",
              padding: "3px",
              objectFit: "contain",
              border: "2px solid",
              borderRadius: "10px",
            }}
          />
        </Stack>
      ),
    },
    {
      field: "giftSupplier",
      headerName: "Supplier",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Button
          onClick={() => {
            setOpenSupplierDialog(true);
            setSupplierData(params.row.giftItem?.giftSupplier);
          }}
        >
          <Typography variant="body">
            {params.row.giftItem?.giftSupplier?.nameEn}
          </Typography>
        </Button>
      ),
    },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      headerAlign: "center",
      flex: 0.8,
      renderCell: (params) => params.row.unitPrice?.toLocaleString(),
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      headerAlign: "center",
      flex: 0.8,
      renderCell: (params) => (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body">
            {params.row.totalPrice?.toLocaleString()}
          </Typography>
          {params.row?.giftItem?.giftItemOptions.length > 0 &&
            params.row?.giftItem?.giftItemOptions.filter(
              (x) => x.priceDifference > 0
            ).length > 0 && (
              <Tooltip
                followCursor
                title={
                  <Typography variant="body1">
                    Item options with price difference
                  </Typography>
                }
              >
                <Typography>
                  <IoIosInformationCircleOutline size={23} color="red" />
                </Typography>
              </Tooltip>
            )}
        </Stack>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack alignItems="center" spacing={1}>
          <Typography variant="body">
            {moment(params.row.createdAt).format("yyyy-MM-DD")}
          </Typography>
          <Typography variant="body">
            {moment(params.row.createdAt).format("HH:mm: a")}
          </Typography>
          {params.row.giftItem?.lockerNumber ? (
            <Typography variant="body" color="error">
              Locker: {params.row.giftItem?.lockerNumber}
            </Typography>
          ) : null}
        </Stack>
      ),
    },
    {
      field: "operations",
      headerName: "Operations",
      headerAlign: "center",
      flex: 0.5,
      renderCell: (params) => (
        <Stack spacing={2}>
          <Tooltip
            title="View Product Details"
            onClick={() => {
              dispatch(getProductInfo(params.row?.giftItem?.id));
            }}
          >
            <IconButton>
              <FaEye />
            </IconButton>
          </Tooltip>
          <RolePermission permission="47-10">
            <IconButton
              color="error"
              disabled={params.row.isCanceled ? true : false}
              aria-label="cancel item"
              onClick={(e) => {
                setIsCancel(true);
                setOpenConfirmDialog(true);
                setSelectedItemData(params.row);
              }}
            >
              <Tooltip title="Cancel item" aria-label="cancel">
                <CloseIcon />
              </Tooltip>
            </IconButton>
          </RolePermission>
        </Stack>
      ),
    },
  ];

  return (
    <Card>
      {giftItemDialog && <ProductInfo />}
      <Box>
        <CustomDialog
          isOpen={openConfirmDialog}
          handleClose={() => {
            setOpenConfirmDialog(false);
          }}
          isActiveDelete
          isRequierdActions
          title="Delete confirmation"
          maxWidth="sm"
          isConfirm
          isConfirmation
          confirmTitle={isCancel ? "Cancel" : "refund"}
          onCancel={() =>
            isCancel
              ? dispatch(cancelOrderItem(selectedItemData?.id, order.id)).then(
                  () => setOpenConfirmDialog(false)
                )
              : dispatch(refundOrderItem(selectedItemData?.id)).then(() =>
                  setOpenConfirmDialog(false)
                )
          }
          selectedObject={`${selectedItemData?.giftItem?.nameEn}`}
        ></CustomDialog>
        <CustomDialog
          isOpen={openImageDialog}
          handleClose={() => handleDialogClose()}
          title="Images"
          maxWidth="lg"
        >
          <div
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <div
              style={{
                position: "relative",
                overflow: "hidden",
                width: "50%",
                height: "auto",
              }}
            >
              <ReactPanZoom image={showPanZoom} alt="Image alt text" />
            </div>
          </div>
        </CustomDialog>
        <CustomDialog
          isOpen={openSupplierDialog}
          handleClose={() => setOpenSupplierDialog(false)}
          title="Supplier Information"
          maxWidth="sm"
        >
          {!supplierData?.isActive ? (
            <Typography color="error" variant="h5" sx={{ my: 1 }}>
              Deactivated
            </Typography>
          ) : null}
          <Typography variant="h5" sx={{ my: 1 }}>
            Name:{" "}
            <span style={{ fontWeight: "bolder" }}>{supplierData?.nameEn}</span>
          </Typography>
          <Typography variant="h5" sx={{ my: 1 }}>
            Phone number:{" "}
            <span style={{ fontWeight: "bolder" }}>
              {" "}
              {supplierData?.phoneNumber}
            </span>
          </Typography>
          <Typography variant="h5" sx={{ my: 1 }}>
            Address:{" "}
            <span style={{ fontWeight: "bolder" }}>
              {" "}
              {supplierData?.province?.nameEn}-{supplierData?.city?.nameEn}
            </span>
          </Typography>
          <Typography variant="h5" sx={{ my: 1 }}>
            Note:{" "}
            <span style={{ fontWeight: "bolder" }}>{supplierData?.note}</span>
          </Typography>
        </CustomDialog>
      </Box>
      <PerfectScrollbar>
        {/* <RolePermission permission="22-08">
          <Box sx={{ margin: 1 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="notes"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    label="Notes"
                    fullWidth
                    multiline
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <BiNotepad size={25} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end" sx={{ marginY: 2 }}>
                          <Button fullWidth variant="contained" type="submit">
                            Update
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </form>
          </Box>
        </RolePermission> */}
        <Box>
          {!order ? (
            <CustomSpinner />
          ) : (
            <DataGrid
              sx={{
                ".MuiDataGrid-cell": {
                  justifyContent: "center",
                },
                ".highlight": {
                  bgcolor: "#bdbdbd",
                  "&:hover": {
                    bgcolor: "darkgrey",
                  },
                },
              }}
              rows={
                order?.giftItems?.map((row, index) => {
                  return { ...row, sequence: index + 1 };
                }) || []
              }
              autoHeight
              loading={reload}
              columns={columns}
              rowHeight={275}
              getRowClassName={(params) => {
                return params.row.isCanceled ? "highlight" : "";
              }}
              disableSelectionOnClick
              // checkboxSelection
              // onSelectionModelChange={(selection) => {
              //   setSelectedRows(selection);
              //   const selectedPricesArray = selection.map(
              //     (selectedId) =>
              //       order?.giftItems?.find(
              //         (row) => row.orderItemId === selectedId
              //       )?.orderItemId
              //   );
              //   setSelectedDateRows(selectedPricesArray);
              // }}
              // isRowSelectable={(params) =>
              //   (params.row.itemStatus !== 8) & (params.row.itemStatus !== 9)
              // }
              // selectionModel={selectedRows}
              pagination
              rowsPerPageOptions={[100]}
              paginationMode="client"
              components={{
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
            />
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
