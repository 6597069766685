import {
  GET_ALL_CART,
  OPEN_CART_DIALOG,
  RELOAD_CART,
  GET_BY_ID_CART,
  RESET_FORM_CART,
  SAVE_SEARCH_BODY_CART,
  GET_CART_HISTORY,
  SAVE_CART_HISTORY_SEARCH_BODY,
  OPEN_CART_HISTORY_DIALOG,
  SAVE_CART_HISTORY_PAGE_NO,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  form: {
    name: "",
    description: "",
    imageUrl: "",
    price: "",
    productUrl: "",
    size: "",
    color: "",
    style: "",
    quantity: "",
    weight: "",
    weightUnit: "",
    dimensions: "",
    dimensionsUnit: "",
    dangerousGoods: "",
    dangerousGoodsUnit: "",
    shippingCost: "",
    deliveryDate: null,
    stockStatus: "",
    vendorId: "",
  },
  searchBody: {
    page: 0,
    type: "all",
    vendorIds: "",
    phoneNumber: "",
  },
  data: {},

  // cart history
  cartHistory: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  cartHistorySearchBody: {
    pageSize: 100,
    fromReviewAt: null,
    toReviewAt: null,
    reviewerId: null,
  },
  cartHistoryDialog: false,
  selectedCart: null,
  cartHistoryPageNo: 1,
};

function cartReducer(cart = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_CART:
      cart.data = payload;
      return cart;

    case OPEN_CART_DIALOG:
      return { ...cart, openDialog: !cart.openDialog, attachmentUrl: payload };

    case RELOAD_CART:
      return { ...cart, reload: !cart.reload };

    case GET_BY_ID_CART:
      cart.form.productUrl = payload.url;
      cart.form.quantity = payload.quantity;
      cart.form.description = payload.description;
      return cart;

    case RESET_FORM_CART:
      cart.form.name = "";
      cart.form.description = "";
      cart.form.imageUrl = "";
      cart.form.price = "";
      cart.form.productUrl = "";
      cart.form.size = "";
      cart.form.color = "";
      cart.form.style = "";
      cart.form.quantity = "";
      cart.form.weight = "";
      cart.form.weightUnit = "";
      cart.form.dimensions = "";
      cart.form.dimensionsUnit = "";
      cart.form.dangerousGoods = "";
      cart.form.dangerousGoodsUnit = "";
      cart.form.shippingCost = "";
      cart.form.deliveryDate = null;
      cart.form.stockStatus = "";
      cart.form.vendorId = "";
      return cart;

    case SAVE_SEARCH_BODY_CART:
      return { ...cart, searchBody: payload };

    case GET_CART_HISTORY:
      cart.cartHistory.data = payload.data;
      cart.cartHistory.total = payload.pageCount;
      cart.cartHistory.rowCount = payload.rowCount;
      return cart;

    case SAVE_CART_HISTORY_SEARCH_BODY:
      return { ...cart, cartHistorySearchBody: payload };

    case OPEN_CART_HISTORY_DIALOG:
      return {
        ...cart,
        cartHistoryDialog: !cart.cartHistoryDialog,
        selectedCart: payload ?? null,
      };

    case SAVE_CART_HISTORY_PAGE_NO:
      return { ...cart, cartHistoryPageNo: payload };

    default:
      return cart;
  }
}

export default cartReducer;
