import React, { useState, useEffect } from "react";
import {
  Tooltip,
  TextField,
  Stack,
  InputAdornment,
  IconButton,
  Box,
  Grid,
  Card,
  Avatar,
  Typography,
  Divider,
  LinearProgress,
  Switch,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import {
  createTicketComment,
  deleteAttachment,
  deleteTicketComment,
  getTicketComment,
  uploadAttachment,
} from "src/actions/ticket/TicketComment";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import CloseIcon from "@mui/icons-material/Close";
import { CustomDialog } from "src/components/CustomDialog";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { showErrorSnackbar } from "src/actions/snackbarMessages";
import moment from "moment";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { BsFillXCircleFill } from "react-icons/bs";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import RolePermission from "src/components/RolePermission";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export default function TicketComment({ ticketId }) {
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const [comment, setComment] = useState({
    attachmentIds: [],
    content: "",
    isInternal: false,
  });

  const [getCommentId, setGetCommentId] = useState({
    id: "",
    attachmentIds: [],
  });

  const [openAttachmentDialog, setOpenAttachmentDailog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDailog] = useState(false);
  const [attachmentdata, setAttachmentData] = useState(false);
  const [files, setFiles] = useState([]);
  const [attachmentfiles, setAttachmentfiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [hoveredColor, setHoveredColor] = useState(false);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [showPanZoom, setShowPanZoom] = useState("");

  const dispatch = useDispatch();
  const commentData = useSelector((state) => state.ticketComment);
  const ticketDetailsData = useSelector((state) => state.tickets.ticketDetails);

  useEffect(() => {
    setIsLoading(true);
    if (ticketId !== "")
      dispatch(getTicketComment(ticketId)).then((res) => setIsLoading(false));
  }, [ticketId]);

  useEffect(() => (attachmentdata ? ticketComment() : null), [attachmentdata]);

  function ticketComment() {
    if (comment.content == "") {
      dispatch(showErrorSnackbar("Comment should not be empty"));
      setIsLoading(false);
    } else
      dispatch(
        createTicketComment({ ...comment, ticketId: ticketId }, ticketId)
      ).then((res) => {
        setComment({
          attachmentIds: [],
          content: "",
        });
        setAttachmentfiles([]);
        setFiles([]);
        setAttachmentData(!attachmentdata);
        setIsLoading(false);
      });
  }

  function deleteComment() {
    setIsLoading(true);
    dispatch(deleteTicketComment(getCommentId.id, ticketId)).then((res) => {
      setOpenDeleteDailog(false);
      setIsLoading(false);
    });
  }

  function deleteCommentAttachment(id) {
    dispatch(deleteAttachment(id, ticketId)).then((res) => {
      setOpenAttachmentDailog(false);
    });
  }

  useEffect(() => {
    setFiles(attachmentfiles.map((files) => files.file));
  }, [attachmentfiles]);

  const sendFiles = async () => {
    setIsLoading(true);
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append("file", files[i]);
      try {
        const res = await dispatch(uploadAttachment(formData));
        setComment((prev) => {
          return {
            ...prev,
            attachmentIds: [...prev.attachmentIds, res.data.id],
          };
        });
      } catch (err) {
        // handle error
      }
    }
    return setAttachmentData(!attachmentdata);
  };
  const handleOpenPanZoom = (index) => {
    setOpenImageDialog(true);
    setShowPanZoom(index);
  };

  const handleRemoveImageHoverIn = (index) => {
    setSelectedAttachment(index);
  };

  const handleRemoveImageHoverLeave = (index) => {
    setSelectedAttachment(null);
  };

  const handleDialogClose = (index) => {
    setOpenImageDialog(false);
  };

  const ticketCommentToolbar = () => {
    return (
      <Box>
        <CustomDialog
          isOpen={openDeleteDialog}
          handleClose={() => {
            setOpenDeleteDailog(false);
            setGetCommentId({
              id: "",
              attachmentIds: [],
            });
          }}
          isActiveDelete
          isRequierdActions
          title="Delete confirmation"
          onDelete={() => deleteComment(getCommentId)}
          selectedObject={`This Ticket Comment`}
        >
          You will delete the Ticket Comment
          <b>{""}</b>
        </CustomDialog>
        <CustomDialog
          isOpen={openAttachmentDialog}
          handleClose={() => setOpenAttachmentDailog(false)}
          title="Attachments"
        >
          {getCommentId.attachmentIds ? (
            <Grid container spacing={2}>
              {getCommentId.attachmentIds.map((data, index) => (
                <Grid item xs={4} key={index} sx={{ position: "relative" }}>
                  <a
                    target="_blank"
                    href={
                      process.env.REACT_APP_ENV == "QA"
                        ? `https://api-qa.orderii.co/ticket-service${data.url}`
                        : `https://api.orderii.co/ticket-service${data.url}`
                    }
                    rel="noreferrer"
                  >
                    <img
                      src={
                        process.env.REACT_APP_ENV == "QA"
                          ? `https://api-qa.orderii.co/ticket-service${data.url}`
                          : `https://api.orderii.co/ticket-service${data.url}`
                      }
                      alt="Ticket Attachment"
                      style={{ width: "80%" }}
                    />
                  </a>
                  <RolePermission permission="T4-02">
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: "10px",
                        right: "50px",
                        color: "red",
                      }}
                      aria-label="Remove Image"
                      onClick={() => deleteCommentAttachment(data.id)}
                    >
                      <Tooltip title="Remove Image">
                        <DisabledByDefaultIcon />
                      </Tooltip>
                    </IconButton>
                  </RolePermission>
                </Grid>
              ))}
            </Grid>
          ) : null}
        </CustomDialog>
      </Box>
    );
  };

  let previousFullName = "";
  let side = "left";

  return (
    <Box>
      {ticketCommentToolbar()}
      <Box>
        {!commentData.data ? (
          <LinearProgress />
        ) : (
          <Box>
            {isLoading ? <LinearProgress /> : null}
            {commentData.data.map((data, index) => {
              if (data.fullName !== previousFullName) {
                side = side === "left" ? "right" : "left";
                previousFullName = data.fullName;
              }
              const fullNameColor =
                data.fullName == previousFullName ? "#000000" : "#ff0000";
              return (
                <Stack
                  key={index}
                  direction="row"
                  sx={{
                    margin: 2,
                    flexDirection: side === "left" ? "row" : "row-reverse",
                  }}
                >
                  <Avatar>
                    {data.userId === ticketDetailsData.issuerInfo?.userId ? (
                      <AccountCircleIcon />
                    ) : (
                      <Box
                        sx={{ width: "100%", backgroundColor: "white" }}
                        component="img"
                        src="/static/images/logo/logo_orderii_english.png"
                      />
                    )}
                  </Avatar>
                  <Box sx={{ position: "relative", width: "75%", marginX: 1 }}>
                    <Card sx={{ padding: 2 }}>
                      <Stack
                        direction="row"
                        sx={{
                          justifyContent: "space-between",
                          paddingBottom: 2,
                        }}
                      >
                        <Stack>
                          <Typography
                            variant="h5"
                            style={{ color: fullNameColor }}
                          >
                            {data.fullName}
                          </Typography>
                          <Typography variant="caption">
                            {moment(data.createdAt).format(
                              "YYYY-MM-DD / hh:mm a"
                            )}
                          </Typography>
                          <Typography
                            variant="caption"
                            color={data.isInternal ? "primary" : ""}
                          >
                            {data.isInternal ? "Office" : "Customer"}
                          </Typography>
                        </Stack>
                        <RolePermission permission="T4-01">
                          <IconButton
                            aria-label="Remove Comment"
                            onClick={() => {
                              setOpenDeleteDailog(true);
                              setGetCommentId({ ...getCommentId, id: data.id });
                            }}
                          >
                            <Tooltip title="Remove Comment">
                              <CloseIcon />
                            </Tooltip>
                          </IconButton>
                        </RolePermission>
                      </Stack>
                      <Divider />
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <Typography multiline sx={{ marginTop: 3 }}>
                            {data.content?.split("\n").map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ marginTop: 2 }}>
                          <Grid container spacing={3}>
                            {data.attachments
                              ? data.attachments.map((attachments, index) => (
                                  <Grid
                                    key={index}
                                    item
                                    xs={4}
                                    sx={{ position: "relative" }}
                                  >
                                    <Box
                                      sx={{ cursor: "pointer" }}
                                      onMouseEnter={() =>
                                        handleRemoveImageHoverIn(attachments.id)
                                      }
                                      onMouseLeave={() =>
                                        handleRemoveImageHoverLeave(
                                          attachments.id
                                        )
                                      }
                                    >
                                      {commentData && (
                                        <Box>
                                          <Box
                                            onClick={() =>
                                              handleOpenPanZoom(attachments.url)
                                            }
                                            component="img"
                                            sx={{
                                              height: "auto",
                                              width: "100%",
                                            }}
                                            alt="itemImage"
                                            src={
                                              process.env.REACT_APP_ENV == "QA"
                                                ? `https://api-qa.orderii.co/ticket-service${attachments.url}`
                                                : `https://api.orderii.co/ticket-service${attachments.url}`
                                            }
                                          />
                                          <RolePermission permission="T4-02">
                                            {selectedAttachment ==
                                              attachments.id && (
                                              <Tooltip
                                                placement="top"
                                                arrow
                                                title="Remove"
                                                onClick={() =>
                                                  deleteCommentAttachment(
                                                    attachments.id
                                                  )
                                                }
                                              >
                                                <IconButton
                                                  sx={{
                                                    position: "absolute",
                                                    top: "18px",
                                                    right: "0px",
                                                  }}
                                                  aria-label="remove"
                                                  onMouseEnter={() =>
                                                    setHoveredColor(true)
                                                  }
                                                  onMouseLeave={() =>
                                                    setHoveredColor(false)
                                                  }
                                                >
                                                  <BsFillXCircleFill
                                                    size={16}
                                                    color={
                                                      hoveredColor
                                                        ? "#D32F2F"
                                                        : "grey"
                                                    }
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            )}
                                          </RolePermission>
                                        </Box>
                                      )}
                                    </Box>
                                  </Grid>
                                ))
                              : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Box>
                </Stack>
              );
            })}
          </Box>
        )}
        <CustomDialog
          isOpen={openImageDialog}
          handleClose={() => handleDialogClose()}
          title="Images"
          maxWidth="lg"
        >
          <div
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <div
              style={{
                position: "relative",
                overflow: "hidden",
                // minWidth: '500px',
                width: "50%",
                height: "auto",
              }}
            >
              <ReactPanZoom
                image={
                  process.env.REACT_APP_ENV == "QA"
                    ? `https://api-qa.orderii.co/ticket-service${showPanZoom}`
                    : `https://api.orderii.co/ticket-service${showPanZoom}`
                }
                alt="Image alt text"
              />
            </div>
          </div>
        </CustomDialog>
      </Box>
      <Divider sx={{ marginY: 2 }} />
      <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
        <Typography>Office Comment!</Typography>
        <Tooltip title="(Deactive) | (Active)" aria-label="active_deactive">
          <Switch
            checked={comment.isInternal}
            onChange={(e) =>
              setComment({ ...comment, isInternal: e.target.checked })
            }
          />
        </Tooltip>
      </Stack>
      <TextField
        multiline
        sx={{ marginY: 2 }}
        label="Write a Comment"
        size="medium"
        fullWidth
        onChange={(e) => {
          setComment({ ...comment, content: e.target.value });
        }}
        // onKeyDown={(e) =>
        //   e.key === "Enter" ? ticketComment() : null
        // }
        value={comment.content}
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="Send" onClick={sendFiles}>
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box>
        <div>
          <FilePond
            files={attachmentfiles}
            onupdatefiles={setAttachmentfiles}
            allowMultiple={true}
            maxFiles={10}
            name="file"
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            credits={false}
          />
        </div>
      </Box>
    </Box>
  );
}
