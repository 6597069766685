import { useEffect } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { useParams } from "react-router";
import { getCartById, openCartDialog, resetForm } from "src/actions/cart";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { getAllFixed } from "src/actions/vendors";
import { addExternalItem } from "src/actions/items";
import { LoadingButton } from "@mui/lab";

const CartExternalItemForm = (props) => {
  const _Cart = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  const { id } = useParams();

  const [fixedVendors, setFixedVendors] = useState([]);
  const [loading, setLoading] = useState(false);

  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _Cart.form,
  });

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(
      addExternalItem({
        cartId: id ?? props.cartId,
        item: data,
      })
    ).then((x) => {
      dispatch(openCartDialog());
      if (id) {
        dispatch(getCartById(id));
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    dispatch(getAllFixed()).then((res) => {
      setFixedVendors(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearAll = () => {
    reset({
      name: "",
      description: "",
      imageUrl: "",
      price: "",
      productUrl: "",
      size: "",
      color: "",
      style: "",
      quantity: "",
      weight: "",
      weightUnit: "",
      dimensions: "",
      dimensionsUnit: "",
      dangerousGoods: "",
      dangerousGoodsUnit: "",
      shippingCost: "",
      deliveryDate: null,
      stockStatus: "",
      vendorId: "",
    });
  };

  useEffect(() => {
    reset({ ..._Cart.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_Cart.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialog = () => {
    dispatch(openCartDialog());
    clearAll();
  };

  return (
    <CustomDialog
      isOpen={_Cart.openDialog}
      handleClose={handleDialog}
      title={"EXTERNAL ITEM"}
      maxWidth="md"
      onClear={() => reset({ ..._Cart.form })}
      Draggable={true}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} md={6}>
            <Controller
              name="vendorId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={fixedVendors}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Vendor" />
                  )}
                  onChange={(event, value) => {
                    setValue("vendorId", value.id ?? null);
                  }}
                  value={
                    fixedVendors.filter((x) => x.id === field.value)[0]
                      ? fixedVendors.filter((x) => x.id === field.value)[0]
                      : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Item Name"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="imageUrl"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Image Url"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="productUrl"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Product URL"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  type="number"
                  size="small"
                  label="Price"
                  fullWidth
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="shippingCost"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  type="number"
                  size="small"
                  label="Shipping Cost"
                  fullWidth
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="weight"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Weight"
                  fullWidth
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Pound</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="quantity"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  type="number"
                  size="small"
                  label="Quantity"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="dimensions"
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Dimensions"
                  fullWidth
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Inches</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="size"
              control={control}
              render={({ field }) => (
                <TextField size="small" label="Size" fullWidth {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="style"
              control={control}
              render={({ field }) => (
                <TextField size="small" label="Style" fullWidth {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="color"
              control={control}
              render={({ field }) => (
                <TextField size="small" label="Color" fullWidth {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="dangerousGoods"
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Dangerous Goods"
                  fullWidth
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">ml</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="stockStatus"
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Stock Status"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="deliveryDate"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    inputFormat="YYYY-MM-DD"
                    views={["year", "month", "day"]}
                    label="Delivery date"
                    onChange={(value) => setValue("deliveryDate", value)}
                    value={field.value}
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth
                        size="small"
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  multiline
                  rows={3}
                  size="small"
                  label="Description"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
              >
                Save
              </LoadingButton>
              <Button variant="contained" color="warning" onClick={clearAll}>
                Clear Text
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default CartExternalItemForm;
