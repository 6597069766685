import React, { useRef } from "react";
import {
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { printPackingList } from "src/actions/shipments";

const useStyles = makeStyles((theme) => ({
  mb: {
    marginBottom: "60px",
  },
  margin: {
    margin: "30px",
  },
  footerText: {
    fontSize: "18px",
    lineHeight: "0px",
  },
  footer: {
    borderBottomWidth: 3,
    backgroundColor: "black",
  },
  div: {
    fontSize: "26px",
  },
  h: {
    fontSize: "28px",
    fontWeight: "bold",
  },
  "@global": {
    "p, h4": {
      padding: "3px",
      color: "black",
    },
  },
  tableHeader: {
    textAlign: "center",
    backgroundColor: "#03a9f4",
    color: "white",
  },
}));

export default function PrintShipmentsPackingList() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const printRef = useRef(null);

  const printPackingListData = useSelector(
    (state) => state.shipments.printPackingList
  );

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    pageStyle: `
    @media print {
      @page {
        size: A4;
        margin: 10;
      }
    }
  `,
  });

  // useEffect(() => {
  //   handlePrint();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  return (
    <CustomDialog
      isOpen={printPackingListData.dialog}
      handleClose={() => dispatch(printPackingList({}))}
      title={""}
      maxWidth="lg"
      Draggable={true}
    >
      <div>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            onClick={() => {
              handlePrint();
            }}
            color="primary"
            variant="contained"
            fullWidth
          >
            print
          </Button>
        </Stack>
        <div
          ref={printRef}
          style={{ padding: "15px", backgroundColor: "white" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Typography variant="h4">Proforma Invoice</Typography>
                <Typography variant="h4">
                  AWB: {printPackingListData.data?.shippingNumber}
                </Typography>
              </Stack>
              <Divider className={classes.footer} />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Box>
                  <Typography fontSize={18} fontWeight={"bold"}>
                    Exporter
                  </Typography>
                  <Typography fontSize={18} lineHeight={1}>
                    Shaker Ayad
                  </Typography>
                  <Typography fontSize={18} lineHeight={1}>
                    301 Cornell Drive Unit K2
                  </Typography>
                  <Typography fontSize={18} lineHeight={1}>
                    Wilmington, Delaware 19801-5700
                  </Typography>
                  <Typography fontSize={18} lineHeight={1}>
                    United States
                  </Typography>
                  <Typography fontSize={18} lineHeight={1}>
                    ISA04651
                  </Typography>

                  {
                    // 1255 16th Street
                    // IQBGW00000
                    // Wilmington, DE 19802
                    // +1 (609) 848-8662
                  }
                </Box>
                <Box>
                  <Typography fontSize={18} fontWeight={"bold"}>
                    Ultimate Consignee
                  </Typography>
                  <Typography fontSize={18} lineHeight={1}>
                    Al-Talab Al-Momayeez
                  </Typography>
                  <Typography fontSize={18} lineHeight={1}>
                    hay aljamea dis 633 h 16 st 66
                  </Typography>
                  <Typography fontSize={18} lineHeight={1}>
                    Baghdad Baghdad 00964
                  </Typography>
                  <Typography fontSize={18} lineHeight={1}>
                    Iraq
                  </Typography>
                  <Typography fontSize={18} lineHeight={1}>
                    +964 7803302500
                  </Typography>
                  <Typography fontSize={18} lineHeight={1}>
                    oshipping@orderii.co
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              {printPackingListData.data?.orderItems?.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Image</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Orderii Number</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Unit Price</TableCell>
                        <TableCell>Total Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {printPackingListData.data?.orderItems.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            <Box
                              component="img"
                              sx={{
                                height: 70,
                                width: 70,
                                objectFit: "contain",
                              }}
                              alt="itemImage"
                              src={row?.imageUrl}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body2"
                              sx={{
                                display: "-webkit-box",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                "-webkit-line-clamp": "2",
                                "-webkit-box-orient": "vertical",
                                lineHeight: "1.5rem",
                                maxHeight: "3rem",
                                width: 200,
                              }}
                            >
                              {row?.itemName}
                            </Typography>
                          </TableCell>
                          <TableCell>{row?.orderiiNumber}</TableCell>
                          <TableCell>{row?.quantity}</TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {row?.unitPrice?.toLocaleString() ?? ""}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {row?.totalPrice?.toLocaleString() ?? ""}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <Typography fontWeight={"bold"}>
                            {printPackingListData.data?.totalUnitPrice?.toLocaleString()}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontWeight={"bold"}>
                            {printPackingListData.data?.totalPrice?.toLocaleString()}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Typography fontSize={18} fontWeight={"bold"} align="center">
                  Items Count
                </Typography>
                <Typography fontSize={18} align="center">
                  {printPackingListData.data?.itemsCount}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Typography fontSize={18} fontWeight={"bold"} align="center">
                  Total Weight
                </Typography>
                <Typography fontSize={18} align="center">
                  {printPackingListData.data?.totalWeight} (lbs)
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Typography fontSize={18} fontWeight={"bold"} align="center">
                  Ultimate Destination
                </Typography>
                <Typography fontSize={18} align="center">
                  {printPackingListData.data?.ultimateDestination}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={1} alignItems="center">
                <Typography fontSize={18} fontWeight={"bold"}>
                  Packing List Specialist Name:
                </Typography>
                <Typography fontSize={18}>
                  {printPackingListData.data.packingListSpecialistName}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={1} alignItems="center">
                <Typography fontSize={18} fontWeight={"bold"}>
                  Preparing Date:
                </Typography>
                <Typography fontSize={18}>
                  {moment(printPackingListData.preparingDate).format(
                    "YYYY-MM-DD"
                  )}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={1} alignItems="center">
                <Typography fontSize={18} fontWeight={"bold"}>
                  Print Date:
                </Typography>
                <Typography fontSize={18}>
                  {moment(printPackingListData.printDate).format("YYYY-MM-DD")}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </div>
      </div>
    </CustomDialog>
  );
}
