import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import NewCustomerPerDay from "src/components/pagesContent/reportsNew/newCustomerPerDay";
import UnusedPromo from "src/components/pagesContent/reportsNew/unusedPromo";
import TotalDicount from "src/components/pagesContent/reportsNew/totalDicountPerDay";
import TotalRevenuePromoVendor from "src/components/pagesContent/reportsNew/totalRevenuePromoVendor";
import RegionPromoVendorRevenue from "src/components/pagesContent/reportsNew/RegionPromoVendorRevenue";
import AllInfromation from "src/components/pagesContent/reportsNew/AllInfo";
import Cac from "src/components/pagesContent/reportsNew/cac";
import moment from "moment";
import TopCustomers from "src/components/pagesContent/reportsNew/TopCustomers";
import TopTenVendors from "src/components/pagesContent/reportsNew/TopTenVendors";
import { Stack } from "@mui/system";
import Transactions from "src/components/pagesContent/reportsNew/transactions";
import UserBalances from "src/components/pagesContent/reportsNew/userBalance";
import DateRangepicker from "src/components/DateRangePicker";
import Topup from "src/components/pagesContent/reportsNew/topup";
import WalletLiability from "src/components/pagesContent/reportsNew/walletLiability";
import Income from "src/components/pagesContent/reportsNew/income";
import WalletSnapshot from "src/components/pagesContent/reportsNew/walletSnapshot";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TbReportAnalytics } from "react-icons/tb";
import AgentTopUp from "src/components/pagesContent/reportsNew/agentTopup";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import Logo from "src/components/Logo";
import { CustomDialog } from "src/components/CustomDialog";
import UsPv from "src/components/pagesContent/reportsNew/PurchaseVouchers";
import Lottie from "react-lottie";
import noDocuments from "../utils/noDocuments.json";
import GiftAllInformation from "src/components/pagesContent/reportsNew/gift/giftAllInfo";
import AllStockInfo from "src/components/pagesContent/reportsNew/gift/allStockInfo";
import WalletsAndBanksAccount from "src/components/pagesContent/reportsNew/walletsAndBanksAccounts";
import { IoMdClose } from "react-icons/io";
import CustomerInfoReport from "src/components/pagesContent/reportsNew/customerInfo";
import ItemsPurchasedReport from "src/components/pagesContent/reportsNew/itemsPurchaed";
import UserBehavior from "src/components/pagesContent/reportsNew/userBehavior";
import ProductsEconomics from "src/components/pagesContent/reportsNew/productEconomics";
import ProductsDurations from "src/components/pagesContent/reportsNew/productDurations";
import CartCreation from "src/components/pagesContent/reportsNew/cartCreation";
import TabulatedInformation from "src/components/pagesContent/reportsNew/tabulatedInformation";

const ReportNew = () => {
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const printRef = useRef(null);

  const reportItems = [
    {
      value: 2,
      label: "New Customer Per Day",
      code: "27-01",
      serviceType: "Global",
      isNew: false,
    },
    {
      value: 3,
      label: "Unused Promo",
      code: "27-03",
      serviceType: "Global",
      isNew: false,
    },
    {
      value: 4,
      label: "Total Discount Per Promo Code",
      code: "27-04",
      serviceType: "Global",
      isNew: false,
    },
    {
      value: 5,
      label: "Total Revenue Promo Vendor",
      code: "27-02",
      serviceType: "Global",
      isNew: false,
    },
    {
      value: 6,
      label: "All Information",
      code: "27-06",
      serviceType: "Global",
      isNew: false,
    },
    {
      value: 18,
      label: "Purchase Vouchers",
      code: "27-06",
      serviceType: "Global",
      isNew: false,
    },
    {
      value: 11,
      label: "Transactions",
      code: "27-12",
      serviceType: "Global",
      isNew: false,
    },
    {
      value: 12,
      label: "User Balances",
      code: "27-13",
      serviceType: "Global",
      isNew: false,
    },
    {
      value: 7,
      label: "Region Promo Vendor Revenue",
      code: "27-05",
      serviceType: "Global",
      isNew: false,
    },
    {
      value: 8,
      label: "Customer Acquisition Cost",
      code: "27-07",
      serviceType: "Global",
      isNew: false,
    },
    {
      value: 9,
      label: "Top Customers",
      code: "27-08",
      serviceType: "Global",
      isNew: false,
    },
    {
      value: 10,
      label: "Top Vendors",
      code: "27-10",
      serviceType: "Global",
      isNew: false,
    },
    {
      value: 16,
      label: "Wallet Snapshot",
      code: "27-18",
      serviceType: "Global",
      isNew: false,
    },
    {
      value: 13,
      label: "Top-Up",
      code: "27-15",
      serviceType: "Global",
      isNew: false,
    },
    {
      value: 14,
      label: "Wallet Liability",
      code: "27-16",
      serviceType: "Global",
      isNew: false,
    },
    {
      value: 15,
      label: "Income",
      code: "27-17",
      serviceType: "Global",
      isNew: false,
    },
    {
      value: 17,
      label: "Agent Top-Up",
      code: "27-15",
      serviceType: "Global",
      isNew: false,
    },
    {
      value: 21,
      label: "Wallets And Banks Account",
      code: "27-21",
      serviceType: "Global",
      isNew: true,
    },
    {
      value: 22,
      label: "Customer Information",
      code: "27-22",
      serviceType: "Global",
      isNew: true,
    },
    {
      value: 23,
      label: "Items Purchased",
      code: "27-23",
      serviceType: "Global",
      isNew: true,
    },
    {
      value: 24,
      label: "User Behavior",
      code: "27-24",
      serviceType: "Global",
      isNew: true,
    },
    {
      value: 25,
      label: "Product Economics",
      code: "27-25",
      serviceType: "Global",
      isNew: true,
    },
    {
      value: 26,
      label: "Product Durations",
      code: "27-26",
      serviceType: "Global",
      isNew: true,
    },
    {
      value: 27,
      label: "Cart Creation",
      code: "27-27",
      serviceType: "Global",
      isNew: true,
    },
    {
      value: 28,
      label: "Tabulated Information",
      code: "27-28",
      serviceType: "Global",
      isNew: true,
    },
    // ===================== Orderii Now Reports
    {
      value: 19,
      label: "Gift All Information",
      code: "60-01",
      serviceType: "Vertical",
      isNew: false,
    },
    {
      value: 20,
      label: "Gift All Stock Info",
      code: "60-02",
      serviceType: "Vertical",
      isNew: false,
    },
  ];

  const sortedItems = reportItems.sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  const [reportType, setReportType] = useState(1);
  const [forCancelled, setForCancelled] = useState(false);
  const [newCustomerPerDay, setNewCustomerPerDay] = useState(false);
  const [unUsedPromo, setUnusedPromo] = useState(false);
  const [totalDicount, setTotalDicount] = useState(false);
  const [totalRevenuePromoVendor, setTotalRevenuePromoVendor] = useState(false);
  const [allInfo, setAllInfo] = useState(false);
  const [usPv, setUsPv] = useState(false);
  const [transactions, setTransactions] = useState(false);
  const [userBalances, setUserBalances] = useState(false);
  const [regionPromoVendorRevenue, setRegionPromoVendorRevenue] =
    useState(false);
  const [cac, setCas] = useState(false);
  const [topCustomers, setTopCustomers] = useState(false);
  const [topTenVendors, setTopTenVendors] = useState(false);
  const [topup, setTopup] = useState(false);
  const [walletLiability, setWalletLiability] = useState(false);
  const [income, setIncome] = useState(false);
  const [walletSnapshot, setWalletSnapshot] = useState(false);
  const [agentTopUp, setAgentTopUp] = useState(false);
  const [giftAllInformation, setGiftAllInformation] = useState(false);
  const [giftAllStockInfo, setGiftAllStockInfo] = useState(false);
  const [walletsAndBanksAccount, setWalletsAndBanksAccount] = useState(false);
  const [customerInfo, setCustomerInfo] = useState(false);
  const [itemsPurchased, setItemsPurchased] = useState(false);
  const [userBehavior, setUserBehavior] = useState(false);
  const [productEconomics, setProductEconomics] = useState(false);
  const [productDurations, setProductDurations] = useState(false);
  const [cartCreation, setCartCreation] = useState(false);
  const [tabulatedInformation, setTabulatedInformation] = useState(false);

  const [findButton, setFindButton] = useState(false);
  const [handleSearch, setHandleSearch] = useState(false);
  const [searchDate, setSearchDate] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));

  useEffect(() => {
    reportType === 2 ? setNewCustomerPerDay(true) : setNewCustomerPerDay(false);
    reportType === 3 ? setUnusedPromo(true) : setUnusedPromo(false);
    reportType === 4 ? setTotalDicount(true) : setTotalDicount(false);
    reportType === 5
      ? setTotalRevenuePromoVendor(true)
      : setTotalRevenuePromoVendor(false);
    reportType === 6 ? setAllInfo(true) : setAllInfo(false);
    reportType === 18 ? setUsPv(true) : setUsPv(false);
    reportType === 7
      ? setRegionPromoVendorRevenue(true)
      : setRegionPromoVendorRevenue(false);
    reportType === 8 ? setCas(true) : setCas(false);
    reportType === 9 ? setTopCustomers(true) : setTopCustomers(false);
    reportType === 10 ? setTopTenVendors(true) : setTopTenVendors(false);
    reportType === 11 ? setTransactions(true) : setTransactions(false);
    reportType === 12 ? setUserBalances(true) : setUserBalances(false);
    reportType === 13 ? setTopup(true) : setTopup(false);
    reportType === 14 ? setWalletLiability(true) : setWalletLiability(false);
    reportType === 15 ? setIncome(true) : setIncome(false);
    reportType === 16 ? setWalletSnapshot(true) : setWalletSnapshot(false);
    reportType === 17 ? setAgentTopUp(true) : setAgentTopUp(false);
    reportType === 19
      ? setGiftAllInformation(true)
      : setGiftAllInformation(false);
    reportType === 20 ? setGiftAllStockInfo(true) : setGiftAllStockInfo(false);
    reportType === 21
      ? setWalletsAndBanksAccount(true)
      : setWalletsAndBanksAccount(false);
    reportType === 22 ? setCustomerInfo(true) : setCustomerInfo(false);
    reportType === 23 ? setItemsPurchased(true) : setItemsPurchased(false);
    reportType === 24 ? setUserBehavior(true) : setUserBehavior(false);
    reportType === 25 ? setProductEconomics(true) : setProductEconomics(false);
    reportType === 26 ? setProductDurations(true) : setProductDurations(false);
    reportType === 27 ? setCartCreation(true) : setCartCreation(false);
    reportType === 28
      ? setTabulatedInformation(true)
      : setTabulatedInformation(false);

    setFindButton(false);

    reportType === 21 || reportType === 22
      ? setSearchDate({
          startDate: null,
          endDate: null,
        })
      : setSearchDate({
          startDate: moment().format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        });
    setForCancelled(false);
  }, [reportType]);

  const handleChange = (e) => {
    setReportType(e.target.value);
  };

  const reportsToolbar = () => {
    return newCustomerPerDay ||
      totalDicount ||
      allInfo ||
      usPv ||
      regionPromoVendorRevenue ||
      transactions ||
      income ||
      topup ||
      agentTopUp ||
      giftAllInformation ||
      giftAllStockInfo ||
      walletsAndBanksAccount ||
      customerInfo ? (
      <Box>
        <DateRangepicker
          right={0}
          startDate={searchDate.startDate}
          endDate={searchDate.endDate}
          handleOnChange={(searchDate) => {
            const updatedFilter = {
              from: moment(searchDate[0].startDate).format("YYYY-MM-DD"),
              to: moment(searchDate[0].endDate).format("YYYY-MM-DD"),
            };
            if (
              updatedFilter.from !== searchDate.startDate ||
              updatedFilter.to !== searchDate.endDate
            ) {
              setSearchDate({
                startDate: moment(searchDate[0].startDate).format("YYYY-MM-DD"),
                endDate: moment(searchDate[0].endDate).format("YYYY-MM-DD"),
              });
            }
          }}
        />
      </Box>
    ) : null;
  };
  const datePickerToollBar = () =>
    findButton & walletSnapshot ? (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          views={["year", "month", "day"]}
          label="select date"
          name="selectDate"
          value={searchDate.endDate}
          onChange={(value) => setSearchDate({ ...searchDate, endDate: value })}
          renderInput={(params) => (
            <TextField size="small" variant="outlined" {...params} />
          )}
        />
      </LocalizationProvider>
    ) : null;

  const yearPickerToolBar = () =>
    itemsPurchased ||
    userBehavior ||
    productEconomics ||
    productDurations ||
    cartCreation ||
    tabulatedInformation ? (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          views={["year"]}
          label="Select Year"
          name="selectYear"
          value={selectedYear}
          onChange={(value) => setSelectedYear(moment(value).format("YYYY"))}
          renderInput={(params) => (
            <TextField size="small" variant="outlined" {...params} />
          )}
        />
      </LocalizationProvider>
    ) : null;

  // const [reload, setReload] = useState(false);
  const [openSignatureDialog, setOpenSignatureDialog] = useState(false);

  // const fetchDataAgentTopup = async () => {
  //   const yesterDay = moment().subtract(1, "day").format("YYYY-MM-DD");
  //   setReload(true);
  //   dispatch(
  //     agentTopup({
  //       from: moment().subtract(1, "day").format("YYYY-MM-DD"),
  //       to: moment().subtract(1, "day").format("YYYY-MM-DD"),
  //     })
  //   ).then((res) => {
  //     const agentTopupHeaders = {
  //       agentName: "Agent Name",
  //       agentPhoneNumber: "Phone Number",
  //       amount: "Amount",
  //       count: "Count",
  //       agentBalance: "Balance",
  //     };

  //     const agentTopupHeadersHandler = Object.values(agentTopupHeaders);

  //     const data = [
  //       agentTopupHeadersHandler,
  //       ...res.data.map((item) =>
  //         Object.keys(agentTopupHeaders).map((key) => item[key])
  //       ),
  //     ];
  //     const agentTopupBook = XLSX.utils.book_new();
  //     const agentTopupSheet = XLSX.utils.aoa_to_sheet(data);
  //     XLSX.utils.book_append_sheet(agentTopupBook, agentTopupSheet, "Sheet1");
  //     XLSX.writeFile(agentTopupBook, `agent-Topup - ${yesterDay}.xlsx`);

  //     dispatch(
  //       incomeReport({
  //         from: moment().subtract(1, "day").format("YYYY-MM-DD"),
  //         to: moment().subtract(1, "day").format("YYYY-MM-DD"),
  //         forCancelled: false,
  //       })
  //     ).then((res) => {
  //       const incomeHeaders = {
  //         vendorName: "Vendor Name",
  //         region: "Region",
  //         subTotal: "Subtotal",
  //         dangerousGoods: "Dangerous Goods",
  //         localShipping: "Local Shipping",
  //         tax: "Tax",
  //         internationalShipping: "International Shipping",
  //         customs: "Customs",
  //         orderiiFee: "Orderii Fee",
  //         deliveryCostBg: "Delivery Cost Baghdad",
  //         deliveryCostOther: "Delivery Cost Other",
  //         deliveryCost: "Total Delivery Cost",
  //         insurance: "insurance",
  //         totalAmountBeforeDiscount: "Total Amount Before Discount",
  //         totalDiscount: "Total Discount",
  //         totalAmountAfterDiscount: "Total Amount After Discount",
  //       };

  //       const incomeHandler = Object.values(incomeHeaders);

  //       const data = [
  //         incomeHandler,
  //         ...res.data.map((item) =>
  //           Object.keys(incomeHeaders).map((key) => item[key])
  //         ),
  //       ];
  //       const incomeBook = XLSX.utils.book_new();
  //       const incomeSheet = XLSX.utils.aoa_to_sheet(data);
  //       XLSX.utils.book_append_sheet(incomeBook, incomeSheet, "Sheet1");
  //       XLSX.writeFile(incomeBook, `income - ${yesterDay}.xlsx`);

  //       dispatch(
  //         topupReport({
  //           from: moment().subtract(1, "day").format("YYYY-MM-DD"),
  //           to: moment().subtract(1, "day").format("YYYY-MM-DD"),
  //         })
  //       ).then((res) => {
  //         const topupHeaders = {
  //           amount: "Amount",
  //           count: "Count",
  //           type: "Type",
  //         };

  //         const topupHeadersHandler = Object.values(topupHeaders);

  //         const data = [
  //           topupHeadersHandler,
  //           ...res.data.map((item) =>
  //             Object.keys(topupHeaders).map((key) => item[key])
  //           ),
  //         ];
  //         const topupBook = XLSX.utils.book_new();
  //         const topupSheet = XLSX.utils.aoa_to_sheet(data);
  //         XLSX.utils.book_append_sheet(topupBook, topupSheet, "Sheet1");
  //         XLSX.writeFile(topupBook, `Topup - ${yesterDay}.xlsx`);

  //         dispatch(walletliability()).then((res) => {
  //           const walletLiabilityHeaders = {
  //             liabilities: "Liabilities",
  //             segment: "Segment",
  //             userCount: "User Count",
  //           };

  //           const walletLiabilityHeadersHandler = Object.values(
  //             walletLiabilityHeaders
  //           );
  //           const data = [
  //             walletLiabilityHeadersHandler,
  //             ...res.data.map((item) =>
  //               Object.keys(walletLiabilityHeaders).map((key) => item[key])
  //             ),
  //           ];
  //           const walletLiabilityDataBook = XLSX.utils.book_new();
  //           const walletLiabilityDataSheet = XLSX.utils.aoa_to_sheet(data);
  //           XLSX.utils.book_append_sheet(
  //             walletLiabilityDataBook,
  //             walletLiabilityDataSheet,
  //             "Sheet1"
  //           );
  //           XLSX.writeFile(
  //             walletLiabilityDataBook,
  //             `Wallet-Liability - ${yesterDay}.xlsx`
  //           );

  //           setReload(false);
  //         });
  //       });
  //     });
  //   });
  // };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    pageStyle: `@media print {
          @page {
            size: A4;
            margin: 10
          }
        }
        .tableHeader {
          background-color: "#8444FF"
        }`,
  });

  // useEffect(() => {
  //   if (openSignatureDialog) {
  //     setTimeout(() => {
  //       handlePrint();
  //     }, 1000);
  //   }
  // }, [openSignatureDialog]);

  const HandelSignaturePrint = (data) => (
    <div>
      <Button
        onClick={() => {
          handlePrint();
        }}
        color="primary"
        variant="contained"
        fullWidth
      >
        Save pdf File
      </Button>
      <div
        ref={printRef}
        style={{ padding: "50px", width: "210mm", height: "297mm" }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ marginBottom: "75px" }}
        >
          <Logo style={{ width: "300px", height: "70px" }} />
          <Stack direction="column" spacing={1} alignItems="end">
            <Typography variant="h4">
              شركة الطلب المميز للتسوق الالكتروني
            </Typography>
            <Typography variant="h4">
              والتجارة العامة محددة المسؤولية
            </Typography>
            <Typography variant="h4">قسم تكنولولوجيا المعلومات</Typography>
          </Stack>
        </Stack>

        <Stack direction="column" alignItems="center">
          <Stack direction="row" spacing={1} sx={{ paddingBottom: 5 }}>
            <Typography variant="h4">System Data Reports</Typography>
            <Typography variant="h4" color="error">
              (CONFIDENTIAL)
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ paddingBottom: 1 }}>
          <Typography sx={{ fontSize: "15px" }}>
            The following reports are exported by Orderii System at:
          </Typography>
          <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
            {moment().subtract(1, "day").format("YYYY-MM-DD")}
          </Typography>
        </Stack>

        <List
          sx={{ width: "100%", maxWidth: 360, paddingBottom: 2 }}
          // subheader={<ListSubheader>Settings</ListSubheader>}
        >
          <ListItem sx={{ padding: "0 15px" }}>
            <ListItemIcon sx={{ minWidth: 25, fontWeight: "bold" }}>
              1-
            </ListItemIcon>
            <ListItemText
              id="switch-list-label-wifi"
              primary="Wallet LiabiliKes Report."
            />
          </ListItem>
          <ListItem sx={{ padding: "0 15px" }}>
            <ListItemIcon sx={{ minWidth: 25, fontWeight: "bold" }}>
              2-
            </ListItemIcon>
            <ListItemText
              id="switch-list-label-wifi"
              primary="Top-up Report."
            />
          </ListItem>
          <ListItem sx={{ padding: "0 15px" }}>
            <ListItemIcon sx={{ minWidth: 25, fontWeight: "bold" }}>
              3-
            </ListItemIcon>
            <ListItemText
              id="switch-list-label-wifi"
              primary="Income Report."
            />
          </ListItem>
          <ListItem sx={{ padding: "0 15px" }}>
            <ListItemIcon sx={{ minWidth: 25, fontWeight: "bold" }}>
              4-
            </ListItemIcon>
            <ListItemText
              id="switch-list-label-wifi"
              primary="Agent Top-up Report."
            />
          </ListItem>
        </List>

        <Typography sx={{ fontSize: "15px", paddingBottom: 2 }}>
          The information contained within the Orderii company files is
          confidential and intended solely for the use of authorized personnel.
          This information includes financial statements, business plans,
          customer data, and intellectual property. This information's
          unauthorized use or disclosure is strictly prohibited and may result
          in legal action. Please ensure that all files are stored securely and
          accessed only by authorized personnel. We thank you for your
          cooperation in maintaining the confidentiality of Orderii's
          proprietary information.
        </Typography>
        <Typography sx={{ fontSize: "15px" }}>Sincerely,</Typography>
        <Typography sx={{ fontSize: "15px" }}>IT Department.,</Typography>

        <Table style={{ marginTop: "50px" }}>
          <TableHead
            className="tableHeader"
            style={{ backgroundColor: "#8444FF" }}
          >
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Signature</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Ahmed Qais Mohammed</TableCell>
              <TableCell>Chief Technology Officer</TableCell>
              <TableCell>{moment().format("YYYY-MM-DD")}</TableCell>
              <TableCell>
                <Box
                  component="img"
                  sx={{ width: 120 }}
                  src="https://i.imgur.com/cVsGMuY.png"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Typography sx={{ fontSize: "15px", marginTop: 8 }}>
          www.orderii.co
        </Typography>
        <Typography sx={{ fontSize: "15px" }}>info@orderii.co</Typography>
        <Typography sx={{ fontSize: "15px" }}>Baghdad - hay aljamea</Typography>
        <Typography sx={{ fontSize: "15px" }}>+(964) 781118833</Typography>
      </div>
    </div>
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noDocuments,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <Helmet>
        <title>Report | Orderii</title>
      </Helmet>
      <Box>
        <Grid container>
          <Grid item xs={12} sx={{ p: 2 }}>
            <Card>
              <CustomDialog
                isOpen={openSignatureDialog}
                handleClose={() => setOpenSignatureDialog(false)}
                title={"Signature"}
                maxWidth="md"
              >
                <HandelSignaturePrint />
              </CustomDialog>
              <CardHeader
                title={
                  <Typography variant="h4" component="div">
                    Reports
                  </Typography>
                }
                avatar={<TbReportAnalytics size={20} />}
                // action={
                //   <LoadingButton
                //     loading={reload}
                //     variant="contained"
                //     color="warning"
                //     onClick={() => {
                //       fetchDataAgentTopup();
                //       if (
                //         _UserInfo.id === "898baf0d-0ad6-490b-8f17-280057972d43"
                //       )
                //         setOpenSignatureDialog(true);
                //     }}
                //   >
                //     Daily Finance Reports
                //   </LoadingButton>
                // }
              />
            </Card>
          </Grid>
          <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
            <Grid container spacing={3}>
              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <Card style={{ padding: "5px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={4} sx={{ margin: 2 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel>Select Report</InputLabel>
                        <Select
                          value={reportType}
                          label="Select Report"
                          onChange={handleChange}
                        >
                          <MenuItem value={1} sx={{ color: "gray" }}>
                            Select Report
                          </MenuItem>
                          {sortedItems.map((item) => {
                            const { value, label, code, serviceType, isNew } =
                              item;
                            const isVisible =
                              _UserInfo.roleOEndpoint
                                .map((data) => data.Code)
                                .includes(code) &&
                              _UserInfo.serviceType === serviceType;

                            return (
                              isVisible && (
                                <MenuItem key={value} value={value}>
                                  {isNew ? (
                                    <Stack
                                      direction="row"
                                      spacing={1}
                                      alignItems="center"
                                    >
                                      <Typography>{label}</Typography>
                                      <Chip
                                        label="New"
                                        color="success"
                                        size="small"
                                        variant="outlined"
                                      />
                                    </Stack>
                                  ) : (
                                    label
                                  )}
                                </MenuItem>
                              )
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={7} sx={{ marginY: 2 }}>
                      <Stack direction="row" spacing={2}>
                        {reportsToolbar()}
                        {datePickerToollBar()}
                        {yearPickerToolBar()}
                        {reportType === 6 ||
                        reportType === 15 ||
                        reportType === 19 ||
                        productEconomics ||
                        productDurations ? (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={forCancelled}
                                onChange={(e) => {
                                  setForCancelled(e.target.checked);
                                }}
                              />
                            }
                            label="Canceled items!"
                          />
                        ) : null}
                        {reportType === 21 || reportType === 22 ? (
                          <IconButton
                            onClick={() => {
                              setSearchDate({
                                startDate: null,
                                endDate: null,
                              });
                            }}
                          >
                            <IoMdClose size={20} />
                          </IconButton>
                        ) : null}
                        {reportType !== 1 ? (
                          <Button
                            variant="contained"
                            onClick={() => {
                              setFindButton(true);
                              setHandleSearch(!handleSearch);
                            }}
                          >
                            Find
                          </Button>
                        ) : null}
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
                {findButton && (
                  <Card sx={{ padding: 2, marginTop: 2 }}>
                    {newCustomerPerDay && (
                      <NewCustomerPerDay
                        date={searchDate}
                        setSearch={handleSearch}
                      />
                    )}
                    {unUsedPromo && (
                      <UnusedPromo date={searchDate} setSearch={handleSearch} />
                    )}
                    {totalDicount && (
                      <TotalDicount
                        date={searchDate}
                        setSearch={handleSearch}
                      />
                    )}
                    {totalRevenuePromoVendor && (
                      <TotalRevenuePromoVendor
                        date={searchDate}
                        setSearch={handleSearch}
                      />
                    )}
                    {allInfo && (
                      <AllInfromation
                        date={searchDate}
                        setSearch={handleSearch}
                        forCancelled={forCancelled}
                      />
                    )}
                    {usPv && (
                      <UsPv date={searchDate} setSearch={handleSearch} />
                    )}
                    {transactions && (
                      <Transactions
                        date={searchDate}
                        setSearch={handleSearch}
                      />
                    )}
                    {userBalances && (
                      <UserBalances
                        date={searchDate}
                        setSearch={handleSearch}
                      />
                    )}
                    {regionPromoVendorRevenue && (
                      <RegionPromoVendorRevenue
                        date={searchDate}
                        setSearch={handleSearch}
                      />
                    )}
                    {cac && <Cac date={searchDate} setSearch={handleSearch} />}
                    {topCustomers && (
                      <TopCustomers
                        date={searchDate}
                        setSearch={handleSearch}
                      />
                    )}
                    {topTenVendors && (
                      <TopTenVendors
                        date={searchDate}
                        setSearch={handleSearch}
                      />
                    )}
                    {topup && (
                      <Topup date={searchDate} setSearch={handleSearch} />
                    )}
                    {walletLiability && (
                      <WalletLiability
                        date={searchDate}
                        setSearch={handleSearch}
                      />
                    )}
                    {income && (
                      <Income
                        date={searchDate}
                        setSearch={handleSearch}
                        forCancelled={forCancelled}
                      />
                    )}
                    {walletSnapshot && (
                      <WalletSnapshot
                        date={searchDate}
                        setSearch={handleSearch}
                      />
                    )}
                    {agentTopUp && (
                      <AgentTopUp date={searchDate} setSearch={handleSearch} />
                    )}
                    {walletsAndBanksAccount && (
                      <WalletsAndBanksAccount
                        date={searchDate}
                        setSearch={handleSearch}
                      />
                    )}
                    {customerInfo && (
                      <CustomerInfoReport
                        date={searchDate}
                        setSearch={handleSearch}
                      />
                    )}
                    {itemsPurchased && (
                      <ItemsPurchasedReport
                        selectedYear={selectedYear}
                        setSearch={handleSearch}
                      />
                    )}
                    {userBehavior && (
                      <UserBehavior
                        selectedYear={selectedYear}
                        setSearch={handleSearch}
                      />
                    )}
                    {productEconomics && (
                      <ProductsEconomics
                        selectedYear={selectedYear}
                        setSearch={handleSearch}
                        forCancelled={forCancelled}
                      />
                    )}
                    {productDurations && (
                      <ProductsDurations
                        selectedYear={selectedYear}
                        setSearch={handleSearch}
                        forCancelled={forCancelled}
                      />
                    )}
                    {cartCreation && (
                      <CartCreation
                        selectedYear={selectedYear}
                        setSearch={handleSearch}
                      />
                    )}
                    {tabulatedInformation && (
                      <TabulatedInformation
                        selectedYear={selectedYear}
                        setSearch={handleSearch}
                      />
                    )}
                    {giftAllInformation && (
                      <GiftAllInformation
                        date={searchDate}
                        setSearch={handleSearch}
                        forCancelled={forCancelled}
                      />
                    )}
                    {giftAllStockInfo && (
                      <AllStockInfo
                        date={searchDate}
                        setSearch={handleSearch}
                      />
                    )}
                  </Card>
                )}
              </Grid>
              {reportType === 1 || !findButton ? (
                <Grid item xs={12}>
                  <Card
                    sx={{
                      height: "calc(100vh - 300px)",
                      overflow: "auto",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <Lottie options={defaultOptions} height={400} width={400} />
                  </Card>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ReportNew;
